import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { withAlert } from 'react-alert'
//import { actionCreators } from '../../store/SecurityStore';
import * as actions from '../../store/actions/auth';
import "./Login.css";
import simslogoLoading from '../../content/images/logos/bankseta_logo.jpg'
import LoadingIndicatorLogin from '../LoadingIndicatorLogin';
import ViewUtils from "../ViewUtils";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";

//const handler = (state) => {
//    let isLoading = state.security.isLoading;
//    let userDetails = state.security.userDetails;
//    return {
//        isLoading: isLoading,
//        userDetails: userDetails,
//        authenticated: userDetails.Authenticated,
//        otpSent: userDetails.OTPSent,
//        message: userDetails.Message,
//    };
//};
import { useState } from "react";

const utils = new ViewUtils();

class Login extends Component {

    state = {
        username: "",
        password: "",
        message: "",
        passwordShown: false,
        //display: "none",
        //loading: true
        showCIManualDownloadLink: false
    };


    showAlert(msg, msgType) {
        this.props.alert.show(msg, {
            timeout: 15000, // custom timeout just for this one alert
            type: msgType,
            onOpen: () => {
                //console.log('open')
            }, // callback that will be executed after this alert open
            onClose: () => {
                //console.log('closed')
            } // callback that will be executed after this alert is removed
        })
    }

    validateUserName() {
        return this.state.username.length > 0;
    }

    validatePassword() {
        return this.state.password.length > 0;
    }

    componentDidMount() {
        this.CheckCIManualDownload();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authenticated !== this.props.authenticated && this.props.authenticated) {
            this.props.history.push('/role');
        }
        if (prevProps.otpSent !== this.props.otpSent && this.props.otpSent) {
            this.props.history.push('/confirmOTP');
        }
        if (prevProps.passwordExpired !== this.props.passwordExpired && this.props.passwordExpired) {
            this.props.history.push('/reset');
        }

        if (prevProps.message !== this.props.message && this.props.message) {
            this.setState({ message: this.props.message });
        }

        if (prevProps.loginErrorMessage !== this.props.loginErrorMessage && this.props.loginErrorMessage) {
            this.showAlert(this.props.loginErrorMessage, 'error');
        }

        if (prevProps.loginMessage !== this.props.loginMessage && this.props.loginMessage) {
            this.showAlert(this.props.loginMessage, 'error');
        }
    }

    CheckCIManualDownload() {
        let url =
            "api/sims/providercimanual/CheckCIManualDownload";
        utils.invokeUrlExternal(
            url,
            (response) => {
                if (response.Result == "1") {
                    this.setState({ showCIManualDownloadLink: true });
                }
                else {
                    this.setState({ showCIManualDownloadLink: false });
                }
            },
            null,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
    }

    onCIManualClick = (e) => {
        e.preventDefault();
        utils.invokeUrlExternal("api/sims/providercimanual/DownloadCIManual", (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, null);
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    login = () => {

        if (!this.validateUserName() && !this.validatePassword()) {
            this.setState({ message: "Enter username", display: "" });
            this.showAlert('Enter username & password', 'error');
            return;
        }

        if (!this.validateUserName()) {
            this.setState({ message: "Enter username", display: "" });
            this.showAlert('Enter username', 'error');
            return;
        }

        if (!this.validatePassword()) {
            this.setState({ message: "Enter password" });
            this.showAlert('Enter Password', 'error');
            return;
        }

        this.setState({ message: "" });
        //this.props.login(this.state.username, this.state.password);
        this.props.onUserAuth(this.state.username, this.state.password, '/login');
    }

    Domainlogin = () => {
        this.props.history.push('/ADlogin');
    }

    // Password toggle handler
    togglePassword = () => {
        // chnage inverse the boolean state passwordShown
        if (this.state.passwordShown === false) {
            this.setState({ passwordShown: true })
        }
        else {
            this.setState({ passwordShown: false })
        }
    };

    render() {
        return (
            <div className="login-container">
                <div className="card-container sec-card">
                    <img src={simslogoLoading} className="img-responsive" />

                    <form>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon">
                                    <i className="fa fa-user prefix"></i>
                                </span>
                            </div>
                            <Input
                                type="text"
                                value={this.state.username}
                                onChange={(ev) => this.setState({ username: ev.target.value })}
                                className="form-control"
                                placeholder="Enter Username"
                                aria-label="Username"
                                aria-describedby="basic-addon" />
                        </div>
                        <br />
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon">
                                    <i className="fa fa-lock prefix"></i>
                                </span>
                            </div>
                            <Input
                                value={this.state.password}
                                onChange={(ev) => this.setState({ password: ev.target.value })}
                                type={this.state.passwordShown ? "text" : "password"} className="form-control"
                                placeholder="Password" aria-label="Password"
                                aria-describedby="basic-addon"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.togglePassword}
                                            edge="end"
                                        >
                                            {this.state.passwordShown ? <VisibilityIcon /> : <VisibilityOff />}
                                        </IconButton>

                                    </InputAdornment>
                                }
                            />
                        </div>

                        <br />
                        <Button
                            block
                            onClick={this.login}
                            className="loginButton"
                        >
                            Login
                        </Button>
                        <br />
                        <Button
                            block
                            onClick={this.Domainlogin}
                            className="loginButton"
                        >
                            Domain Login
                        </Button>
                        <br />
                        <Link to='/forgot'>Forgot password?</Link><br /><br />
                        <Link to='/sdfRegistrationForm'>SDF Registration</Link><br />
                        <Link to='/stakeholderRegistrationForm'>Stakeholder Registration (Discretionary Grant)</Link>
                        <br />
                       {/* <Link to='/onlineRegistrationTypeSelect'>Online Registration</Link>*/}
                        {this.state.showCIManualDownloadLink && <Fragment><br /><Link to='' onClick={this.onCIManualClick}>CI Manual</Link><br /></Fragment>}
                    </form>
                </div>
                <div className="loginCopyright">
                    @ {new Date().getFullYear()} Copyright SIMS. All rights reserved. Developed By SoluGrowth
                </div>
                <LoadingIndicatorLogin show={this.props.isLoading} />
            </div>

        );
    }
}

//export default withRouter(
//    connect(handler,
//        dispatch => bindActionCreators(actionCreators, dispatch)
//    )(withAlert()(Login)))


const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading,
        userDetails: state.auth.userDetails,
        authenticated: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Authenticated : false,
        otpSent: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.OTPSent : false,
        passwordExpired: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.PasswordExpired : false,
        loginMessage: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Message : null,
        loginErrorMessage: state.auth.errorMessage
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUserAuth: (email, password, loginPath) => dispatch(actions.authUser(email, password, loginPath))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlert()(Login)));

