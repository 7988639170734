import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { SelectItem } from '../../widgets/SelectItem';


export default function CareerGuidanceWorkshopSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="CareerGuidanceWorkshop" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta" handleObject={true}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form => <form className={form.classes.container} id="CareerGuidanceWorkshop">
                <div className="row search-form-container">
                    <div className="col form-col">
                        <CustomTextField
                            id="EventName"
                            label="Name of event"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </div>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/CareerGuidance/GetLookupList?listId=FinancialYear"}
                            id="FinancialYearID"
                            label="Financial Year"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/CareerGuidance/GetLookupList?listId=Project"}
                            id="ProjectID"
                            label="Project"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                </div>
            </form>
            }
        </EntityForm>
    );
}
