import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "../../../View.jsx";
import AlertItem from "../../../widgets/AlertItem";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../../widgets/Toolbar';
import LinkDialog from "../../LinkDialog";
import Prompt from '../../../widgets/Prompt';
import QualificationSetupSearch from '../../program/QualificationSetupSearch';

export const QualificationHeadCells = [
    { id: 'SAQAQualificationID', numeric: false, disablePadding: true, label: 'Qualification Code' },
    { id: 'SAQAQualificationTitle', numeric: false, disablePadding: true, label: 'Qualification Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' }
];

const linkToolbarRef = React.createRef();
const utils = new ViewUtils();
const toolbarRef = React.createRef();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to Learnership Scope', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to Unit Standard Scope', theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class OnlineProviderQualification extends Component {
    constructor(props) {
        super(props)
        let data = {};

        this.state = {
            model: !utils.isNull(props.data) && !utils.isNull(props.data.qualifications) ? props.data.qualifications : { qualifications: [] },
            showTable: true,
            qualificationIds: qualificationIds,
            linkDialogOpen: false,
            formDialogOpen: false,
            clearSelectedRow: false,
            selectedQualification: null,
            propmtOpen: false,
            promptTitle: "Unlink Qualification From Provider",
            promptContent: "Are you sure you want to unlink this qualification from the provider?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "ProviderID", Value: this.props.location.state.ProviderID }]
        }

        this.linkToolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link Qualification/s to the provider', icon: 'LinkIcon' },
                        { id: 'unlink', label: '', tooltip: 'Un-link selected qualification from the provider', disabled: utils.isNull(this.state.selectedQualification), icon: 'LinkOffIcon' }
                    ]
                }
            ]
        };

        let qualificationIds = "";

        if (!utils.isNull(props.data) && !utils.isNull(props.data.qualifications)) {
            this.setState({ qualificationIds: this.getIdList(props.data.qualifications) });
        }
        else {
            if (this.props.regType == "reregistration") {
                this.AutoGrabProviderQualificationScope();
            }
        }

    }

    AutoGrabProviderQualificationScope() {
        let url = "api/sims/OnlineProviderRegistration/GetPrefillProviderQualificationScope?PersonID=" + this.props.data.personRoleDetails.ID;
        utils.invokeUrlExternal(url, (response) => {
            this.setState({
                model: {
                    ...this.state.model,
                    ["qualifications"]: response[0].Data
                }
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    saveQualifications(selection) {
        for (let i = 0; i < selection.length; i++) {
            this.state.model["qualifications"].push(selection[i]);
        }
        this.setState({ qualificationIds: this.getIdList(selection) });
    }

    getIdList(programmes) {
        let exclusions = "";

        for (let i = 0; i < programmes.length; i++) {
            exclusions += programmes[i].Id;
            if (i < programmes.length - 1) {
                exclusions += ","
            }
        }

        return exclusions;
    }

    handleToolbarButtonClick(buttonId) {
        if ("back" === buttonId) {
            this.props.stepHandler(3, false, this.state.model);
        } else if ("next" === buttonId) {
            if (!utils.isNull(this.state.model)) {
                this.props.stepHandler(3, true, this.state.model);
            }
        }
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedQualification: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    unlinkQualification() {
        this.setState({ promptOpen: false }, () => {
            linkToolbarRef.current.setButtonDisabled("unlink", true);
        })

        let programmes = this.state.model.qualifications;
        let newProgrammes = [];

        for (let i = 0; i < programmes.length; i++) {
            if (programmes[i].Id !== this.state.selectedProgrammeId) {
                newProgrammes.push(programmes[i]);
            }
        }
        let newIds = this.getIdList(newProgrammes);
        this.setState({ qualificationIds: newIds, selectedProgrammeId: null, clearSelectedRow: true });
        this.setState({ model: { ...this.state.model, ["qualifications"]: newProgrammes } });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedProgrammeId: selection[0].Id, clearSelectedRow: false });
        } else {
            this.setState({ selectedProgrammeId: null, clearSelectedRow: false });
        }
        linkToolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false, clearSelectedRow: true })
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    handleTopToolbarButtonClick = (buttonId) => {
        if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <p>
                    Online Provider Registration: Qualification Scope
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        ref={linkToolbarRef}
                        buttonClickHandler={(buttonId) => this.handleTopToolbarButtonClick(buttonId)}
                        config={this.linkToolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={QualificationHeadCells}
                            rows={this.state.model.qualifications}
                            autoFetchData={false}
                            height="50vh"
                            multiSelect={false}
                            clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <LinkDialog open={this.state.linkDialogOpen} linkHandler={(selection) => { this.saveQualifications(selection) }}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Qualification to Provider`}
                >
                    <QualificationSetupSearch dataUrl="api/sims/OnlineProviderRegistration/GetSearchLinkableQualifications"
                        multiSelect={true} showTitle={false} selectionCallbackMode="full"
                        IsExternal={this.props.IsExternal}
                        programmeType="Q" exclusions={this.state.qualificationIds}
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkQualification()}
                    closePrompt={this.closePrompt}
                />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(OnlineProviderQualification)
);
