import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "6eb17547-63dd-4422-97c2-ac0faabff925",
        authority: "https://login.microsoftonline.com/b23e616c-123f-4dbd-b946-f59a6d2734a3"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

export const loginRequest = {
    scopes: ["api://634a5e6d-97fb-4751-8885-b16954615e80/SIMSUser.Read"]
};