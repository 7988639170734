import { withRouter } from 'react-router-dom';
import React, { Component } from "react";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import LodgeApealSearchForm from './LodgeApealSearchForm';
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/ResponsiveTable';
import AddDialog from './LodgeApealDialog';
import AlertItem from "../../widgets/AlertItem";
import EditDialog from './LodgeApealEditDialog';
import Prompt from '../../widgets/Prompt';
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'Date', numeric: false, disablePadding: true, label: 'Date' },
    { id: 'Time', numeric: false, disablePadding: true, label: 'Time' },
    { id: 'LoggedBy', numeric: false, disablePadding: true, label: 'Logged By' },
    { id: 'Letterhead', numeric: false, disablePadding: true, label: 'Letterhead' },
    { id: 'AuthorisedSignatory', numeric: false, disablePadding: true, label: 'Authorise signatory' },
    { id: 'Reasons', numeric: false, disablePadding: true, label: 'Reason' },
    { id: 'AppealStatus', numeric: false, disablePadding: true, label: 'Apeal Status' },
    { id: 'Document', numeric: false, disablePadding: true, label: 'Document', contentType: 'document' },
    { id: 'DateCommunicationGenerated', numeric: false, disablePadding: true, label: 'Date Communication Generated' },
];

class LodgeAppeal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                Id: this.props.location.state.selection,
                FinancialYear: { Id: 26, Description: "01 April 2024 - 31 March 2025" }
            },
            addDialogOpen: false,
            editDialogOpen: false,
            selectedId: null,
            searchParameters: [{ Name: "OrganisationID", Value: this.props.location.state.organisationID }, { Name: "FinancialYearID", Value: 0 }],
            Letterhead: null,
            AuthorisedSignatory: null,
            Reasons: null,
            clearSelectedRow: false,
            promptOpen: false,
            promptTitle: "Remove Appeal Request",
            promptContent: "Are you sure you want to remove this Appeal Request?",
            FinancialYearID: null
        }

        this.toolbarConfig = {
            items: [

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add Request', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit Request', disabled: utils.isNull(this.state.selectedId), icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit },
                        { id: 'remove', label: '', tooltip: 'Remove Request', disabled: utils.isNull(this.state.selectedId), icon: 'DeleteIcon', visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        }
    }

    componentDidMount() {

        toolbarRef.current.setButtonDisabled("add", this.state.FinancialYearID === null || this.state.FinancialYearID.length === 0);
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true });

        }
        else if (buttonId === "edit") {
            this.setState({ editDialogOpen: true });
        }
        else if (buttonId === "close") {
            this.props.history.push({
                pathname: "/skillsOrganisation",
                state: { selection: this.props.location.state.organisationID }
            })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        } else {
            // this.setState({ editDialogOpen: true });
        }
    }

    handleFormValueChange(values) {

        let FinancialYearID = values.FinancialYear.Id;
        let newsearchParameters = [...this.state.searchParameters];
        let temp_element = { ...newsearchParameters[1] };
        temp_element.Value = FinancialYearID
        newsearchParameters[1] = temp_element;

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            FinancialYearID: FinancialYearID
        });
        toolbarRef.current.setButtonDisabled("add", FinancialYearID === null || FinancialYearID.length === 0);
    }

    FileDownload = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            console.log(headCell);
            utils.invokeUrl("api/sims/lodgeappeal/downloaddocument?id=" + rowid, (response) => {

                var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedId: selection[0].Id, Message: "", clearSelectedRow: false });
        } else {
            this.setState({ selectedId: null, Message: "", clearSelectedRow: false });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
    }

    postAddHandler(cancel, save, response) {
        if (save === true) {
            if (response.MessageType === "SUCCESS") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    addDialogOpen: false,
                    editDialogOpen: false,
                    selectedId: null,
                    Message: response.Message,
                    MessageStyle: "success",
                    clearSelectedRow: true
                });
                toolbarRef.current.setButtonDisabled("remove", true);
            }
            else if (response.MessageType === "ERROR") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    addDialogOpen: false,
                    editDialogOpen: false,
                    selectedId: null,
                    Message: response.Message,
                    MessageStyle: "warning"
                });
                toolbarRef.current.setButtonDisabled("remove", true);
            }
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedId: null,
                clearSelectedRow: true
            })
            toolbarRef.current.setButtonDisabled("remove", true);
        }
    }

    remove(url) {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("edit", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedId}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null })
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedId: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase(),
                clearSelectedRow: true });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="legend">
                    {"Organisation > Lodge an Appeal " + (this.props.location.state.organisationName !== null ? "> " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    < LodgeApealSearchForm
                        // data={this.state.model}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)} data={this.state.model}
                    />

                    <EnhancedToolbar highlight={false}
                        title="Lodge an Appeal"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig}
                    />

                    <EnhancedTable
                        headCells={headCells}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/lodgeappeal/GetSearch"
                        searchParameters={this.state.searchParameters}
                        onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                        clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                        paged={true} />
                </div>

                <AddDialog
                    open={this.state.addDialogOpen}
                    FinancialYearID={this.state.FinancialYearID}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                    viewRef={this}
                    OrganisationID={this.props.location.state.organisationID}
                />

                <EditDialog
                    open={this.state.editDialogOpen}
                    id={this.state.selectedId}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                    viewRef={this}
                />

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.remove("api/sims/lodgeappeal/removelodgeappeal") }}
                    closePrompt={this.closePrompt}
                />

            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(LodgeAppeal));