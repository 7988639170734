import React from 'react';
import { CustomTextField } from '../../../../widgets/CustomTextField';
import { EntityForm } from '../../../../widgets/Forms';
import ViewUtils from '../../../../ViewUtils';
import TabPanel from '../../../../widgets/TabPanel';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import OFOSearch from '../../Controls/OFOSearch';
import PivotalProgrammeSearch from '../../Controls/PivotalProgrammeSearch';
import { LookupField } from '../../../../widgets/LookupField';
import Table from '@material-ui/core/Table';
import { SelectItem } from '../../../../widgets/SelectItem';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

const styles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: 50,
            textAlign: 'center',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        minWidth: 200
    },
    inputForm: {
        minWidth: '200px',
    },
    table: {
        minWidth: 650,
    },
    inputCenter: {
        textAlign: 'center'
    },
    paper: {
        position: "absolute",
        width: 600,
        height: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0.1)
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#01579B',
        color: theme.palette.common.white,
        border: '1px solid white',
        height: 6
    },
    body: {
        fontSize: 11,
    },

}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            // width: 50,
            textAlign: 'center'
        },
    },
    body: {
        fontSize: 14,
        fontWeight: 500
    }

}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);

const useStyles = theme => ({
    table: {
        minWidth: 700,
    },
});

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 5;
    const left = 5;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

export const TrainingReportSummaryForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm
            ref={ref}
            viewId={null}
            values={props.data}
            entityLookupController={props.entityLookupController}
            org="saseta"
            entityLookupId={props.id}
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="TrainingReportSummary">
                    <div className="row w-100">
                        <div className="col w-100">
                            <TabPanel value={tabValue} index={0} className=''>
                                <table cellPadding="2" width="80%">
                                    <tbody>
                                        <tr>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl={'api/sims/WSPATRForms2024/GetOFOMajorGroupPerWSPATRID?parameter=[{"Name":"WSPATRID","Value":' + props.WSPATRID + '}]'}
                                                    id="OFOMajorGroupID"
                                                    parentId={0}
                                                    required
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Major Occupation"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                />
                                            </td>
                                            <td width="50%">
                                                
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%">
                                                <CustomTextField
                                                    id="NumberTrained"
                                                    key={tabValue}
                                                    parentId={0}
                                                    label="Number of persons who attended training in the past year"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                    validationRegex={/^[0-9]+$/g}
                                                />
                                            </td>
                                            <td width="50%">
                                                <CustomTextField
                                                    id="TrainingExpenditure"
                                                    key={tabValue}
                                                    parentId={0}
                                                    label="Expenditure on Training"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                    validationRegex="^[0-9]+(\.[0-9]{1,2})?$"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%">
                                                <CustomTextField
                                                    id="EmployedBeneficiaries"
                                                    key={tabValue}
                                                    parentId={0}
                                                    label="Total number of employed beneficiaries"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                    validationRegex={/^[0-9]+$/g}
                                                />
                                            </td>
                                            <td width="50%">
                                                <CustomTextField
                                                    id="DisabledBeneficiaries"
                                                    key={tabValue}
                                                    parentId={0}
                                                    label="Total Number of Disabled Beneficiaries"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                    validationRegex={/^[0-9]+$/g}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </TabPanel>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})