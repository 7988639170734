import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
//import { bindActionCreators } from 'redux';
import { Button } from "react-bootstrap";
import { withAlert } from 'react-alert'
//import { actionCreators } from '../../store/SecurityStore';
import * as actions from '../../store/actions/auth';
//import LoadingScreen from 'react-loading-screen';
//import { AlertStyle } from '../../components/widgets/AlertTemplate';
import "./Login.css";
import simslogoLoading from '../../content/images/logos/bankseta_logo.jpg'
import LoadingIndicatorLogin from '../LoadingIndicatorLogin';
import ViewUtils from "../ViewUtils";

//const handler = (state) => {
//    let isLoading = state.security.isLoading;
//    let userDetails = state.security.userDetails;
//    return {
//        isLoading: isLoading,
//        userDetails: userDetails,
//        authenticated: userDetails.Authenticated,
//        otpSent: userDetails.otpSent,
//        message: userDetails.Message,
//    };
//};

//const alertStyle = { AlertStyle }

const utils = new ViewUtils();

class confirmOTP extends Component {

    state = {
        otp: "",
        message: "",
        display: "none",
        loading: true
    };

    showAlert(msg, msgType) {
        this.props.alert.show(msg, {
            timeout: 3000, // custom timeout just for this one alert
            type: msgType,
            onOpen: () => {
                //console.log('open')
            }, // callback that will be executed after this alert open
            onClose: () => {
                //console.log('closed')
            } // callback that will be executed after this alert is removed
        })
    }

    validateOTP() {
        return this.state.otp.length > 0;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authenticated !== this.props.authenticated && this.props.authenticated) {
            this.props.history.push('/role');
        }

        if (prevProps.loginRedirectToLogin !== this.props.loginRedirectToLogin && this.props.loginRedirectToLogin) {
            this.props.history.push('/login');
        }

        if (prevProps.message !== this.props.message && this.props.message) {
            this.setState({ message: this.props.message });
        }

        if (prevProps.loginErrorMessage !== this.props.loginErrorMessage && this.props.loginErrorMessage) {
            this.showAlert(this.props.loginErrorMessage, 'error');
        }

        if (prevProps.loginMessage !== this.props.loginMessage && this.props.loginMessage) {
            this.showAlert(this.props.loginMessage, 'error');
        }
    }

    login = () => {

        if (!this.validateOTP()) {
            this.setState({ message: "Enter OTP", display: "" });
            this.showAlert('Enter OTP', 'error');
            return;
        }

        this.setState({ message: "" });
        //this.props.confirmOTP(this.state.otp, this.props.userDetails.UserKey);
        this.props.onConfirmOTP(this.state.otp, this.props.userDetails.UserKey);
    }

    render() {

        return (
            <div className="login-container">
                <div className="login-container-logo">
                </div>
                <div className="card-container sec-card">
                    <img src={simslogoLoading} className="img-responsive" />

                    <form>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon">
                                    <i className="fa fa-user prefix"></i>
                                </span>
                            </div>
                            <input
                                type="text"
                                value={this.state.otp}
                                onChange={(ev) => this.setState({ otp: ev.target.value })}
                                className="form-control"
                                placeholder="Enter OTP"
                                aria-label="OTP"
                                aria-describedby="basic-addon" />
                        </div>
                        <br />
                        <Button
                            block
                            onClick={this.login}
                            className="loginButton"
                        >
                            Confirm
                    </Button>
                        <Link to='/login'>Back to Login</Link>
                    </form>
                </div>
                <div className="loginCopyright">
                    @ {new Date().getFullYear()} Copyright SIMS. All rights reserved. Developed By SoluGrowth
                </div>
                <LoadingIndicatorLogin show={this.props.isLoading} />
            </div>

        );
    }
}

//function demoAsyncCall() {
//    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
//}

//export default withRouter(
//    connect(handler,
//        dispatch => bindActionCreators(actionCreators, dispatch)
//    )(withAlert()(confirmOTP)))

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading,
        userDetails: state.auth.userDetails,
        authenticated: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Authenticated : false,
        otpSent: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.OTPSent : false,
        loginMessage: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Message : null,
        loginErrorMessage: state.auth.errorMessage,
        loginRedirectToLogin: state.auth.redirectToLogin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onConfirmOTP: (OTP, UserKey) => dispatch(actions.authOTP(OTP, UserKey))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlert()(confirmOTP)));
