import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./SDF.css";
import "./sdfStyle.css";
import { SDFForm } from './SDFForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";
import LoadingIndicator from '../../../components/LoadingIndicator';

const formRef = React.createRef();
const utils = new ViewUtils();
const toolbarRef = React.createRef();


class SDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sdfDetails: {
                FirstName: "",
                Surname: "",
                IDNo: "",
            },
            message: "",
            messageStyle: "",
            title: "",
            showSDFDocUploader: true,
            HaveyoucompletedaSDFTrainingProgrammeprovidedbyanaccreditedtrainingprovider: false,
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
            IsExternal: utils.isNull(this.props.IsExternal) ? false : this.props.IsExternal,
            IsDashboard: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.IsDashboard)) ? this.props.location.state.IsDashboard : null,
            counter: 0
        };
        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.IsExternal ? true : utils.isNull(this.props.location.state.selection) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add },
                        { id: 'organisationDetails', label: 'Organisation Details', tooltip: 'Organisation Details', disabled: utils.isNull(this.state.id) }
                    ]
                }
            ]
        }

    }

    //componentDidMount() {
    //    let LookupUrl = `api/sims/sdforganisationdetails/`;
    //    let path = '/sdfOrgDetails';
    //    if (this.props.IsExternal) { LookupUrl = `api/sims/sdfregistration/`; path = '/sdfRegistrationOrgDetails'; }
    //    this.props.history.push({
    //        pathname: path,
    //        state: {
    //            SDFID: 7,
    //            sdfName: 'test' + " " + 'test',
    //            IDNo: '15092021',
    //            IsDashboard: false,
    //            IsExternal: true,
    //            LookupUrl: LookupUrl
    //        }
    //    });
    //}

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            if (this.state.HaveyoucompletedaSDFTrainingProgrammeprovidedbyanaccreditedtrainingprovider
                !== values.HaveyoucompletedaSDFTrainingProgrammeprovidedbyanaccreditedtrainingprovider) {
                this.setState({
                    HaveyoucompletedaSDFTrainingProgrammeprovidedbyanaccreditedtrainingprovider: values.HaveyoucompletedaSDFTrainingProgrammeprovidedbyanaccreditedtrainingprovider,
                    showSDFDocUploader: false
                }, () => { this.setState({ showSDFDocUploader: true }) })
            }
            this.setState({
                sdfDetails: {
                    FirstName: values.FirstName,
                    Surname: values.Surname,
                    IDNo: values.IDNo
                }
            });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("organisationDetails" === buttonId) {
            let LookupUrl = `api/sims/sdforganisationdetails/`;
            let path = '/sdfOrgDetails';
            if (this.props.IsExternal) { LookupUrl = `api/sims/sdfregistration/`; path = '/sdfRegistrationOrgDetails'; }
            this.props.history.push({
                pathname: path,
                state: {
                    SDFID: this.state.id,
                    sdfName: this.state.sdfDetails.FirstName + " " + this.state.sdfDetails.Surname,
                    IDNo: this.state.sdfDetails.IDNo,
                    IsDashboard: this.state.IsDashboard,
                    IsExternal: this.state.IsExternal,
                    LookupUrl: LookupUrl
                }
            });
        }
    }

    save() {
        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;

            if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
                formValue.id = this.props.location.state.selection;
                isAdd = false;
            }
            let data = JSON.stringify(formValue);

            if (isAdd) {
                let url = `api/sims/sdfdetails/AddSDF`;
                if (this.props.IsExternal) {
                    url = `api/sims/sdfregistration/AddSDF`;
                    utils.saveFormExternal(this, formRef, url, (response) => {
                        if (!utils.isNull(response.EntityId) && response.EntityId != -1) {
                            this.setState({ id: response.EntityId }, () => { toolbarRef.current.setButtonDisabled("organisationDetails", false); });
                        }
                        utils.showMessage(this, response.Message, response.MessageType);
                        response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                    }, this.error);
                } else {
                    utils.saveForm(this, formRef, url, (response) => {
                        if (!utils.isNull(response.EntityId) && response.EntityId != -1) {
                            this.setState({ id: response.EntityId }, () => { toolbarRef.current.setButtonDisabled("organisationDetails", false); });
                        }
                        utils.showMessage(this, response.Message, response.MessageType);
                        response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                    }, this.error);
                }
            } else {
                const url = `api/sims/sdfdetails/UpdateSDF`;
                utils.updateForm(this, formRef, url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                }, this.error);
            }
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType, counter: this.state.counter + 1 });
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    closeView() {
        let url = `/sdfsearch`;
        if (this.state.IsDashboard) { url = `/sdfdashboard`; }
        this.props.history.push({
            pathname: url,
            state: { selection: null }
        });
    }

    dataArrivedHandler = (values) => {
        if (!utils.isNull(values["ID"]) && values["ID"] !== 0) {
            this.setState({ id: values["ID"] });
        }
    }

    render() {
        return (
            <ViewContainer title="">
                {this.state.IsExternal ? <LoadingIndicator /> : null}
                <div className="view w-100 h-100">
                    <p className="breadcrumbs">
                        SDF Details
                    </p>
                    <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td className="btn-margin">
                                    <EnhancedToolbar highlight={false} title="sdf" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                        ref={toolbarRef} enabled={true} config={this.toolbarConfig} />
                                </td>
                            </tr>
                            <tr>
                                <td className="formContainer">
                                    <SDFForm key={this.state.counter} ref={formRef} id={this.state.id} valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                        entityLookupController={"GetFind"} IsExternal={this.props.IsExternal} LookupUrl={typeof this.props.IsExternal != 'undefined' ? "api/sims/sdfregistration/GetLookupList?listId=" : "api/sims/sdfdetails/GetLookupList?listId="}
                                        dataArrivedHandler={(values) => this.dataArrivedHandler(values)} dataUrl={typeof this.props.IsExternal != 'undefined' ? "api/sims/sdfregistration/getfind" : "api/sims/sdfdetails/getfind"}
                                        showSDFDocUploader={this.state.showSDFDocUploader}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(SDF));
