import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Learner.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import ViewUtils from '../../ViewUtils';
import { EntityForm } from '../../widgets/Forms';
import { SelectItem } from '../../widgets/SelectItem';
import { LearnerLearnershipForm } from '../program/registration/LearnerLearnershipForm';
import { LearnerSkillsProgramForm } from '../program/registration/LearnerSkillsProgramForm';
import { LearnerUnitStandardForm } from '../program/registration/LearnerUnitStandardForm';
import { LearnerQualificationForm } from '../program/registration/LearnerQualificationForm';
import { LearnerTradeForm } from "../program/registration/LearnerTradeForm";
import { LearnerInternshipForm } from "../program/registration/LearnerInternshipForm";
import { LearnerBursaryForm } from "../program/registration/LearnerBursaryForm";
import { LearnerAETForm } from "../program/registration/LearnerAETForm";
import { LearnerFETForm } from "../program/registration/LearnerFETForm";
import { LearnerCandidacyForm } from "../program/registration/LearnerCandidacyForm";
import { LearnerEntrepreneurshipForm } from "../program/registration/LearnerEntrepreneurshipForm";
import { LearnerRPLForm } from "../program/registration/LearnerRPLForm";
import { LearnerShortCourseForm } from "../program/registration/LearnerShortCourseForm";
import { LearnerWILForm } from "../program/registration/LearnerWILForm";
import { LearnerSeniorCertificateAssistanceForm } from "../program/registration/LearnerSeniorCertificateAssistanceForm";
import { LearnerCapacityBuildingSupportForm } from "../program/registration/LearnerCapacityBuildingSupportForm";
import { LearnerCareerGuidanceWorkshopForm } from "../program/registration/LearnerCareerGuidanceWorkshopForm";
import { LearnerDigitalLiteracyForm } from "../program/registration/LearnerDigitalLiteracyForm";
import { LearnerMathScienceSupportForm } from "../program/registration/LearnerMathScienceSupportForm";
import { LearnerWorkReadinessProgrammeForm } from "../program/registration/LearnerWorkReadinessProgrammeForm";

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to Search', theme: true },
                { id: 'finish', label: 'FINISH', tooltip: 'Save and Finish Process', theme: true }
            ]
        }
    ]
};

const learnershipFormRef = React.createRef();
const skillsProgramFormRef = React.createRef();
const unitStandardFormRef = React.createRef();
const qualificationFormRef = React.createRef();

const tradeFormRef = React.createRef();
const internshipFormRef = React.createRef();
const bursaryFormRef = React.createRef();
const aetFormRef = React.createRef();
const fetFormRef = React.createRef();
const candidacyFormRef = React.createRef();
const entrepreneurshipFormRef = React.createRef();
const rplFormRef = React.createRef();
const shortcourseFormRef = React.createRef();
const wilFormRef = React.createRef();
const seniorCertificateAssistanceFormRef = React.createRef();
const capacityBuildingSupportFormRef = React.createRef();
const careerGuidanceWorkshopFormRef = React.createRef();
const digitalLiteracyProgrammeFormRef = React.createRef();
const mathScienceSupportFormRef = React.createRef();
const workReadinessProgrammeFormRef = React.createRef();

const utils = new ViewUtils();

class AddLearnerProgram extends Component {

    state = {
        model: {},
        searchParameters: null,
        message: "",
        dependency: null,
        formValue: { Intervension: null },
        selection: {},
        selectedIntervension: null
    };

    handleFormValueChange(values) {
        this.setState({ model: values });
    }

    handleBursaryFormValueChange = (values) => {

        if (!utils.isNull(values)) {
            if (!utils.isNull(values.BursaryTypeID) && JSON.stringify(this.state.dependency) !== JSON.stringify(values.BursaryTypeID)) {
                const bursaryTypeID = {
                    Name: "BursaryTypeID",
                    Value: values.BursaryTypeID.Id
                }
                if (!utils.isNull(bursaryTypeID.Value)) {
                    this.setState({ dependency: bursaryTypeID })
                }
            };
            this.setState({ model: values });
        }
    }

    handleProgrammeFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ selectedIntervension: values.Intervension });
        }
    }

    handleToolbarButtonClick(buttonId) {

        if ("back" === buttonId) {
            this.props.stepHandler(3, false, this.state.model);
        } else if ("finish" === buttonId) {
            let formValue = null;

            if (!utils.isNull(this.state.selectedIntervension)) {
                if ((1 == this.state.selectedIntervension.Id)) {
                    formValue = learnershipFormRef.current.getValue();
                } else if ((2 == this.state.selectedIntervension.Id)) {
                    formValue = skillsProgramFormRef.current.getValue();
                } else if ((3 == this.state.selectedIntervension.Id)) {
                    formValue = unitStandardFormRef.current.getValue();
                } else if ((4 == this.state.selectedIntervension.Id)) {
                    formValue = qualificationFormRef.current.getValue();
                } else if ((5 == this.state.selectedIntervension.Id)) {
                    formValue = tradeFormRef.current.getValue();
                } else if ((6 == this.state.selectedIntervension.Id)) {
                    formValue = internshipFormRef.current.getValue();
                } else if ((7 == this.state.selectedIntervension.Id)) {
                    formValue = bursaryFormRef.current.getValue();
                } else if ((8 == this.state.selectedIntervension.Id)) {
                    formValue = aetFormRef.current.getValue();
                } else if ((9 == this.state.selectedIntervension.Id)) {
                    formValue = fetFormRef.current.getValue();
                } else if ((10 == this.state.selectedIntervension.Id)) {
                    formValue = candidacyFormRef.current.getValue();
                } else if ((11 == this.state.selectedIntervension.Id)) {
                    formValue = entrepreneurshipFormRef.current.getValue();
                } else if ((12 == this.state.selectedIntervension.Id)) {
                    formValue = rplFormRef.current.getValue();
                } else if ((13 == this.state.selectedIntervension.Id)) {
                    formValue = shortcourseFormRef.current.getValue();
                } else if ((14 == this.state.selectedIntervension.Id)) {
                    formValue = wilFormRef.current.getValue();
                } else if ((15 == this.state.selectedIntervension.Id)) {
                    formValue = seniorCertificateAssistanceFormRef.current.getValue();
                } else if ((16 == this.state.selectedIntervension.Id)) {
                    formValue = capacityBuildingSupportFormRef.current.getValue();
                } else if ((17 == this.state.selectedIntervension.Id)) {
                    formValue = careerGuidanceWorkshopFormRef.current.getValue();
                } else if ((18 == this.state.selectedIntervension.Id)) {
                    formValue = digitalLiteracyProgrammeFormRef.current.getValue();
                } else if ((19 == this.state.selectedIntervension.Id)) {
                    formValue = mathScienceSupportFormRef.current.getValue();
                } else if ((20 == this.state.selectedIntervension.Id)) {
                    formValue = workReadinessProgrammeFormRef.current.getValue();
                }

                if (!utils.isNull(formValue)) {
                    formValue.programmeType = this.state.selectedIntervension.Id;
                    this.props.stepHandler(3, true, formValue);
                }
            }
            else {
                formValue = {};
                this.props.stepHandler(3, true, formValue);
            }
        }
    }

    render() {
        return (
            <div style={{ paddingTop: "0 !important", paddingLeft: "32px", height: "100%" }}>
                {
                    !utils.isStringEmpty(this.props.saveMessage) ?
                        <p className={this.props.hasSaveError ? "errorMessage" : "message"}>
                            {this.props.saveMessage}
                        </p>
                        :
                        ""
                }
                <EntityForm viewId="learnerProgramIntervension" values={this.state.formValue} useCustomSettings={false} valueChangeHandler={(values) => { this.handleProgrammeFormValueChange(values); }}>
                    {form =>
                        <form className={form.classes.container} id="learnerProgramIntervensionForm">
                            <div className="row w-50">
                                <div className="col w-100">
                                    <SelectItem
                                        dataUrl="api/sims/learner/GetLearnerInterventionsByRole"
                                        id="Intervension"
                                        label="Programme Type"
                                        className="{classes.select} w-100"
                                        form={form} />
                                </div>
                            </div>
                        </form>
                    }
                </EntityForm>
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 1 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 1 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Learnership
                            </p>
                            <div style={{ height: "100%" }}>
                                <LearnerLearnershipForm data={this.props.data} hideFields={true} ref={learnershipFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 2 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 2 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Skills Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerSkillsProgramForm data={this.props.data} hideFields={true} ref={skillsProgramFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 3 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 3 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Unit Standard
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerUnitStandardForm data={this.props.data} hideFields={true} ref={unitStandardFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 4 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Qualification
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerQualificationForm data={this.props.data} hideFields={true} ref={qualificationFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 5 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Trade
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerTradeForm data={this.props.data} hideFields={true} ref={tradeFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 6 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Internship
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerInternshipForm data={this.props.data} hideFields={true} ref={internshipFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 7 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Bursary
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerBursaryForm data={this.props.data} hideFields={true} dependency={this.state.dependency} ref={bursaryFormRef} valueChangeHandler={(values) => { this.handleBursaryFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 8 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to an AET
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerAETForm data={this.props.data} hideFields={true} ref={aetFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 9 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to an FET Placement
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerFETForm data={this.props.data} hideFields={true} ref={fetFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 10 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Candidacy Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerCandidacyForm data={this.props.data} hideFields={true} ref={candidacyFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 11 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Entrepreneurship Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerEntrepreneurshipForm data={this.props.data} hideFields={true} ref={entrepreneurshipFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 12 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Entrepreneurship Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerRPLForm data={this.props.data} hideFields={true} ref={rplFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 13 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a Short Course
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerShortCourseForm data={this.props.data} hideFields={true} ref={shortcourseFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 14 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                            //display: !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id === 4 ? 'block' : 'none'
                        }}>
                            <p className="legend">
                                Enroll to a WIL
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerWILForm data={this.props.data} hideFields={true} ref={wilFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 15 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                        }}>
                            <p className="legend">
                                Enroll to Senior Certificate Assistance
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerSeniorCertificateAssistanceForm data={this.props.data} hideFields={true} ref={seniorCertificateAssistanceFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 16 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                        }}>
                            <p className="legend">
                                Enroll to Capacity Building Support
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerCapacityBuildingSupportForm data={this.props.data} hideFields={true} ref={capacityBuildingSupportFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 17 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                        }}>
                            <p className="legend">
                                Enroll to a Career Guidance Workshop
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerCareerGuidanceWorkshopForm data={this.props.data} hideFields={true} ref={careerGuidanceWorkshopFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 18 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                        }}>
                            <p className="legend">
                                Enroll to a Digital Literacy Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerDigitalLiteracyForm data={this.props.data} hideFields={true} ref={digitalLiteracyProgrammeFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 19 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                        }}>
                            <p className="legend">
                                Enroll to Math and Science Support
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerMathScienceSupportForm data={this.props.data} hideFields={true} ref={mathScienceSupportFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    !utils.isNull(this.state.selectedIntervension) && this.state.selectedIntervension.Id == 20 ?
                        <div style={{
                            margin: "8px 0 16px 8px",
                            height: "38%",
                        }}>
                            <p className="legend">
                                Enroll to Work Readiness Programme
                            </p>
                            <div style={{ height: "90%", overflow: "auto" }}>
                                <LearnerWorkReadinessProgrammeForm data={this.props.data} hideFields={true} ref={workReadinessProgrammeFormRef} valueChangeHandler={(values) => { this.handleFormValueChange(values) }} />
                            </div>
                        </div>
                        :
                        ""
                }
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(
    connect()(AddLearnerProgram)
);
