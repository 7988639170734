import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import ProjectSearchForm from './ProjectSearchForm';
import SearchView from "../../../widgets/SearchView";
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "./../../../View.jsx";
import withMenuPermission from "../../../widgets/withMenuPermission";


const utils = new ViewUtils();

const headCells = [
    { id: 'Id', numeric: true, disablePadding: false, label: 'Project ID', widthRatio: .5 },
    { id: 'Description', numeric: false, disablePadding: false, label: 'Description', widthRatio: .25 }
];



const filterParameters = [
    { dataField: "Id", text: "Id" },
    { dataField: "Description", text: "Description" }

]

class ProjectSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                Id: "",
                Description: ""
            },
            parameters: {},
            keyUpSwitch: false,
            selectedProject: null
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add},
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit}
                    ]
                }
            ]
        };

        this.searchParameters = 

            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "Description", Value: "" }
            ]
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedProject: selection })
        } else {
            this.setState({ selectedProject: null })
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if  ("add" === buttonId) {
         this.setState({ linkDialogOpen: true })
        }
    } 

    render() {
        return (
            <ViewContainer title={utils.isNull(this.props.showTitle) || this.props.showTitle === true ? 'Project' : null}>
                <SearchView headCells={headCells}
                            dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/GrantSetup/DTSSServiceProject"}
                            entityViewPath='/grant'
                            model={this.state.model}
                            toolbarConfig={this.toolbarConfig}
                            searchParameters={this.searchParameters}
                            filterParameters={filterParameters}
                            onLookupValueChange={this.props.onLookupValueChange}
                            descriptionProp="Id"
                            mode={this.props.mode}
                            history={this.props.history}
                            buttonClickHandler={this.handleToolbarButtonClick}
                            keyUpSwitch={this.state.keyUpSwitch} 
                            setSelection={(selection) => { this.handleSelection(selection) }}
            >
                    <ProjectSearchForm data={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }} /> 
                </SearchView>
            </ViewContainer>

        );
    }
}


export default withRouter(withMenuPermission(ProjectSearch));

