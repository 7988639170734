import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import { makeStyles } from '@material-ui/core/styles';
import ViewUtils from '../../../ViewUtils';
import { AddressCodeItem } from '../../../widgets/AddressCodeItem';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { GPSCoordinatesAPI } from '../../../widgets/GPSCoordinatesAPI';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

const utils = new ViewUtils();

export const OnlineProviderForm = React.forwardRef((props, ref) => {

    const [tabValue, setTabValue] = React.useState(0);
    const [postalCodeValue, setPostalCodeValue] = React.useState(null);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleUsePhysicalAddressForPostal = form => event => {


        if (event.target.checked === true) {
            form.values['PostalAddressLine1'] = form.values["PhysicalAddress1"];
            form.values['PostalAddressLine2'] = form.values["PhysicalAddress2"];
            form.values['PostalAddressLine3'] = form.values["PhysicalAddress3"];
            form.values['PostalCode'] = form.values["PhysicalCode"];
            setPostalCodeValue(form.values["PhysicalCode"]);
        }
        form.updateValue('UsePhysicalAsPostal', event.target.checked);


    }

    const updateGPSAddress = (address, form) => {
        form.values["GPSCoordinates"] = address.gpscoordinates.coordinate;
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }

    return (
        <EntityForm ref={ref}
            dataURL='api/sims/OnlineProviderApproval/GetApprovalFind'
            viewId="OnlineProviderRegistration"
            values={props.data}
            entityLookupId={props.id} IsExternal={props.IsExternal}
            entityLookupController={props.entityLookupController}
            org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="organisationDetailsForm">
                    <div className="row" style={{ width: "90%" }}>
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="TradeName"
                                                required
                                                key={tabValue}
                                                label="Trade Name"
                                                className="w-100"
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="LegalName"
                                                required
                                                key={tabValue}
                                                required={true}
                                                label="Legal Name"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="SDLNumber"
                                                required
                                                key={tabValue}
                                                label="SDL Number"
                                                InputProps={{
                                                    readOnly: false,
                                                    disabled: !utils.isNull(props.id)
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Provider Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                                <Tab label="Provider Address" {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                                <Tab label="Provider Contacts" {...a11yProps(2)} style={{ color: form.fieldParentErrors[2] === true ? "red" : null }} />
                                <Tab label="Provider Users" {...a11yProps(3)} style={{ color: form.fieldParentErrors[3] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className="w-100" value={tabValue} index={0}>
                            <table cellPadding="2" width="80%" index={0}>
                                <tbody>

                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="OrganisationRegistrationNumber"
                                                required
                                                parentId={0}
                                                key={0}
                                                label="Registration Number"
                                                disabled={!props.editPermission}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderRegistration/GetLookupList?listId=LevyNumberType"
                                                id="LevyNumberTypeID"
                                                required
                                                parentId={0}
                                                key={0}
                                                label="SDL Number Type"
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderRegistration/GetLookupList?listId=OrganisationRegistrationNumberType"
                                                id="OrganisationRegistrationNumberTypeID"
                                                required
                                                parentId={0}
                                                key={0}
                                                disabled={!props.editPermission}
                                                label="Organisation Registration Number Type"
                                                className="w-100"
                                                checkRefreshToken={!props.IsExternal}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="SARSNumber"
                                                required
                                                parentId={0}
                                                key={0}
                                                label="SARS Number"
                                                className="w-100"
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                validationRegex={/^[0-9]+$/g}

                                            />
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderRegistration/GetLookupList?listId=Partnership"
                                                id="PartnershipID"
                                                required
                                                parentId={0}
                                                key={0}
                                                label="Partnership"
                                                disabled={!props.editPermission}
                                                checkRefreshToken={!props.IsExternal}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderRegistration/GetLookupList?listId=SICCode"
                                                id="SICCodeID"
                                                required
                                                parentId={0}
                                                key={0}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission}
                                                label="SIC Code"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>


                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="NumberOfEmployees"
                                                required
                                                parentId={0}
                                                key={0}
                                                disabled={!props.editPermission}
                                                label="Number of Employees"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}

                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="NumberOfEmployeesProfile"
                                                parentId={0}
                                                key={0}
                                                label="Number of Employees as Per the Employment Profile"
                                                disabled={!props.editPermission}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderRegistration/GetLookupList?listId=OrganisationSize"
                                                id="OrganisationSizeID"
                                                required
                                                parentId={0}
                                                key={0}
                                                label="Organisation Size"
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderRegistration/GetLookupList?listId=LegalStatus"
                                                id="LegalStatusID"
                                                required
                                                parentId={0}
                                                key={0}
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission}
                                                label="Legal Status"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}


                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderRegistration/GetLookupList?listId=TypeofOrganisation"
                                                id="TypeofOrganisationID"
                                                parentId={0}
                                                key={0}
                                                label="Type Of Organisation"
                                                checkRefreshToken={!props.IsExternal}
                                                disabled={!props.editPermission}
                                                className="w-100"
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Website"
                                                parentId={0}
                                                key={0}
                                                disabled={!props.editPermission}
                                                label="Website"
                                                required
                                                className="w-100"
                                                form={form}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>

                                            <CustomTextField
                                                id="ProviderContactNumber"
                                                required
                                                parentId={0}
                                                key={0}
                                                label="Provider Contact Number"
                                                disabled={!props.editPermission}
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel className="w-100" value={tabValue} index={1}>
                            <tbody>
                                <tr>
                                    <td>
                                        <CustomCheckbox
                                            id="UsePhysicalAddressForPostalAddress"
                                            key={tabValue}
                                            form={form}
                                            disabled={!props.editPermission}
                                            onChange={handleUsePhysicalAddressForPostal}
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                            label="Use Physical Address for Postal Address?"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <GPSCoordinatesAPI
                                            id="Address"
                                            key={tabValue}
                                            className="w-100"
                                            label="Address"
                                            form={form}
                                            disabled={!props.editPermission}
                                            updateGPSAddress={updateGPSAddress}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="GPSCoordinates"
                                            key={tabValue}
                                            label="GPS Coordinates"
                                            className="w-100"
                                            form={form}
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <CustomTextField
                                            parentId={1}
                                            id="PhysicalAddress1"
                                            required
                                            key={tabValue}
                                            label="Physical Address 1"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="PostalAddressLine1"
                                            key={tabValue}
                                            required
                                            label="Postal Address Line 1"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            parentId={1}
                                            id="PhysicalAddress2"
                                            key={tabValue}
                                            required
                                            label="Physical Address 2"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="PostalAddressLine2"
                                            required
                                            key={tabValue}
                                            label="Postal Address Line 2"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <CustomTextField
                                            parentId={1}
                                            id="PhysicalAddress3"
                                            key={tabValue}
                                            label="Physical Address 3"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="PostalAddressLine3"
                                            key={tabValue}
                                            label="Postal Address Line 3"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <AddressCodeItem
                                            id="PhysicalCode"
                                            required={true}
                                            addressId="Physical"
                                            value={physicalCodeValue}
                                            key={tabValue}
                                            validationRegex={/^[0-9]{4,5}$/g}
                                            validationMessage="The code must be 4 or 5 digits long"
                                            provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                            municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                            districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                            urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                            suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                            cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                            className="w-100"
                                            label="Physical Code"
                                            hasMunicipalityID={true}
                                            hasProvinceID={true}
                                            hasCityID={true}
                                            hasDistrictID={false}
                                            hasUrbanRuralID={true}
                                            hasSuburbID={true}
                                            form={form}
                                            disabled={!props.editPermission}
                                            checkRefreshToken={!props.IsExternal}
                                        />
                                    </td>

                                    <td>
                                        <AddressCodeItem
                                            id="PostalCode"
                                            required={true}
                                            addressId="Postal"
                                            value={postalCodeValue}
                                            key={tabValue}
                                            validationRegex={/^[0-9]{4,5}$/g}
                                            validationMessage="The code must be 4 or 5 digits long"
                                            provinceId={!utils.isNull(form.values) ? form.values.PostalProvinceID : null}
                                            municipalityId={!utils.isNull(form.values) ? form.values.PostalMunicipalityID : null}
                                            districtId={!utils.isNull(form.values) ? form.values.PostalDistrictID : null}
                                            urbanRuralId={!utils.isNull(form.values) ? form.values.PostalUrbanRuralID : null}
                                            suburbId={!utils.isNull(form.values) ? form.values.PostalSuburbID : null}
                                            cityId={!utils.isNull(form.values) ? form.values.PostalCityID : null}
                                            className="w-100"
                                            label="Postal Code"
                                            hasMunicipalityID={true}
                                            hasProvinceID={true}
                                            hasCityID={true}
                                            hasDistrictID={false}
                                            hasUrbanRuralID={true}
                                            hasSuburbID={true}
                                            form={form}
                                            disabled={!props.editPermission}
                                            checkRefreshToken={!props.IsExternal}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </TabPanel>
                        <TabPanel className="w-100" value={tabValue} index={2}>
                            <table cellPadding="2" width="80%" index={0}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField id="ContactPersonName"
                                                parentId={2}
                                                key={2}
                                                label="Contact Person Name"
                                                className="w-100"
                                                required
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                        <td>
                                            <CustomTextField id="ContactPersonSurname"
                                                parentId={2}
                                                key={2}
                                                label="Contact Person Surname"
                                                className="w-100"
                                                required
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField id="ContactTelephoneNumber"
                                                parentId={2}
                                                key={2}
                                                label="Contact Telephone Number"
                                                className="w-100"
                                                required
                                                disabled={!props.editPermission}
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                        <td>
                                            <CustomTextField id="ContactCellphoneNumber"
                                                parentId={2}
                                                key={2}
                                                label="Contact Cellphone Number"
                                                className="w-100"
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                required
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField id="ContactEmailAddress"
                                                parentId={2}
                                                key={2}
                                                label="Contact E-mail"
                                                validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                className='{classes.textField} w-100'
                                                required
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                        <td>
                                            <CustomTextField id="ContactDesignation"
                                                parentId={2}
                                                key={2}
                                                label="Contact Designation"
                                                className="w-100"
                                                required
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel className="w-100" value={tabValue} index={3}>
                            <table cellPadding="2" width="80%" index={0}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField id="UserName"
                                                parentId={3}
                                                key={3}
                                                label="Users Name"
                                                required={true}
                                                className="w-100"
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                        <td>
                                            <CustomTextField id="UserSurname"
                                                required={true}
                                                parentId={3}
                                                key={3}
                                                label="Users Surname"
                                                disabled={!props.editPermission}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField id="UserIDNo"
                                                required={true}
                                                parentId={3}
                                                key={3}
                                                disabled={!props.editPermission}
                                                label="User ID Number"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                        <td>
                                            <CustomTextField id="UserTelephoneNumber"
                                                required={true}
                                                parentId={3}
                                                key={3}
                                                label="Users Telephone Number"
                                                disabled={!props.editPermission}
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                        <td>
                                            <CustomTextField id="UserCellphoneNumber"
                                                required={true}
                                                parentId={3}
                                                key={3}
                                                label="Users Cellphone Number"
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField id="UserEmailAddress"
                                                parentId={3}
                                                key={3}
                                                required={true}
                                                label="Users EMail Address"
                                                disabled={!props.editPermission}
                                                validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                className='{classes.textField} w-100'
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form >
            }
        </EntityForm >
    );
})