import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ViewContainer } from "./../../View.jsx";
import ExcelPage from "./CareerGuidanceExcelPage";
import "antd/dist/antd.css";

class BulkIpload extends Component {


    render() {
        return (
            <ViewContainer title="">

                <p className="breadcrumbs">
                    Upload Career Guidance Programme Registration
                </p>

                <table width="100%" hight="50%">
                    <tbody>
                        <tr>
                            <td className="formContainer">
                                <div>
                                    <ExcelPage />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    </table>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(BulkIpload)
);
