import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import CareerGuidanceWorkshopSearchForm from './CareerGuidanceWorkshopSearchForm';
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
    { id: 'EventName', numeric: false, disablePadding: true, label: 'Name of Event' },
    { id: 'StartDate', numeric: false, disablePadding: true, label: 'Start Date' },
    { id: 'EndDate', numeric: false, disablePadding: true, label: 'End Date' }
];



const utils = new ViewUtils();

class CareerGuidanceWorkshopSearch extends Component {
    constructor(props) {
        super(props);

        this.searchParameters =
            [
            { Name: "FinancialYearID", Value: "0" },
            { Name: "EventName", Value: "" },
            { Name: "ProjectID", Value: "0" }
            ]

        let model = 
            {
            EventName: "",
            ProjectID: "",
            FinancialYearID: "0"
            }

        this.state =
        {
            model: model,
            keyUpSwitch: false,
            careerGuidanceWorkshopID: 0,
            toolbarConfig: {
                items: [
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'view', label: '', tooltip: 'View', icon: 'ViewIcon', visible: this.props.menuPermissionItem.View },
                            { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                            { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit },
                            { id: "delete", label: "", tooltip: "Remove Career Guidance Workshop", icon: "DeleteIcon", visible: this.props.menuPermissionItem.Delete }
                        ]
                    }
                ]
            }
        };

        this.deleteUrl = ""
    }


    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/AddCareerGuidanceWorkshop',
                state: {
                    careerGuidanceWorkshopID: 0,
                    CreateNewFlag: true
                }
            })
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/AddCareerGuidanceWorkshop',
                state: {
                    careerGuidanceWorkshopID: this.state.selectedCareerGuidanceWorkshop[0].Id,
                    CreateNewFlag: false,
                    EventName: this.state.selectedCareerGuidanceWorkshop[0].EventName
                }
            })
        }
        else if (buttonId === "view") {
            this.props.history.push({
                pathname: '/AddCareerGuidanceWorkshop',
                state: {
                    careerGuidanceWorkshopID: this.state.selectedCareerGuidanceWorkshop[0].Id,
                    viewOnly: true,
                    CreateNewFlag: false,
                    EventName: this.state.selectedCareerGuidanceWorkshop[0].EventName
                }
            })
        }


    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedCareerGuidanceWorkshop: selection })
        } else {
            this.setState({ selectedCareerGuidanceWorkshop: null })
        }
    }


    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Career Guidance Workshop`}
                </p>
                <SearchView headCells={headCells} dataUrl={"api/sims/CareerGuidance/GetCareerGuidanceSearch"}
                    deleteUrl={"api/sims/CareerGuidance/DeleteCareerGuidanceWorkshop"} useDeletePost={true}
                    entityViewPath='/AddCareerGuidanceWorkshop'
                    model={this.state.model} toolbarConfig={this.state.toolbarConfig}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="CareerGuidanceWorkshopEventName" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    handleObject={true}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Career Guidance Workshop"}
                    promptContent={"Are you sure you want to remove the career guidance workshop record?"}
                >
                    <CareerGuidanceWorkshopSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(CareerGuidanceWorkshopSearch));
