import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ModulesTable from '../ModulesTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import ModuleSetupSearch from '../program/ModuleSetupSearch';
import LinkDialog from "../LinkDialog";
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";


const utils = new ViewUtils();

const headCells = [
    { id: 'QCTOQualificationID', numeric: false, disablePadding: true, label: 'QCTO Module ID' },
    { id: 'QCTOQualificationTitle', numeric: false, disablePadding: true, label: 'QCTO Module Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'ModuleType', numeric: false, disablePadding: true, label: 'Module Type' }
];

const toolbarRef = React.createRef();

class LearnerModuleQualificationUSSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkDialogOpen: false,
            selectedLinkType: null,
            selectedLinkStandards: null,
            selectedModule: null,
            keyUpSwitch: false,
            propmtOpen: false,
            promptTitle: "Unlink Module From The Learners ModuleQualification",
            promptContent: "Are you sure you want to unlink this Module from the Learners ModuleQualification?",
            message: "",
            messageStyle: "",
            searchParameters: [
                { Name: "LearnerModuleQualificationID", Value: this.props.location.state.ModuleQualificationID },
                { Name: "LearnerID", Value: this.props.location.state.LearnerID }
            ]
           
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/AddLearnerModuleQualification',
                state: {
                    LearnerID: this.props.location.state.LearnerID,
                    ModuleQualificationID: this.props.location.state.ModuleQualificationID,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    viewOnly: this.props.location.state.viewOnly,
                    permissions: this.props.location.state.permissions
                }
            });
        }
    }

    render() {
        return (
            <ViewContainer>
                <p className="breadcrumbs">
                    {"Learner QCTO Qualification Modules"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                    <ModulesTable headCells={headCells} 
                        dataUrl="api/sims/LearnerModuleQualification/GetLearnerQualificationModulesSearch"
                        height={"50vh"}
                        searchParameters={this.state.searchParameters} paged={false} />
                </div>
            </ViewContainer>

        );
    }
}

export default withRouter(
    connect()(LearnerModuleQualificationUSSearch)
);
