import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DocumentUpload from '../../../widgets/DocumentUpload';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import AlertItem from "../../../widgets/AlertItem";
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();

export default function WSPATRBulkImportDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [AddDiasbaled, setAddDiasbaled] = React.useState(true);
    const [Message, setMessage] = React.useState("");
    const [MessageStyle, setMessageStyle] = React.useState("");
    const formRef = React.createRef();
    const [filename, setFilename] = React.useState("");
    const [size, setSize] = React.useState(null);
    const [base64, setBase64] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [showReport, setShowReport] = React.useState(false);
    const [documentName, setDocumentName] = React.useState("");

    const handleClose = (cancel, save, response) => {
        setFilename("");
        setOpen(false);
        props.closeHandler(cancel, save, response);
    };

    const cancel = () => {
        setMessage('');
        setMessageStyle('');
        setAddDiasbaled(true);
        setFilename("");
        setShowReport(false);
        handleClose(true, false);
    };

    const add = () => {
        console.log(type);
        let data = {};
        data.Document = {};
        data.Document.Name = filename;
        data.Document.size = size;
        data.Document.type = type;
        data.Document.base64 = base64;
        data.WSPATRID = props.WSPATRID;
        data.WSPATRFormsID = props.WSPATRFormsID;

        if (
            !(
                type === "application/vnd.ms-excel" ||
                type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            )
        ) {
            setMessage("Unknown file format. Only Excel files are uploaded!");
            setMessageStyle('ERROR');
            setAddDiasbaled(true);
            setFilename("");
            return false;
        }
        const url = `api/sims/WSPATRForms2023/BulkImport`;

        //utils.invokeUrl(url, (response) => {
        //    successCallback(response);
        //}, (response) => { error(response); },
        utils.invokeUrl(url, (response) => {
            handleClose(false, true, response);
        }, () => { handleClose(false, false); },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        );
    }

    const successCallback = (response) => {
        setMessage('Import Completed');
        setMessageStyle('SUCCESS');
        setShowReport(true);
        setDocumentName(response.Message);
        setAddDiasbaled(true);
        setFilename("");
    }

    const error = (response) => {
        setMessage(response.Message);
        setMessageStyle(response.MessageType);
    }

    const onUploadHandler = (data) => {
        if (data) {
            setMessage('');
            setMessageStyle('');
            setFilename(data.name);
            setSize(data.size);
            setBase64(data.base64);
            setType(data.type);
            setAddDiasbaled(false);
        }
    }

    const FileDownload = () => {
        utils.invokeUrl("api/sims/WSPATRForms2023/DownloadBulkImportTemplate?WSPATRFormsID=" + props.WSPATRFormsID, (response) => {

            var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }
        );
    };

    const ReportDownload = () => {

        utils.invokeUrl("api/sims/WSPATRForms2023/BulkUploadGetReport?FileName=" + documentName, (response) => {

            var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
                setMessage('Report Download Failed');
                setMessageStyle('ERROR');
        }
        );

    };

    const base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-60 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}>
                <div style={{ padding: "32px" }}>
                    <p className="legend">
                        {"WSP/ATR Bulk Import"}
                    </p>
                    <br/>
                    <div style={{ paddingLeft: "24px", paddingRight: "44px" }}>
                        <AlertItem message={Message} alertStyle={MessageStyle} />
                    </div>

                    <div style={{ height: "80%" }}>
                        <a
                            onClick={() => { FileDownload() }}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            style={{ textDecoration: "underline" }}
                        >Download template</a>
                        <br/>
                        <DocumentUpload showViewer={false} onUploadHandler={onUploadHandler} filename={filename} />
                    </div>
                    <div style={{ height: "80%" }}>
                        {showReport?<a
                            onClick={() => { ReportDownload() }}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            style={{textDecoration:"underline"}}
                        >Download Report</a>: null }
                    </div>
                    <DialogActions>
                        <Button onClick={add} color="primary" disabled={AddDiasbaled}>
                            Import
                        </Button>
                        <Button onClick={cancel} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}