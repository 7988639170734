import { withRouter } from 'react-router-dom';
import React, { Component } from "react";
import "../Grid.css";
import "../Form.css";
import "../App.css";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import EnhancedToolbar from '../widgets/Toolbar';
import EnhancedTable from '../widgets/ResponsiveTable';
import AlertItem from "../widgets/AlertItem";
import withMenuPermission from "../widgets/withMenuPermission";

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const toolbarRef2 = React.createRef();

const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'IDNo', numeric: false, disablePadding: true, label: 'ID No' },
    { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
];

class SkillsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
            searchParameters: [],
            clearSelectedRow: false,
            formArray: [],
            StakeholderID: null,
            stakeholderName: null,
            IDNo: null,
            OrganisationId: null
        }

        this.toolbarConfig = {
            items: [

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View Organisation', icon: 'ViewIcon', disabled: true }
                    ]
                }
            ]
        }

        this.toolbarConfig2 = {
            items: [

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view2', label: '', tooltip: 'View Organisation', icon: 'ViewIcon', disabled: true }
                    ]
                }
            ]
        }
    }

    //componentDidMount() {

    //    this.getDashboardDetails()
    //}
    //getDashboardDetails() {
    //    let url = "api/sims/SkillsDashboard/GetOrganisationSDFsForApproval";
    //    utils.invokeUrl(url, (response) => {
    //        this.setState({
    //            formArray: response,
    //            stakeholderName: response.FirstName + " " + response.Surname,
    //            IDNo: response.IDNo,
    //            StakeholderID: response.ID,
    //            searchParameters: [{ Name: "StakeholderID", Value: response.ID }]
    //        })
    //    },
    //        null,
    //        {
    //            method: 'GET',
    //            headers: {
    //                'Accept': 'application/json',
    //                'Content-Type': 'application/json',
    //                'Authorization': "Bearer " + localStorage.getItem("token")
    //            }
    //        }
    //    )
    //}


    handleToolbarButtonClick = (buttonId) => {
        //console.clear();
        //console.log(this.state.StakeholderID);
        if ("view" === buttonId) {
            this.props.history.push({
                pathname: "/SkillsOrganisation",
                state: {
                    selection: this.state.OrganisationId,
                    isDashboard: true
                }
            })
        }
        else
            if ("view2" === buttonId) {
                this.props.history.push({
                    pathname: "/DGOrganisation",
                    state: {
                        selection: this.state.OrganisationId,
                        isDashboard: true
                    }
                })
            }
    }

    handleFormValueChange(values) {

        let newsearchParameters = [...this.state.searchParameters];

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
        });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedId: selection[0].Id, Message: "", clearSelectedRow: false, OrganisationId: selection[0].OrganisationID });
        }
        else {
            this.setState({ selectedId: null, Message: "", clearSelectedRow: false, OrganisationId: null });
        }
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
    }

    handleSelectionChange2 = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedId: selection[0].Id, Message: "", clearSelectedRow: false, OrganisationId: selection[0].OrganisationID });
        }
        else {
            this.setState({ selectedId: null, Message: "", clearSelectedRow: false, OrganisationId: null });
        }
        toolbarRef2.current.setButtonDisabled("view2", selection === null || selection.length === 0);
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                addDialogOpen: false,
                selectedId: null,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                showTable: false,
                clearSelectedRow: true
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                selectedId: null,
                Message: "",
                MessageStyle: "",
                clearSelectedRow: true
            })
        }
        else {
            this.setState({
                addDialogOpen: false,
                selectedId: null,
                clearSelectedRow: true
            })
        }
    }

    closePrompt = () => {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null })
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />
                <br />
                <table width="100%" class="FormsTableHeader" color="#FFFFFF" backgroundColor="#AD0049">
                    <tbody>
                        <tr>
                            <td align="center" bgcolor="#AD0049">
                                <font color="#FFFFFF" size="4"><b>{"Skills Dashboard"}</b></font>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <br /><br />
                    <table width="100%" class="FormsTableHeader">
                        <tbody>
                            <tr>
                                <td align="center" bgcolor="#AD0049">
                                    <font color="#FFFFFF" size="4"><b>{"SDFs"}</b></font>
                                    <div className="searchResults">

                                        <EnhancedToolbar highlight={false}
                                            title="SDFs"
                                            buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                            ref={toolbarRef}
                                            config={this.toolbarConfig}
                                        />

                                        <EnhancedTable
                                            headCells={headCells}
                                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                                            dataUrl={"api/sims/SkillsDashboard/GetOrganisationSDFsForApproval"}
                                            searchParameters={this.state.searchParameters}
                                            clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                                            height={"50vh"} bodyMaxHeight={"60vh"}
                                            paged={true} />
                                    </div>
                                </td>
                                <td>&nbsp;</td>
                                <td align="center" bgcolor="#AD0049">
                                    <font color="#FFFFFF" size="4"><b>{"Stakeholders"}</b></font>
                                    <div className="searchResults">

                                        <EnhancedToolbar highlight={false}
                                            title="Stakeholders"
                                            buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                            ref={toolbarRef2}
                                            config={this.toolbarConfig2}
                                        />

                                        <EnhancedTable
                                            headCells={headCells}
                                            onSelectionChange={(selection) => { this.handleSelectionChange2(selection) }}
                                            dataUrl={"api/sims/SkillsDashboard/GetOrganisationStakeholdersForApproval"}
                                            searchParameters={this.state.searchParameters}
                                            clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                                            height={"50vh"} bodyMaxHeight={"60vh"}
                                            paged={true} />
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(SkillsDashboard));