import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import DGDocumentsUploadSearchForm from './DGDocumentUploadsSearchForm'
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/ResponsiveTable';
import DGDocumentUploadsDialog from './DGDocumentUploadsDialog';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
    { id: 'Comment', numeric: false, disablePadding: true, label: 'Comment' },
    { id: 'DocumentType', numeric: false, disablePadding: true, label: 'Document Type' },
    { id: 'Document', numeric: false, disablePadding: true, label: 'Document', contentType: 'document' },
    { id: 'UploadedBy', numeric: false, disablePadding: true, label: 'Uploaded By' },
    { id: 'UploadDate', numeric: false, disablePadding: true, label: 'Upload Date' }

];

class DGDocumentsUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                FinancialYearID: null,
                Id: this.props.location.state.selection
            },
            addDialogOpen: false,
            selectedDocument: null,
            promptOpen: false,
            promptTitle: "Remove Document",
            promptContent: "Are you sure you want to remove this Document?",
            searchParameters: [{ Name: "OrganisationID", Value: this.props.location.state.organisationID }, { Name: "DGDocumentTypeID", Value: 0 }],
            DGDocumentTypeID: null,
            DocumentDescription: null,
            toolbarConfig: {
                items: []
            }
        }
    }

    componentDidMount() {
        this.CheckNonLevyPayer();
    }

    CheckNonLevyPayer = () => {
        this.setState({
            toolbarConfig: {
                items: [

                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: "CloseIcon" }
                        ]
                    }
                    ,
                    {
                        type: "devider"
                    },

                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'add', label: '', tooltip: 'Upload Document', disabled: utils.isNull(this.state.DocumentRelatesID), icon: 'UploadIcon', visible: this.props.menuPermissionItem.Add },
                            { id: 'remove', label: '', tooltip: 'Remove Document', disabled: utils.isNull(this.state.selectedContact), icon: 'DeleteIcon', visible: this.props.menuPermissionItem.Delete }
                        ]
                    }
                ]
            }
        }, () => {
            let url = "api/sims/DGDocumentUpload/CheckDGOrganisationNonLevyPayer?DGOrganisationID=" + this.props.location.state.organisationID;
            utils.invokeUrl(url, (response) => {
                if (response.Result === "1") {
                    this.setState({
                        toolbarConfig: {
                            items: [

                                {
                                    type: "buttonGroup",
                                    buttons: [
                                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: "CloseIcon" }
                                    ]
                                }
                                ,
                                {
                                    type: "devider"
                                },

                                {
                                    type: "buttonGroup",
                                    buttons: [
                                        { id: 'add', label: '', tooltip: 'Upload Document', disabled: utils.isNull(this.state.DocumentRelatesID), icon: 'UploadIcon', visible: this.props.menuPermissionItem.Add },
                                        { id: 'remove', label: '', tooltip: 'Remove Document', disabled: utils.isNull(this.state.selectedContact), icon: 'DeleteIcon', visible: this.props.menuPermissionItem.Delete },
                                        //{ id: 'PivotalPlanned', label: 'Pivotal Planned Template', tooltip: 'Download Pivotal Planned Template', disabled: false},
                                        //{ id: 'PivotalActual', label: 'Pivotal Actual Template', tooltip: 'Download Pivotal Actual Template', disabled: false},
                                    ]
                                }
                            ]
                        }
                    })
                }
            },
                null,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        });
    }


    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true });

        } else if (buttonId === "close") {
            this.props.history.push({
                pathname: "/DGOrganisation",
                state: { selection: this.props.location.state.organisationID }
            })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        //else if ("PivotalPlanned" === buttonId) {
        //    this.FileDownloadTemplate(1);
        //}
        //else if ("PivotalActual" === buttonId) {
        //    this.FileDownloadTemplate(0);
        //}
        else {
            // this.setState({ editDialogOpen: true });
        }
    }

    //FileDownloadTemplate = (isPlan) => {
    //    utils.invokeUrl("api/sims/DGDocumentUpload/DownloadBulkImportTemplate?IsPlan=" + isPlan, (response) => {

    //        var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
    //        var url = URL.createObjectURL(blob);

    //        const link = document.createElement('a');
    //        link.href = url;
    //        link.download = response.Name;
    //        link.target = '_blank';
    //        link.click();
    //    }
    //    );
    //};

    base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleFormValueChange(values) {

        let DGDocumentTypeID = values.DocumentType.Id;
        let DocumentDesc = values.DocumentType.Description;
        let newsearchParameters = [...this.state.searchParameters];
        let temp_element = { ...newsearchParameters[1] };
        temp_element.Value = DGDocumentTypeID
        newsearchParameters[1] = temp_element;

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            DGDocumentTypeID: DGDocumentTypeID,
            DocumentDescription: DocumentDesc
        });
        toolbarRef.current.setButtonDisabled("add", DGDocumentTypeID === null || DGDocumentTypeID.length === 0);
    }

    FileDownload = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            utils.invokeUrl("api/sims/DGDocumentUpload/downloaddocument?id=" + rowid, (response) => {

                var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedDocument: selection[0].Id, Message: "" });
        } else {
            this.setState({ selectedDocument: null, Message: "" });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    postAddHandler(cancel, save, response) {
        if (save === true) {
            if (response.MessageType === "SUCCESS") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    addDialogOpen: false,
                    selectedDocument: null,
                    Message: response.Message,
                    MessageStyle: "success"
                });
                toolbarRef.current.setButtonDisabled("remove", true);
            }
            else if (response.MessageType === "ERROR") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    addDialogOpen: false,
                    selectedDocument: null,
                    Message: response.Message,
                    MessageStyle: "warning"
                });
                toolbarRef.current.setButtonDisabled("remove", true);
            }
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                selectedDocument: null
            })
            toolbarRef.current.setButtonDisabled("remove", true);
        }
    }


    successfulDocumentRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedDocument: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulDocumentRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    remove(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("edit", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedDocument}`;
        utils.invokeUrlDelete(fullUrl, this.successfulDocumentRemoveCallback, this.unsuccessfulDocumentRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedDocument: null })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"DG Organisation > Document Uploads " + (this.props.location.state.organisationName !== null ? "> " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    < DGDocumentsUploadSearchForm
                        // data={this.state.model}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                    />

                    <EnhancedToolbar highlight={false}
                        title="DG Document Uploads"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.state.toolbarConfig}
                    />

                    <EnhancedTable
                        headCells={headCells}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/DGDocumentUpload/GetSearch"
                        searchParameters={this.state.searchParameters}
                        onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                        paged={true} />
                </div>

                <DGDocumentUploadsDialog
                    open={this.state.addDialogOpen}
                    docType={this.state.DocumentDescription}
                    docID={this.state.DGDocumentTypeID}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                    viewRef={this}
                    OrganisationID={this.props.location.state.organisationID}
                />

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.remove("api/sims/DGDocumentUpload/RemoveDGDocument") }}
                    closePrompt={this.closePrompt}
                />

            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(DGDocumentsUpload));
