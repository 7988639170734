import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import DocumentUpload from "../../widgets/DocumentUpload";

export const ProviderLogoApplicationForm = React.forwardRef((props, ref) => {

    const [tabValue, setTabValue] = React.useState(0);
    const [Documentfilename, setDocumentFilename] = React.useState("");
    const utils = new ViewUtils();

    function ProviderLogoApplicationTypeCheckShow(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    const onDocumentUploadHandler = (data) => {
        if (data) {
            setDocumentFilename(data.name);
        }
    };

    function FileDownload(id) {
        utils.invokeUrl("api/sims/providerlogoapplication/DownloadDocument?id=" + id, (response) => {

            var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    function base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    function GetProviderLogoApplicationStatusID(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    return (
        <EntityForm ref={ref} viewId="providerlogoapplication" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="providerLogoApplicationAddForm">

                    <div className="row w-100">
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                parentId={0}
                                                dataUrl="api/sims/providerlogoapplication/GetLookupList?listId=ProviderLogoApplicationType"
                                                id="ProviderLogoApplicationTypeID"
                                                key={tabValue}
                                                label="Type of application"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                        <td>
                                            {ProviderLogoApplicationTypeCheckShow(form.values["ProviderLogoApplicationTypeID"]) &&
                                                <CustomTextField
                                                    id="ProviderLogoApplicationTypeOther"
                                                    key={tabValue}
                                                    label="Other type of application"
                                                    className="w-100"
                                                    form={form}
                                                    required={ProviderLogoApplicationTypeCheckShow(form.values["ProviderLogoApplicationTypeID"])}
                                                />
                                            }
                                        </td>
                                    </tr>

                                    {props.id && <tr>
                                        <td>
                                            <SelectItem
                                                parentId={0}
                                                dataUrl={'api/sims/providerlogoapplication/GetProviderLogoApplicationStatusByRole?parameter=[{"Name":"ProviderLogoApplicationStatusId","Value":' + GetProviderLogoApplicationStatusID(form.values["ProviderLogoApplicationStatusID"]) + '}]'}
                                                id="ProviderLogoApplicationStatusID"
                                                key={tabValue}
                                                label="Status of submission"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                        <td>
                                        </td>
                                    </tr>}
                                    <tr>
                                        <td>
                                            <DocumentUpload
                                                showViewer={false}
                                                onUploadHandler={onDocumentUploadHandler}
                                                buttonLabel="Upload Document"
                                                filename={Documentfilename}
                                                form={form}
                                                required={props.documentRequired}
                                                id="Document"
                                            />
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    {form.values["OriginalFileName"] !== "" && !utils.isNull(form.values["OriginalFileName"]) &&
                                        <tr>
                                            <td>
                                                <a id="aBankDetail" onClick={() => FileDownload(props.id)} >Download Document</a>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})