import React from 'react';
import { CustomTextField } from '../../../../widgets/CustomTextField';
import { EntityForm } from '../../../../widgets/Forms';
import ViewUtils from '../../../../ViewUtils';
import TabPanel from '../../../../widgets/TabPanel';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { LookupField } from '../../../../widgets/LookupField';
import Table from '@material-ui/core/Table';
import { SelectItem } from '../../../../widgets/SelectItem';
import { CustomDatePicker } from '../../../../widgets/CustomDatePicker';
import SkillsProgrammeSearch from '../../Controls/SkillsProgrammeSearch';
import ProviderSearch from '../../Controls/ProviderSearch';
import OFOSearch from '../../Controls/OFOSearch';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

const styles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: 50,
            textAlign: 'center',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        minWidth: 200
    },
    inputForm: {
        minWidth: '200px',
    },
    table: {
        minWidth: 700,
    },
    inputCenter: {
        textAlign: 'center'
    },
    paper: {
        position: "absolute",
        width: 600,
        height: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0.1)
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#D9B262',
        color: theme.palette.common.white,
        border: '1px solid white',
        height: 6
    },
    body: {
        fontSize: 11,
    },

}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            // width: 50,
            textAlign: 'center'
        },
    },
    body: {
        fontSize: 14,
        fontWeight: 500
    }

}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);

const useStyles = theme => ({
    table: {
        minWidth: 700,
    },
});

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 5;
    const left = 5;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

export const SkillsProgrammeForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function checkProviderParam(value) {
        if (value !== undefined && value !== null) {
            return value.Id;
        }
        else {
            return 0;
        }

    }

    function GetProvinceID(value) {
        //console.clear();
        //console.log(value);
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    const GetQualificationDetails = (form, value) => {
        if (!utils.isNull(value)) {
            let url = "api/sims/DGApplicationForms2024/GetDGSkillsProgrammeQualification?SkillsProgrammeID=" + value.Id;
            utils.invokeUrl(url, (response) => {
                if (response.MessageType === "SUCCESS") {
                    form.updateValue("Qualification", response.Message);
                }
            },
                null,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        }
    }

    function QualificationVacanciesNoCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "No") {
                return true
            }
            else if (value.Description !== "No") {
                return false
            }
        }
    }

    function QualificationVacanciesYesCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Yes") {
                return true
            }
            else if (value.Description !== "Yes") {
                return false
            }
        }
    }

    function DGHardToFillVacancyReasonOtherCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    return (
        <EntityForm
            ref={ref}
            viewId={null}
            values={props.data}
            entityLookupController={props.entityLookupController}
            org="saseta"
            entityLookupId={props.id}
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="EmploymentNumber">
                    <div className="row w-100">
                        <div className="col w-100">
                            <TabPanel value={tabValue} index={0} className=''>
                                <table cellPadding="2" width="80%">
                                    <tbody>
                                        <tr>

                                            <td width="50%">
                                                <LookupField
                                                    id="ProviderID"
                                                    //required
                                                    label="Training Provider"
                                                    className="w-100"
                                                    form={form}
                                                >
                                                    <ProviderSearch showTitle={true} mode='lookup' dataUrl="api/sims/DGApplicationForms2024/GetDGFormProviderSearch"
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("ProviderID", value)
                                                        }} autoFetchData={false} selection={props.selection} />
                                                </LookupField>
                                            </td>
                                            <td width="40%">
                                                {/*<LookupField*/}
                                                {/*    id="OFOOccupationID"*/}
                                                {/*    required*/}
                                                {/*    label="OFO Code"*/}
                                                {/*    className="w-100"*/}
                                                {/*    form={form}*/}
                                                {/*>*/}
                                                {/*    <OFOSearch showTitle={true} mode='lookup' dataUrl="api/sims/DGApplicationForms2024/GetDGFormOFOCodeSearch"*/}
                                                {/*        onLookupValueChange={(value) => {*/}
                                                {/*            form.setLookupSelection("OFOOccupationID", value)*/}
                                                {/*        }} autoFetchData={false} selection={props.selection} DGApplicationID={props.DGApplicationID} />*/}
                                                {/*</LookupField>*/}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="40%">
                                                <SelectItem
                                                    dataUrl="api/sims/DGApplicationForms2024/GetLookupList?listId=DGSocioEconomicStatus"
                                                    id="DGSocioEconomicStatusID"
                                                    key={tabValue}
                                                    onChange={props.onChangeProvinceIDHandler}
                                                    label="Socio Economic Status"
                                                    className="w-100"
                                                    form={form}
                                                    allowEmptyValue={true}
                                                    //required
                                                />
                                            </td>
                                            <td width="40%">
                                                <CustomTextField
                                                    id="Vacancy"
                                                    key={tabValue}
                                                    label="Vacancy"
                                                    className="w-100"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                    //required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="40%">
                                                <LookupField
                                                    id="SkillsProgrammeID"
                                                    //required
                                                    label="Skills Programme"
                                                    className="w-100"
                                                    form={form}
                                                >
                                                    <SkillsProgrammeSearch showTitle={true} mode='lookup' dataUrl="api/sims/DGApplicationForms2024/GetDGFormSPByProviderSearch"
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("SkillsProgrammeID", value)
                                                            GetQualificationDetails(form, value);
                                                        }} autoFetchData={false} selection={props.selection} programmeId={checkProviderParam(form.values["ProviderID"])} />
                                                </LookupField>
                                            </td>
                                            <td width="40%">
                                                <CustomTextField
                                                    id="NoOfLearners"
                                                    key={tabValue}
                                                    label="Number of Learners"
                                                    className="w-100"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                    //required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="40%">
                                                <CustomDatePicker
                                                    id="StartDate"
                                                    label="Start Date"
                                                    className='w-100'
                                                    //required
                                                    form={form}
                                                />
                                            </td>
                                            <td width="40%">
                                                <CustomDatePicker
                                                    id="EndDate"
                                                    label="End Date"
                                                    className='w-100'
                                                    //required
                                                    form={form}
                                                />
                                                {/*<CustomTextField*/}
                                                {/*    id="Mentor"*/}
                                                {/*    key={tabValue}*/}
                                                {/*    parentId={0}*/}
                                                {/*    label="Name of Mentor"*/}
                                                {/*    className="w-100"*/}
                                                {/*    form={form}*/}
                                                {/*//required*/}
                                                {/*/>*/}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/DGApplicationForms2024/GetLookupList?listId=YesNo"
                                                    id="QualificationVacanciesYesNoID"
                                                    key={tabValue}
                                                    label="Does the Qualification address the hard to fill vacancies in the SSP?"
                                                    className="w-100"
                                                    form={form}
                                                    allowEmptyValue={true}
                                                    //required
                                                />
                                            </td>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/DGApplicationForms2024/GetLookupList?listId=DGHardToFillVacancyList"
                                                    id="DGHardToFillVacancyListID"
                                                    key={tabValue}
                                                    label="Hard to Fill Vacancy List"
                                                    className="w-100"
                                                    form={form}
                                                    allowEmptyValue={true}
                                                    disabled={!QualificationVacanciesYesCheck(form.values["QualificationVacanciesYesNoID"])}
                                                    //required={QualificationVacanciesYesCheck(form.values["QualificationVacanciesYesNoID"])}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/DGApplicationForms2024/GetLookupList?listId=DGHardToFillVacancyReason"
                                                    id="DGHardToFillVacancyReasonID"
                                                    key={tabValue}
                                                    label="Hard to fill vacancy reason"
                                                    className="w-100"
                                                    form={form}
                                                    allowEmptyValue={true}
                                                    disabled={!QualificationVacanciesNoCheck(form.values["QualificationVacanciesYesNoID"])}
                                                    //required={QualificationVacanciesNoCheck(form.values["QualificationVacanciesYesNoID"])}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="DGHardToFillVacancyReasonOther"
                                                    key={tabValue}
                                                    parentId={0}
                                                    label="Other (Reason)"
                                                    className="w-100"
                                                    form={form}
                                                    disabled={!DGHardToFillVacancyReasonOtherCheck(form.values["DGHardToFillVacancyReasonID"])}
                                                    //required={DGHardToFillVacancyReasonOtherCheck(form.values["DGHardToFillVacancyReasonID"])}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/DGApplicationForms2024/GetLookupList?listId=Province"
                                                    id="ProvinceID"
                                                    key={tabValue}
                                                    onChange={props.onChangeProvinceIDHandler}
                                                    label="Province"
                                                    className="w-100"
                                                    form={form}
                                                    allowEmptyValue={true}
                                                    //required
                                                />
                                            </td>
                                            <td>
                                                <SelectItem
                                                    dataUrl={'api/sims/DGApplicationForms2024/GetDGMunicipalityByProvince?parameter=[{"Name":"ProvinceID","Value":' + GetProvinceID(form.values["ProvinceID"]) + '}]'}
                                                    id="MunicipalityID"
                                                    key={tabValue}
                                                    label="Municipality"
                                                    className="w-100"
                                                    form={form}
                                                    allowEmptyValue={true}
                                                    clearOptions={props.clearOptions}
                                                    clearOptionsHandler={props.clearOptionsHandler}
                                                    //required
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Table className={styles.table} size="small" >
                                    <TableHead>

                                        <TableRow>
                                            <StyledTableCell align="center" colSpan={3}>African</StyledTableCell>
                                            <StyledTableCell align="center" colSpan={3}>Coloured</StyledTableCell>
                                            <StyledTableCell align="center" colSpan={3}>Indian/Asian</StyledTableCell>
                                            <StyledTableCell align="center" colSpan={3}>White</StyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>

                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>

                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>

                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow color="primary">
                                            <StyledTableTextCell align="center"><CustomTextField id="AfricanMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="AfricanFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="AfricanDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>

                                            <StyledTableTextCell align="center"><CustomTextField id="ColouredMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="ColouredFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="ColouredDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>

                                            <StyledTableTextCell align="center"><CustomTextField id="IndianMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="IndianFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="IndianDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>

                                            <StyledTableTextCell align="center"><CustomTextField id="WhiteMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="WhiteFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="WhiteDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" /*required*/ validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                        </StyledTableRow>

                                    </TableBody>
                                </Table>
                            </TabPanel>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})