import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import { LearnerRPLForm } from '../../etqa/program/registration/LearnerRPLForm';

const RPLRef = React.createRef();
const utils = new ViewUtils();

class AddLearnerRPL extends Component {

    state = {
        messageStyle: "message",
        id: null,
        showTable: true,
        CurrentProgramme: this.props.location.state.RPLID,
        CreateNewFlag: this.props.location.state.hideFields,
        Learner: this.props.location.state.LearnerID,
        NavPerm: this.props.location.state.permissions,
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon', visible: !this.props.location.state.viewOnly }
                    ]
                }
            ]
        }
    };


    handleFormValueChange = (values) => {
    }


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        }
    }

    save() {
        let formValue = RPLRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.LearnerID;
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnerrpl/LinkLearnerRPL", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    CurrentProgramme: response.EntityId,
                    CreateNewFlag: false
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    closeView() {

        this.props.history.push({
            pathname: '/LearnerRPLSearch',
            state: {
                learnerID: this.props.location.state.LearnerID,
                learnerNames: this.props.location.state.learnerNames
            }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {
                        (this.props.location.state.hideFields) ?
                            `Learner > Link New RPL > ${this.props.location.state.learnerNames}`
                            :
                            `Learner > Update RPL > ${this.props.location.state.learnerNames}`
                    }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                        <LearnerRPLForm ref={RPLRef}
                                            viewRef={this}
                                            id={this.state.CurrentProgramme}
                                            editPermission={this.state.NavPerm}
                                            viewOnly={this.props.location.state.viewOnly}
                                            hideFields={this.state.CreateNewFlag}
                                            lid={this.props.location.state.LearnerID}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AddLearnerRPL);
