import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Snackbar, Grid, Paper } from '@material-ui/core';
import { ViewContainer } from './../../View.jsx';
import ViewUtils from '../../ViewUtils';
import Alert from '@material-ui/lab/Alert';
import withMenuPermission from "../../widgets/withMenuPermission";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

const utils = new ViewUtils();

class IDVerificationPortal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbarOpen: false,
            showModal: false,
            idNumber: '',
            idn: '',
            dhaname: '',
            dhasurname: '',
            deadIndicator: '',
            dateOfDeath: '',
            idnBlocked: '',
            maritalStatus: '',
            dateOfMarriage: '',
            isValid: '',
            BirthPlaceCountryCode: '',
            simsName: '',
            simsSurname: '',
            snackbarMessage: '',
            snackbarSeverity: 'error',
            showBulkModal: false,
            selectedFile: null,
            alertOpen: false,
            message: '',
            messageType: '',
            showBatchResultsModal: false,
            batchNumber: '',
            dhaStatus: null,
            fromDate: null,
            toDate: null,
            formattedFromDate: '',
            formattedToDate: '',
            fromDateError: false,
            toDateError: false,
        };
    }

    componentDidMount() {
        this.fetchDhaStatus();
    }

    fetchDhaStatus() {
        let url = "api/sims/IDVerificationPortal/GetDHAStatus";
        utils.invokeUrl(url, (response) => {
            this.setState({
                dhaStatus: response
            });
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        );
    }

    renderLightIndicator() {
        const { dhaStatus } = this.state;
        if (dhaStatus === null) return null;

        const lightStyle = {
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundColor: dhaStatus === true ? 'green' : 'red',
            border: '2px solid #333',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '10px auto',
        };

        return <div style={lightStyle}></div>;
    }

    renderDhaStatusMessage() {
        const { dhaStatus } = this.state;
        if (dhaStatus === null) return null;

        return dhaStatus === true ? "Home Affairs is Online" : "Home Affairs is Offline";
    }

    toggleBatchResultsModal = () => {
        this.setState(prevState => ({
            showBatchResultsModal: !prevState.showBatchResultsModal
        }));
    }

    handleBatchNumberChange = (event) => {
        this.setState({ batchNumber: event.target.value });
    }

    handleSnackbarOpen = (message, severity) => {
        this.setState({ snackbarOpen: true, snackbarMessage: message, snackbarSeverity: severity });
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackbarOpen: false });
    }

    toggleModal = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    handleInputChange = (event) => {
        this.setState({ idNumber: event.target.value });
    }

    handleAlertOpen = () => {
        this.handleSnackbarOpen("Please select a file before uploading.", "error");
    }

    verify = () => {
        utils.invokeUrl("api/sims/IDVerificationPortal/VerifySingularIDNumber?idNumber=" + this.state.idNumber, (response) => {
            if (utils.isNull(response.ErrorMessage)) {
                this.setState({
                    idn: response.Idn,
                    dhaname: response.DhaName,
                    dhasurname: response.DhaSurname,
                    deadIndicator: response.DeadIndicator ? "Yes" : "No",
                    dateOfDeath: response.DateOfDeath,
                    idnBlocked: response.IdnBlocked ? "Yes" : "No",
                    maritalStatus: response.MaritalStatus,
                    dateOfMarriage: response.DateOfMarriage,
                    isValid: response.IsValid ? "Valid" : "Invalid",
                    BirthPlaceCountryCode: response.BirthPlaceCountryCode,
                    simsName: response.SimsName || "Record does not exist in SIMS",
                    simsSurname: response.SimsSurname || "Record does not exist in SIMS"
                });
            }
            else {
                this.handleSnackbarOpen(response.ErrorMessage, "error");
            }
            this.toggleModal();
        }, (e) => {
            this.handleSnackbarOpen("Something went wrong, please try again later.", "error");
        });
    }

    syncData = () => {
        utils.invokeUrl(`api/sims/IDVerificationPortal/SyncDHAPersonData?idNumber=${this.state.idn}`, (response) => {
            if (response.IsValid) {
                this.setState({
                    isValid: response.IsValid ? "Valid" : "Invalid",
                    simsName: response.SimsName || "Record does not exist in SIMS",
                    simsSurname: response.SimsSurname || "Record does not exist in SIMS"
                });
                this.handleSnackbarOpen("Data synced successfully!", "success");
            }
            else {
                this.handleSnackbarOpen(response.ErrorMessage, "error");
            }
        }, (e) => {
            this.handleSnackbarOpen("Something went wrong while syncing, please try again later.", "error");
        });
    }

    toggleBulkModal = () => {
        this.setState(prevState => ({
            showBulkModal: !prevState.showBulkModal
        }));
    }

    downloadTemplate = () => {
        utils.invokeUrl("api/sims/IDVerificationPortal/DownloadVerificationTemplate", (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        });
    }

    fetchBatchResults = () => {
        utils.invokeUrl(`api/sims/IDVerificationPortal/GetBatchResults?batchNumber=${this.state.batchNumber}`, (response) => {
            if (response.Status == "Pass") {
                var blob = new Blob([this.base64ToArrayBuffer(response.Document.Base64)], { type: response.Document.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Document.Name;
                link.target = '_blank';
                link.click();
            }
            else {
                this.handleSnackbarOpen("Batch has not yet been finalized. We apologize for the inconvenience. Kindly wait for your confirmation email.", "error");
            }
        });
    }

    base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleFileChange = (event) => {
        this.setState({ selectedFile: event.target.files[0] });
    }

    handleImport = () => {
        if (this.state.selectedFile) {

            const reader = new FileReader();
            reader.onloadend = () => {
                let base64String = reader.result;
                base64String = base64String.split(',').pop();

                let data = {};
                data.Document = {};
                data.Document.Name = this.state.selectedFile.name;
                data.Document.size = this.state.selectedFile.size;
                data.Document.type = this.state.selectedFile.type;
                data.Document.base64 = base64String;

                utils.invokeUrl(`api/sims/IDVerificationPortal/ImportBulkVerificationTemplate`, (response) => {
                    console.log(response);
                    if (response.Status == "Pass") {
                        this.handleSnackbarOpen("Import successful. You will receive an email once your batch has been completed and ready to download. Your batch number is " + response.BatchNumber, "success");
                    }
                    else {
                        this.handleSnackbarOpen("Import failed. Kindly contact your system administrator for further support.", "error");
                    }
                }, (error) => {
                    this.handleSnackbarOpen("An error occurred while uploading. Please try again.", "error");
                },
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: JSON.stringify(data)
                    }
                );
            };
            reader.readAsDataURL(this.state.selectedFile);
        } else {
            this.handleAlertOpen();
        }
    }

    downloadUsageReport = () => {
        const { fromDate, toDate } = this.state;

        if (!fromDate || !toDate) {
            this.setState({
                fromDateError: !fromDate,
                toDateError: !toDate
            });
            return;
        }

        const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
        const formattedToDate = format(toDate, 'yyyy-MM-dd');

        const url = `api/sims/IDVerificationPortal/DownloadVerificationUsageReport?fromDate=${formattedFromDate}&toDate=${formattedToDate}`;

        utils.invokeUrl(url, (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        });
    }

    handleDateChange = (field, date) => {
        const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
        this.setState({
            [field]: date,
            [`formatted${field.charAt(0).toUpperCase() + field.slice(1)}`]: formattedDate,
            [`${field}Error`]: false
        });
    }

    render() {
        return (
            <ViewContainer title="ID Verification Portal">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {this.renderLightIndicator()}
                        <Typography style={{ marginLeft: '10px' }}>{this.renderDhaStatusMessage()}</Typography>
                    </div>
                </div>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Button variant="contained" color="primary" disabled={!this.props.menuPermissionItem.Add} onClick={this.toggleModal}>NEW SINGULAR VALIDATION</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.toggleBulkModal}
                            disabled={
                                utils.getCurrentRole() !== "Administrator" && utils.getCurrentRole() !== "ETQA Manager" && utils.getCurrentRole() !== "ETQA Manager"
                            }
                        >
                            BULK VALIDATION
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="tertiary" onClick={this.toggleBatchResultsModal}>FETCH BATCH RESULTS</Button>
                    </Grid>
                    <Grid item xs>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container spacing={1} alignItems="center" justify="flex-end">
                                <Grid item>
                                    <DatePicker
                                        label="From Date"
                                        value={this.state.fromDate}
                                        onChange={(date) => this.handleDateChange('fromDate', date)}
                                        format="yyyy/MM/dd"
                                        error={this.state.fromDateError}
                                        helperText={this.state.fromDateError ? 'Required' : ''}
                                    />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        label="To Date"
                                        value={this.state.toDate}
                                        onChange={(date) => this.handleDateChange('toDate', date)}
                                        format="yyyy/MM/dd"
                                        error={this.state.toDateError}
                                        helperText={this.state.toDateError ? 'Required' : ''}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        onClick={this.downloadUsageReport}
                                        style={{ marginLeft: '10px' }}
                                        disabled={
                                            utils.getCurrentRole() === "Administrator" || utils.getCurrentRole() === "ETQA Manager"
                                                ? false
                                                : this.props.menuPermissionItem.Add
                                                    ? true
                                                    : false
                                        }
                                    >
                                        USAGE REPORT
                                    </Button>
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>

                <Dialog open={this.state.showModal} onClose={this.toggleModal}>
                    <DialogTitle>Enter ID Number</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="ID Number"
                            type="number"
                            fullWidth
                            onChange={this.handleInputChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleModal} color="primary">Cancel</Button>
                        <Button onClick={this.verify} color="primary">VERIFY</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.showBulkModal} onClose={this.toggleBulkModal}>
                    <DialogTitle>Bulk Validation</DialogTitle>
                    <DialogContent>
                        <input
                            type="file"
                            accept=".xls, .xlsx"
                            onChange={this.handleFileChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.downloadTemplate} color="primary">DOWNLOAD TEMPLATE</Button>
                        <Button onClick={this.handleImport} color="primary">IMPORT</Button>
                        <Button onClick={this.toggleBulkModal} color="secondary">Cancel</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.showBatchResultsModal} onClose={this.toggleBatchResultsModal}>
                    <DialogTitle>Enter Batch Number</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Batch Number"
                            type="text"
                            fullWidth
                            onChange={this.handleBatchNumberChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleBatchResultsModal} color="secondary">Cancel</Button>
                        <Button onClick={this.fetchBatchResults} color="primary">FETCH BATCH RESULTS</Button>
                    </DialogActions>
                </Dialog>

                <br /><br />

                <div style={{ marginTop: '20px' }}>
                    <Typography variant="h6" gutterBottom>Verification Details</Typography>
                    <br />
                    <Grid container spacing={3}>
                        {this.renderField("DHA ID Number", this.state.idn)}
                        {this.renderField("DHA Name", this.state.dhaname)}
                        {this.renderField("DHA Surname", this.state.dhasurname)}
                        {this.renderField("SIMS Name", this.state.simsName)}
                        {this.renderField("SIMS Surname", this.state.simsSurname)}
                        {this.renderField("Death Indicator", this.state.deadIndicator)}
                        {this.renderField("Date Of Death", this.state.dateOfDeath)}
                        {this.renderField("ID Number Blocked", this.state.idnBlocked)}
                        {this.renderField("Marital Status", this.state.maritalStatus)}
                        {this.renderField("Date Of Marriage", this.state.dateOfMarriage)}
                        {this.renderField("Is Valid", this.state.isValid)}
                        {this.renderField("Birth Place Country Code", this.state.BirthPlaceCountryCode)}
                    </Grid>
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.syncData}
                        disabled={this.state.isValid !== "Valid"}
                    >
                        Sync Data
                    </Button>
                </div>

                <Snackbar
                    open={this.state.snackbarOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Alert onClose={this.handleSnackbarClose} severity={this.state.snackbarSeverity}>
                        {this.state.snackbarMessage}
                    </Alert>
                </Snackbar>
            </ViewContainer>
        );
    }

    renderField(label, value) {
        return (
            <Grid item xs={12} md={6}>
                <Paper style={{ padding: '10px' }}>
                    <Typography><strong>{label}:</strong> {value}</Typography>
                </Paper>
            </Grid>
        );
    }
}

export default withRouter(withMenuPermission(IDVerificationPortal));
