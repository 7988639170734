import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Style.css";
import ViewUtils from '../../ViewUtils';
import {ViewContainer} from "../../View";
import AlertItem from "../../widgets/AlertItem";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import withMenuPermission from "../../widgets/withMenuPermission";

const userRef = React.createRef();
const toolbarRef = React.createRef();
const utils = new ViewUtils();

const headCells = [
    { id: 'LegalName', numeric: false, disablePadding: true, label: 'Legal Name' },
    { id: 'TradeName', numeric: false, disablePadding: true, label: 'Trade Name' },
    { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
    { id: 'WindowType', numeric: false, disablePadding: true, label: 'Window Type' },
    { id: 'Intervention', numeric: false, disablePadding: true, label: 'Intervention' }
];

class DGContracts extends Component {

    constructor(props){
        super(props)
        this.state={
            OrganisationID: this.props.location.state.organisationID,
            DGApplicationID: null,
            showMessage: true,
            message: '',
            messageStyle: 'SUCCESS',
            searchParameters: [{ Name: "OrganisationID", Value: this.props.location.state.organisationID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'Close', tooltip: 'Close', icon: 'CloseIcon'},
                    ]
                },
                        {
                            type: "devider"
                        },
                {
                    type: "buttonGroup",
                    buttons: [       
                        { id: 'downloadContract', label: 'Download Contract', tooltip: 'Download Contract', icon: 'DownloadIcon', disabled: utils.isNull(this.state.DGApplicationID)},
                        { id: 'emailContract', label: 'Email Contract', tooltip: 'Email Contract', icon: 'EmailIcon', disabled: utils.isNull(this.state.DGApplicationID), visible: this.props.menuPermissionItem.Add }
                    ]
                }
            ]
        };

    }
    handleFormValueChange(values) {
    }
    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ DGApplicationID: selection[0].Id })
        } else {
            this.setState({ DGApplicationID: null })
        }
        toolbarRef.current.setButtonDisabled("downloadContract", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("emailContract", selection === null || selection.length === 0);

    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/DGOrganisation',
                state: { selection: this.props.location.state.organisationID }
            });
        } else if ("downloadContract" === buttonId) {
                this.downloadContract();  
        } else if ("emailContract" === buttonId) {
            this.emailContract();  
    }
    }

    emailContract=()=>{
        const url = `api/sims/dgcontracts/emaildgcontract`;
                utils.invokeApiPut(
                    JSON.stringify({
                        "DGApplicationID" : this.state.DGApplicationID
                    }),
                    url,
                    (response) => {
                      this.successCallback(response);
                    },
                    (response) => {
                      this.error(response);
                    }
                );
    }

    downloadContract=()=>{
        const url = `api/sims/dgcontracts/viewdgcontract`;
                utils.invokeApiPut(
                    JSON.stringify({
                        "DGApplicationID" : this.state.DGApplicationID
                    }),
                    url,
                    (response) => {
                        var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                        var url = URL.createObjectURL(blob);

                        const link = document.createElement('a');
                        link.href = url;
                        link.download = response.Name;
                        link.target = '_blank';
                        link.click();

                        this.setState({
                            message: response.Message,
                            messageStyle: response.MessageType,
                            showMessage: true
                        }, ()=>{
                            setTimeout(()=>{this.setState({showMessage: false})},2000)
                        });
                    },
                    (response) => {
                        this.setState({
                            message: "Error downloading DG Contract.",
                            messageStyle: "ERROR",
                            showMessage: true
                        }, ()=>{
                            setTimeout(()=>{this.setState({showMessage: false})},2000)
                        });
                    }
                );
    }

    successCallback(response) {
            this.setState({ message: response.Message, messageStyle: response.MessageType });
      }
      error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
      }

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    render() {
        return (
            <ViewContainer topPadding="0" title={""}>
                <p className="breadcrumbs">
                    {"DG Contracts " + (this.props.location.state.organisationName !== null ? "> " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />
                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/dgcontracts/GetSearch`}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(DGContracts));
