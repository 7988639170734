
import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
const OrganisationSDFEditForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "200px !important",
                width: "600px !important"
            }
        }
    })


    return (
        <EntityForm ref={ref}
            dataURL='api/sims/organisationsdf/getfind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="organisationsdfform">
                    <p className="legend">
                        {"Organisation SDF Update"}
                    </p>
                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>


                                <td>
                                    <CustomTextField
                                        id="FirstName"
                                        key={tabValue}
                                        label="First Name"
                                        className="w-100"
                                        form={form}
                                        disabled
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="Surname"
                                        key={tabValue}
                                        label="Surname"
                                        className="w-100"
                                        form={form}
                                        disabled
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="StartDate"
                                        key={tabValue}
                                        label="Start Date"
                                        className='{classes.textField} w-100 '
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="EndDate"
                                        key={tabValue}
                                        label="End Date"
                                        className='{classes.textField} w-100 '
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/organisationsdf/GetLookupList?listId=SDFRole"
                                        id="SDFRoleID"
                                        key={tabValue}
                                        label="SDFRole"
                                        className="w-100"
                                        required={true}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/organisationsdf/GetLookupList?listId=SDFStatus"
                                        id="SDFStatusID"
                                        key={tabValue}
                                        label="SDF Status"
                                        className="w-100"
                                        required={true}
                                        form={form} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})

export default OrganisationSDFEditForm;