import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Organisation.css";
import { OrganisationCFODetailsForm } from './OrganisationCFODetailsForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();
const formRef = React.createRef();

const organisationRef = React.createRef();

class OrganisationCFODetails extends Component {

    state = {
        organisationDetails: {
            LegalName: "",
        },
        message: "",
        messageStyle: "",
        title: "",
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
            ]
        }
    };

    handleFormValueChange(values) {
        this.setState({ organisationDetails: values });
    }

    save() {

    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else {
            this.close();
        }
    }

    save() {
        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            //if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
            //    formValue.id = this.props.location.state.selection;
            //    isAdd = false;
            //}
            if (!utils.isNull(this.state.id)) {
                formValue.id = this.state.id;
                isAdd = false;
            }
            formValue.OrganisationID = this.state.organisationID;
            //let data = JSON.stringify(formValue);

            if (isAdd) {
                const url = `api/sims/organisationcfodetail/addcfodetail`;
                utils.saveForm(this, formRef, url, (response) => {
                    this.setState({ id: response.EntityId });
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response);
                }, this.error);
            } else {
                const url = `api/sims/organisationcfodetail/updatecfodetail`;
                utils.updateForm(this, formRef, url, (response) => {
                    //this.setState({ message: "Letter updated successfully", messageStyle: "SUCCESS" });
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response);
                }, this.error);
            }
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    close() {
        this.props.history.push({
            pathname: '/skillsOrganisation',
            state: { selection: this.state.organisationID }
        });
    }

    dataArrivedHandler = (values) => {
        console.log(values);
        if (!utils.isNull(values["ID"]) && values["ID"] !== 0) {
            this.setState({ id: values["ID"] }, () => {
                this.SetToolbarConfig();
            });
        }
        else {
            this.SetToolbarConfig();
        }
    }

    SetToolbarConfig = () => {
        this.setState({
            toolbarConfig: {
                items: [
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                        ]
                    }
                    ,
                    {
                        type: "devider"
                    },
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: !utils.isNull(this.state.id) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add }
                        ]
                    }
                ]
            }
        })
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Organisation > CFO Details " + (this.props.location.state.organisationName !== null ? "> " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Organisation" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <OrganisationCFODetailsForm
                                    ref={formRef} id={typeof this.props.location.state !== 'undefined' ? this.state.organisationID : null}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)} dataArrivedHandler={(values) => this.dataArrivedHandler(values)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(OrganisationCFODetails));
