import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from "./components/layout/main";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ViewUtils from './components/ViewUtils';
import Login from './components/security/Login';
import ADLogin from './components/security/ADLogin';
import Forgot from './components/security/forgot';
import Reset from './components/security/reset';
import RoleSelect from './components/security/RoleSelect';
import ProviderSelect from './components/security/ProviderSelect';
import EmployerSelect from './components/security/EmployerSelect';
import ConfirmOTP from './components/security/confirmOTP';
import SDF from './components/skills/sdf/SDF';
import SDFOrganisation from './components/skills/sdf/SDFOrganisations';
import Stakeholder from './components/dg/stakeholder/Stakeholder';
import StakeholderOrganisation from './components/dg/stakeholder/StakeholderOrganisationDetails';
import InterfaceVersion from './InterfaceVersion';
import OnlineRegistrationTypeSelect from './components/etqa/onlineRegistration/OnlineRegistrationTypeSelect';
import OnlineRegistrationSecondryTypeSelect from './components/etqa/onlineRegistration/OnlineRegistrationSecondryTypeSelect';
import AddOnlineAssessorFlow from './components/etqa/onlineRegistration/assessor/AddOnlineAssessorFlow';
import AddOnlineModeratorFlow from './components/etqa/onlineRegistration/moderator/AddOnlineModeratorFlow';
import AddOnlineProviderFlow from './components/etqa/onlineRegistration/provider/AddOnlineProviderFlow';

const utils = new ViewUtils();

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#AD0049'
        },
        secondary: {
            main: '#009C44'
        },
        action: {
            main: '#ffffff'
        }

    }
},
)

class App extends Component {

    render() {
        let redirect = null
        if (this.props.autoLogout) {
            redirect = (
                <Redirect to={this.props.loginRedirectToLoginPath} />
            );
        }

        return (
            <Fragment>
                <MuiThemeProvider theme={theme}>
                    <Router basename={process.env.PUBLIC_URL}>
                        <Switch>
                            <Route path='/login'
                                render={(props) => (<Login />
                                )} />
                            <Route path='/ADlogin' component={ADLogin} />
                            <Route path='/role' component={RoleSelect} />
                            <Route path='/loginprovider' component={ProviderSelect} />
                            <Route path='/loginemployer' component={EmployerSelect} />
                            <Route path='/forgot' component={Forgot} />
                            <Route path='/confirmOTP' component={ConfirmOTP} />
                            <Route path='/reset' component={Reset} />
                            <Route path='/sdfRegistrationForm' render={(props) =>
                                (<SDF {...props} IsExternal={true} />)} />
                            <Route path='/sdfRegistrationOrgDetails' component={SDFOrganisation} />
                            <Route path='/stakeholderRegistrationForm' render={(props) =>
                                (<Stakeholder {...props} IsExternal={true} />)} />
                            <Route path='/stakeholderRegistrationOrgDetails' component={StakeholderOrganisation} />
                            <Route path='/onlineRegistrationTypeSelect' render={(props) =>
                                (<OnlineRegistrationTypeSelect {...props} />)} />
                            <Route path='/onlineRegistrationSecondryTypeSelect' render={(props) =>
                                (<OnlineRegistrationSecondryTypeSelect {...props} />)} />
                            <Route path='/addOnlineAssessorFlow' render={(props) =>
                                (<AddOnlineAssessorFlow {...props} IsExternal={true} />)} />
                            <Route path='/addOnlineModeratorFlow' render={(props) =>
                                (<AddOnlineModeratorFlow {...props} IsExternal={true} />)} />
                            <Route path='/addOnlineProviderFlow' render={(props) =>
                                (<AddOnlineProviderFlow {...props} IsExternal={true} />)} />
                            {this.props.roleSet && <MainLayout />}
                            <Route path='/' exact component={Login} />
                            {redirect}
                            <Redirect to="/login" />
                        </Switch>
                    </Router>
                </MuiThemeProvider>
                <InterfaceVersion />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        autoLogout: state.auth.autoLogout,
        loginRedirectToLoginPath: state.auth.loginRedirectToLoginPath,
        roleSet: !utils.isNull(state.auth.userDetails) ? !utils.isNull(state.auth.userDetails.Token) ? true : false : false
    };
}

export default connect(mapStateToProps, null)(App);