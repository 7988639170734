import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import Prompt from '../../widgets/Prompt';
import QualificationSetupSearch from '../program/QualificationSetupSearch';
import AlertItem from "../../widgets/AlertItem";
import AssessorInterventionAudit from '../assessor/AssessorInterventionAudit';
import FormDialog from "../FormDialog";
import { OutComeStatusForm } from "../common/OutcomeStatusForm";
import withMenuPermission from "../../widgets/withMenuPermission";

export const qualificationHeadCells = [
    { id: 'SAQAQualificationID', numeric: false, disablePadding: true, label: 'SAQA Qualification ID' },
    { id: "SAQAQualificationTitle", numeric: false, disablePadding: true, label: "SAQA Qualification Title" },
    { id: "Credits", numeric: false, disablePadding: true, label: "Credits" },
    { id: "QualificationRegistrationStartDate", numeric: false, disablePadding: true, label: "Qualification Registration Start Date" },
    { id: "QualificationRegistrationEndDate", numeric: false, disablePadding: true, label: "Qualification Registration End Date" },
    { id: "QualificationLastEnrolmentDate", numeric: false, disablePadding: true, label: "Qualification Last Enrolment Date" },
    { id: "QualificationLastAchievementDate", numeric: false, disablePadding: true, label: "Qualification Last Achievement Date" },
    { id: 'Outcome', numeric: false, disablePadding: true, label: 'Outcome' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' }
]

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();
class AssessorQualificationSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                SAQAQualificationID: "",
                SAQAQualificationTitle: "",
                AssessorID: this.props.location.state.AssessorID
            },
            showTable: true,
            linkDialogOpen: false,
            updateDialogOpen: false,
            formDialogOpen: false,
            auditDialogOpen: false,
            selectedQualification: null,
            propmtOpen: false,
            promptTitle: "Unlink Qualification From Assessor",
            promptContent: "Are you sure you want to unlink this qualification from the assessor?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "AssessorID", Value: this.props.location.state.AssessorID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon : 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link Qualification/s to the assessor', icon: 'LinkIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'update', label: '', tooltip: 'Update the status and outcome of the selected qualification', disabled: utils.isNull(this.state.selectedQualification), icon: 'UpdateIcon', visible: this.props.menuPermissionItem.Edit },
                        { id: 'audit', label: '', tooltip: 'View Record Audit Trail', disabled: utils.isNull(this.state.selectedQualification) , icon: 'AuditIcon' },
                        { id: 'unlink', label: '', tooltip: 'Un-link selected qualification from the assessor', disabled: utils.isNull(this.state.selectedQualification), icon: 'LinkOffIcon', visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/assessor',
                state: { selection: this.props.location.state.AssessorID }
            });
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else if ("update" === buttonId) {
            this.setState({ updateDialogOpen: true, message: "" })
        }
        else if ("audit" === buttonId) {
            this.setState({ auditDialogOpen: true, message: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedQualification: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    unlinkQualification(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedQualification}&assessorId=${this.props.location.state.AssessorID}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                linkDialogOpen: false, selectedQualification: null,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters))
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedQualification: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        } else {
            this.setState({
                selectedQualification: null,
                message: "",
                messageStyle: ""
            });
        }
        toolbarRef.current.setButtonDisabled("audit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("update", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateDialogOpen: false, selectedSiteVisit: null, auditDialogOpen: false, linkDialogOpen: false, selection: this.props.location.state.selection, selectedQualification: null
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Assessor > Qualifications" + (this.props.location.state !== null ? " > " + this.props.location.state.AssessorName + " - " + this.props.location.state.AssessorIDNo : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={qualificationHeadCells}
                            dataUrl={`api/sims/assessorqualification/GetAssessorQualifications`}
                            height="50vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <FormDialog
                    open={this.state.updateDialogOpen}
                    dialogTitle={"Update Outcome and Status"}
                    viewRef={this}
                    entityId={this.state.selectedUnitStandard}
                    formRef={addFormRef}
                    usePut={true}
                    saveUrl={"api/sims/assessorqualification/UpdateQualificationOutcomeStatus"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <OutComeStatusForm ref={addFormRef} id={this.state.selectedQualification} dataURL='api/sims/assessorqualification/GetFindQualificationOutcomeStatus' controllerName='assessorqualification' />
                </FormDialog>

                <LinkDialog open={this.state.auditDialogOpen}
                    title={"Audit Trail"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={"Audit Trail"}
                    selectionId={this.props.location.state.selectedQualification}
                >

                    <AssessorInterventionAudit dataUrl={"api/sims/assessorqualification/GetAssessorQualificationAudit"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedQualification}
                    />
                </LinkDialog>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.qualificationTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Qualification to Assessor: ${this.props.location.state.AssessorName}`}
                    linkName={"Qualifications"} postUrl={"api/sims/assessorqualification/LinkAssessorQualification"} usePost={true}
                    selectionId={this.props.location.state.AssessorID}
                >
                    <QualificationSetupSearch 
                        dataUrl={"api/sims/assessorqualification/GetSearchLinkableQualifications"}
                        multiSelect={true} showTitle={false} customMessage={"No Qualifications with this search criteria have been found."}
                        programmeId={this.props.location.state.AssessorID} programmeType="Q" allowClear={true}
                        mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkQualification(`api/sims/assessorqualification/RemoveAssessorQualification`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(AssessorQualificationSearch));
