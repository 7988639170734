import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import ProviderSearch from '../../provider/ProviderSearch';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../../widgets/Forms';
import LearnerQualificationSearch from '../../program/LearnerQualificationSearch';
import InstitutionSearch from '../../program/InstitutionSearch';
import OFOSearch from '../../program/OFOSearch';
import ViewUtils from "../../../ViewUtils";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const LearnerFETForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function GetProgrammeStatusID(value) {
        console.clear();
        console.log(value);
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }


    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    function SponsorCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "BANKSETA Funded") {
                return true
            }
            else if (value.Description !== "Industry Funded") {
                return false
            }
        }
    }

    function SocioCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Employed") {
                return true
            }
            else if (value.Description !== "Unemployed") {
                return false
            }
        }
    }

    function trackExtensionDate(value) {
        console.clear()
        console.log(value)
        if (value !== undefined && value !== null) {
                return true
        }
        else {
                return false
        }
        
    }


    return (
        <EntityForm ref={ref} values={props.data} viewId="FETSetup" dataURL="api/sims/learnerfet/GetLearnerLinkedFETDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerFETForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="TVET Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="EmployerID"
                                                label="Lead Employer"
                                                className="w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required={SocioCheck(form.values["SocioEconomicStatusID"])}
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl='api/sims/learnerfet/EmployerSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("EmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="OFOOccupationID"
                                                label="OFO Occupation"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form} 
                                            >
                                                <OFOSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerfet/getofooccupationsearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerfet/GetLookupList?listId=Sponsorship"
                                                id="SponsorshipID"
                                                parentId={0}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                                key={tabValue}
                                                label="Sponsorship"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerfet/GetLookupList?listId=Project"
                                                id="ProjectID"
                                                parentId={0}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required={SponsorCheck(form.values["SponsorshipID"])}
                                                key={tabValue}
                                                label="Project"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                allowEditEmptyValue={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ContractNumber"
                                                key={tabValue}
                                                label="Contract Number"
                                                className="w-100 "
                                                disabled
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerfet/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerfet/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerfet/GetLookupList?listId=YearOfStudy"
                                                id="YearOfStudyID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Year Of Study"
                                                className="{classes.select} w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td xs lg="6">
                                            <CustomCheckbox
                                                id="IsIndustrySpecific"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Industry Specific?"
                                            />
                                        </td>
                                        <td>
                                            <CustomCheckbox
                                                id="IndustryNonSpecific"
                                                key={tabValue}
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Industry Non Specific?"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerfet/GetLookupListNQFLevel?id=1"
                                                id="NQFLevelID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="NQF Level"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="AmountSpent"
                                                key={tabValue}
                                                label="Amount Spent"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="InstitutionID"
                                                label="TVET"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form} 
                                            >
                                                <InstitutionSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerinternship/GetInstitutionSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("InstitutionID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerfet/GetLookupListTVETTypeFiltered?id=1"
                                                id="FETTypeID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="TVET Type"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="MostRecentRegistrationDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Most Recent Registration Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerfet/GetLookupListHELFiltered?id=1"
                                                id="HighestEducationLevelID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Highest Education Level"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="FETStartDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Work Place Start Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="FETEndDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Work Place End Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="CompletionDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Estimate Date of Completion"
                                                className='{classes.textField} w-100 '
                                                required={!TerminationCheck(form.values["FETPlacementStatusID"])}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="FETQualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <LearnerQualificationSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerfet/QualificationSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("FETQualificationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ContactPerson"
                                                key={tabValue}
                                                label="Contact Person"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ContactPersonEmailAddress"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Contact Person Email Address"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="TerminationDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Termination Date"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["FETPlacementStatusID"])}
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerfet/GetLookupList?listId=TerminationReason"
                                                id="TerminationReasonID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Termination Reason"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                required={TerminationCheck(form.values["FETPlacementStatusID"])}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["FETPlacementStatusID"])}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="OtherTerminationReason"
                                                key={tabValue}
                                                label="Other Termination Reason"
                                                className="w-100 "
                                                required={terminationReasonCheck(form.values["TerminationReasonID"])}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !terminationReasonCheck(form.values["TerminationReasonID"])}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="ExtensionDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Extension Date"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="ExtensionCapturedDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Extension Captured Date"
                                                disabled
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ReasonForExtension"
                                                key={tabValue}
                                                label="Reason For Extension"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100 "
                                                required={trackExtensionDate(form.values["ExtensionDate"])}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerfet/GetLookupList?listId=LearnerTracking"
                                                id="LearnerTrackingID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Learner Tracking"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        {
                                            (!props.hideFields) ?
                                        <td >
                                            <SelectItem
                                                dataUrl={'api/sims/learnerfet/GetProgrammeStatusByRole?parameter=[{"Name":"FETPlacementStatusID","Value":' + GetProgrammeStatusID(form.values["FETPlacementStatusID"]) + '}]'}
                                                id="FETPlacementStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                label="TVET Placement Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                                </td>
                                                :
                                                ""
                                        }
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerfet/GetLookupList?listId=EnrolmentStatusReason"
                                                        id="EnrolmentStatusReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Enrolment Status Reason"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>

                                                <td>
                                                    <CustomTextField
                                                        id="CaptureByUser"
                                                        key={tabValue}
                                                        label="Capture By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="RegistrationDate"
                                                        key={tabValue}
                                                        label="Registration Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="RegisteredBy"
                                                        key={tabValue}
                                                        label="Registered By"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CompletionProcessedDate"
                                                        key={tabValue}
                                                        label="Completion Processed Date"
                                                        disabled
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ActualTerminatedDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Actual Termination Date"
                                                        required={TerminationCheck(form.values["FETPlacementStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["FETPlacementStatusID"])}
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
