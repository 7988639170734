import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Learner.css";
import { LearnerForm } from './LearnerForm';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();


const learnerRef = React.createRef();
class Learner extends Component {

    state = {
        LearnerDetails: {
            FirstName: "",
            Surname: ""
        },
        title: "",
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },

                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit },
                        {
                            contextMenu: [
                                { id: "registrationUnitStandards", description: "Unit Standards" },
                                { id: "registrationQualifications", description: "SAQA Qualifications" },
                                { id: "registrationSkillsProgramme", description: "Skills Programme" },
                                { id: "registrationLearnerships", description: "Learnerships" },
                                { id: "registrationInternships", description: "Internships" },
                                { id: "registrationBursaries", description: "Bursaries" },
                                { id: "registrationAET", description: "AET" },
                                { id: "registrationCandidacyProgramme", description: "Candidacy Programme" },
                                { id: "registrationEntrepreneurship", description: "Entrepreneurship Programme" },
                                { id: "registrationRPL", description: "RPL" },
                                { id: "registrationShortCourse", description: "Short Course" },
                                { id: "registrationWIL", description: "WIL" },
                                { id: "registrationCapacityBuilding", description: "Capacity Building Support" },
                                { id: "registrationCertificateAssistance", description: "Assist Grade 12" },
                                { id: "registrationDigitalLiteracy", description: "Digital Literacy Programme" },
                                { id: "registrationMathScienceSupport", description: "Math and Science Support" },
                                { id: "registrationWorkReadinessProgramme", description: "Work Readiness Programme" },
                                { id: "registrationQCTOQualifications", description: "QCTO Qualifications" },
                            ],
                            id: 'registrations', label: 'REGISTRATIONS', tooltip: 'Registered Programmes'
                        },
                        {
                            contextMenu: [
                                { id: "assessmentsUnitStandard", description: "Unit Standards" },
                                { id: "assessmentsQualification", description: "SAQA Qualifications" },
                                { id: "assessmentsSkillsProgramme", description: "Skills Programme" },
                                { id: "assessmentsLearnerships", description: "Learnerships" },
                                { id: "assessmentsModuleQualification", description: "QCTO Qualifications" }
                            ],
                            id: 'assessments', label: 'ASSESSMENTS', tooltip: 'Assessed Programmes'
                        },
                        {
                            contextMenu: [
                                { id: "achievementsUnitStandards", description: "Unit Standards" },
                                { id: "achievementsQualifications", description: "Qualifications" },
                                { id: "achievementsSkillsProgramme", description: "Skills Programme" },
                                { id: "achievementsLearnerships", description: "Learnerships" }
                            ],
                            id: 'achievements', label: 'ACHIEVEMENTS', tooltip: 'Achieved Programmes'
                        },
                        { id: 'crm', label: 'CRM', tooltip: 'Client Relations Management' },
                        { id: 'programmeGrants', label: 'PROGRAMME GRANTS', tooltip: 'Learner Programme Grants' },
                    ]
                }
            ]
        }
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ LearnerDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("crm" === buttonId) {
            this.props.history.push({
                pathname: '/learnerCRM',
                state: {
                    LearnerID: this.state.LearnerDetails.ID,
                    LearnerName: `${this.state.LearnerDetails.FirstName} ${this.state.LearnerDetails.Surname}`
                }
            })
        }
        else if ("programmeGrants" === buttonId) {
            this.props.history.push({
                pathname: '/learnergrants',
                state: {
                    LearnerID: this.state.LearnerDetails.ID,
                    LearnerName: `${this.state.LearnerDetails.FirstName} ${this.state.LearnerDetails.Surname}`
                }
            })
        }
    }

    handleToolbarButtonMenuClick(menu) {
        if ("registrationSkillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerSkillsProgrammeSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationEntrepreneurship" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerEntrepreneurshipSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationCapacityBuilding" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerCapacityBuildingSupportSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationCertificateAssistance" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerSeniorCertificateAssistanceSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationDigitalLiteracy" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerDigitalLiteracySearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationCareerGuidanceWorkshop" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerCareerGuidanceWorkshopSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationRPL" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerRPLSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        } else if ("registrationShortCourse" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerShortCourseSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationWIL" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerWILSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationMathScienceSupport" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerMathScienceSupportSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationWorkReadinessProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerWorkReadinessProgrammeSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationBursaries" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerBursarySearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationUnitStandards" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerUnitStandardSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationQualifications" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQualificationSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationLearnerships" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerLearnershipSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationInternships" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerInternshipsSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationAET" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerAETSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("registrationCandidacyProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerCandidacySearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsLearnerships" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerLearnershipAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsSkillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerSkillsProgrammeAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsQualifications" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQualificationAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("achievementsUnitStandards" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerUnitStandardAchievements',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsLearnerships" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerLearnershipAssessments',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsSkillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerSkillsProgrammeAssessment',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsQualification" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerQualificationAssessment',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
        else if ("assessmentsUnitStandard" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerUnitStandardAssessment',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        } else if ("registrationQCTOQualifications" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerModuleQualificationSearch',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        } else if ("assessmentsModuleQualification" === menu.id) {
            this.props.history.push({
                pathname: '/LearnerModuleQualificationAssessment',
                state: {
                    learnerID: this.state.LearnerDetails.ID,
                    learnerNames: this.state.LearnerDetails.FirstName + " " + this.state.LearnerDetails.Surname
                }
            })
        }
    }

    successCallback = () => {

        this.setState({ message: "Learner updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = learnerRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnerdetails/UpdateLearner", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    closeView() {
        this.props.history.push('/learners');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">

                <p className="breadcrumbs">
                    {"Learner " + (this.state.LearnerDetails.FirstName !== null ? "> " + this.state.LearnerDetails.FirstName + (this.state.LearnerDetails.Surname !== null ? "  " + this.state.LearnerDetails.Surname : "") : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title="Learner"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={false}
                                    config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "80vh", width: "95%" }}>
                                    <LearnerForm ref={learnerRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(Learner));

