import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import { LearnerModuleQualificationForm } from '../../etqa/program/registration/LearnerModuleQualificationForm';



const ModuleQualificationRef = React.createRef();
const utils = new ViewUtils();

class AddLearnerModuleQualification extends Component {

    state = {
        ModuleQualificationDetails: {
            QCTOModuleQualificationTitle: "",
            QCTOModuleQualificationCode: ""
        },
        messageStyle: "message",
        id: null,
        Learner: this.props.location.state.LearnerID
    };


    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {

            this.setState({
                ModuleQualificationSetupDetails: {
                    QCTOModuleQualificationCode: values.QCTOModuleQualificationID,
                    QCTOModuleQualificationTitle: values.QCTOModuleQualificationTitle
                },
                Learner: this.props.location.state.LearnerID
            });
        }
    }

    toolbarConfig = {
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
                ]
            }
            ,
            {
                type: "devider"
            },
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon' },
                    { id: 'Module', label: 'ModuleS', tooltip: 'View QCTO Qualfication Modules', visible: this.props.location.state.hideFields === false },
                ]
            }
        ]
    };


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("Module" === buttonId) {
            this.props.history.push({
                pathname: '/LearnerModuleQualificationUSSearch',
                state: {
                    LearnerID: this.props.location.state.LearnerID,
                    ModuleQualificationID: this.props.location.state.ModuleQualificationID,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    viewOnly: this.props.location.state.viewOnly,
                    permissions: this.props.location.state.permissions
                }
            })
        }
    }

    successCallback = () => {

        this.setState({ message: "Learner ModuleQualification updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = ModuleQualificationRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.LearnerID;
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnerModuleQualification/LinkLearnerModuleQualification", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    closeView() {

        this.props.history.push({
            pathname: '/LearnerModuleQualificationSearch',
            state: {
                learnerID: this.props.location.state.LearnerID,
                learnerNames: this.props.location.state.learnerNames
            }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {
                        (this.props.location.state.hideFields) ?
                            `Learner > Link New QCTO Qualification > ${this.props.location.state.learnerNames}`
                            :
                            `Learner > Update QCTO Qualification > ${this.props.location.state.learnerNames}`
                    }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <LearnerModuleQualificationForm ref={ModuleQualificationRef}
                                        viewRef={this}
                                        id={this.props.location.state.ModuleQualificationID}
                                        hideFields={this.props.location.state.hideFields}
                                        editPermission={this.props.location.state.permissions}
                                        viewOnly={this.props.location.state.viewOnly}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AddLearnerModuleQualification);
