import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const SkillsLetterCreationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "200px !important",
                width: "600px !important"
            }
        }
    })

    const onTextChange = (form, id, value) => {
        //if (id === "BodyA") {
        //    form.updateValue('BodyACharactersLeft', 2500 - value.length);
        //} else if (id === "BodyB") {
        //    form.updateValue('BodyBCharactersLeft', 2500 - value.length);
        //}
    }

    return (
        <EntityForm ref={ref}
            viewId="queryreason"
            dataURL='api/sims/skillslettercreationdetails/getfind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            valueChangeHandler={props.valueChangeHandler}
            data={props.data}
        >
            {form =>
                <form className={form.classes.container} id="SkillsLetterCreationForm">
                    <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                            <Tab label="Letter Creation" {...a11yProps(0)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tabValue} index={0}>
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/skillslettercreationdetails/GetLookupList?listId=FinancialYearID"
                                            id="FinancialYearID"
                                            key={tabValue}
                                            label="Financial Year"
                                            className="w-100"
                                            required={true}
                                            form={form} 
                                            InputProps={{
                                                readOnly: false,
                                                disabled: !utils.isNull(props.id)
                                            }}/>
                                    </td>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/skillslettercreationdetails/GetLookupList?listId=CRMLetterTypeID"
                                            id="CRMLetterTypeID"
                                            key={tabValue}
                                            label="Letter Type"
                                            required={true}
                                            className="w-100"
                                            form={form} 
                                            InputProps={{
                                                readOnly: false,
                                                disabled: !utils.isNull(props.id)
                                            }}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/skillslettercreationdetails/GetLookupList?listId=CRMLetterGrantTypeID"
                                            id="CRMLetterGrantTypeID"
                                            key={tabValue}
                                            label="Letter Grant Type"
                                            className="w-100"
                                            required={true}
                                            form={form} 
                                            InputProps={{
                                                readOnly: false,
                                                disabled: !utils.isNull(props.id)
                                            }}/>
                                    </td>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/skillslettercreationdetails/GetLookupList?listId=CRMLetterSalutationID"
                                            id="CRMLetterSalutationID"
                                            key={tabValue}
                                            required={true}
                                            label="Letter Salutation"
                                            className="w-100"
                                            form={form} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="HeaderA"
                                            key={tabValue}
                                            label="Header A"
                                            className="w-100"
                                            required={true}
                                            form={form}
                                        />

                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="HeaderB"
                                            key={tabValue}
                                            label="Header B"
                                            className="w-100"
                                            required={true}
                                            form={form}
                                        />
                                    </td>


                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="BodyA"
                                            key={tabValue}
                                            label="Body A"
                                            className="w-100"
                                            required={true}
                                            inputProps={{
                                                maxLength: 2500
                                            }}
                                            multiline
                                            styles={styles()}
                                            form={form}
                                            onChange={onTextChange}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="BodyB"
                                            key={tabValue}
                                            label="BodyB"
                                            className="w-100"
                                            inputProps={{
                                                maxLength: 2500
                                            }}
                                            multiline
                                            styles={styles()}
                                            form={form}
                                            onChange={onTextChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="BodyACharactersLeft"
                                            label="Body A Characters Left"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            form={form}
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="BodyBCharactersLeft"
                                            label="Body B Characters Left"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            form={form}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/skillslettercreationdetails/GetLookupList?listId=CRMLetterGreetingID"
                                            id="CRMLetterGreetingID"
                                            key={tabValue}
                                            label="Letter Greeting"
                                            className="w-100"
                                            required={true}
                                            form={form} />
                                    </td>

                                    <td>
                                        <CustomTextField
                                            id="SignOffName"
                                            key={tabValue}
                                            label="Sign Off Name"
                                            className="w-100"
                                            required={true}
                                            form={form}
                                        />
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="JobTitle"
                                            key={tabValue}
                                            label="Job Title"
                                            className="w-100"
                                            required={true}
                                            form={form}
                                        />
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </TabPanel>

                </form>
            }
        </EntityForm>
    );
})

