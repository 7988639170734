import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import WILSearchForm from '../program/WILSetupSearchForm';
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
    { id: 'WILProgramme', numeric: false, disablePadding: true, label: 'WIL Programme' },
    { id: 'ProgrammeStatus', numeric: false, disablePadding: true, label: 'Programme Status' },
    { id: 'StartDate', numeric: false, disablePadding: true, label: 'Start Date' },
    { id: 'EndDate', numeric: false, disablePadding: true, label: 'End Date' },
    { id: 'Project', numeric: false, disablePadding: true, label: 'Project' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();

class LearnerWIL extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = !utils.isNull(this.props.location.state.learnerID) ? [
            { Name: "WILProgramme", Value: "" },
            { Name: "ProgrammeID", Value: this.props.programmeId },
            { Name: "LearnerID", Value: this.props.location.state.learnerID }
        ]
            :
            [
                { Name: "WILProgramme", Value: "" },
                { Name: "LearnerID", Value: this.props.location.state.learnerID }
            ];

        let model = !utils.isNull(this.props.location.state.learnerID) ?
            {
                Qualification: "",
                ProgrammeID: this.props.programmeId,
                LearnerID: this.props.location.state.learnerID

            }
            :
            {
                Qualification: "",
                LearnerID: this.props.location.state.learnerID
            }


        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }


        this.state = !utils.isNull(this.props.location.state.learnerID) ?
            {
                model: model,
                keyUpSwitch: false,
                toolbarConfig: {
                    items: [
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                            ]
                        }
                        ,
                        {
                            type: "devider"
                        },
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'view', label: '', tooltip: 'View', icon: 'ViewIcon', visible: this.props.menuPermissionItem.View },
                                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit, disabled: true },
                                { id: "delete", label: "", tooltip: "Remove short course", icon: "DeleteIcon", visible: this.props.menuPermissionItem.Delete }
                            ]
                        }
                    ]
                }
            }
            :
            {
                model: model,
                keyUpSwitch: false
            };

        //TODO: Add url for unit standard removal
        this.deleteUrl = ""
    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "add") {
            this.props.history.push({
                pathname: '/AddLearnerWIL',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    WILID: null,
                    hideFields: true,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem
                }
            })
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/AddLearnerWIL',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    WILID: this.state.selectedLearnerWIL[0].Id,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem
                }
            })
        }
        else if (buttonId === "view") {
            this.props.history.push({
                pathname: '/AddLearnerWIL',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    WILID: this.state.selectedLearnerWIL[0].Id,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem,
                    viewOnly: true
                }
            })
        }


    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnerWIL: selection })

            let url = "api/sims/LearnerWIL/CheckCanEditProgramme?Id=" + selection[0].Id;
            utils.invokeUrl(url, (response) => {
                toolbarRef.current.setButtonDisabled("edit", response.Result === "0" ? true : false)
            },
                (e) => {
                    toolbarRef.current.setButtonDisabled("edit", true)
                },
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        } else {
            this.setState({ selectedLearnerWIL: null })
            toolbarRef.current.setButtonDisabled("edit", true)
        }
    }


    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > WIL > ${this.props.location.state.learnerNames}`}
                </p>
                <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/sims/learnerwil/GetLearnerWILSearch" : this.props.dataUrl}
                    deleteUrl={"api/sims/learnerwil/unlinkLearnerWIL"} useDeletePost={true}
                    entityViewPath='/AddLearnerWIL'
                    model={this.state.model} toolbarConfig={this.state.toolbarConfig}
                    toolbarRef={toolbarRef}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="Qualification" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Learner Short Course"}
                    promptContent={"Are you sure you want to remove the learner Short Course record?"}
                >
                    <WILSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(LearnerWIL));

