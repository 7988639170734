import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import Button from '@material-ui/core/Button';
import withMenuPermission from "../../widgets/withMenuPermission";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import Item from "antd/lib/list/Item";
import AlertItem from "../../widgets/AlertItem";
import './Style.css'
import { SelectItem } from '../../widgets/SelectItem';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import WebIcon from '@material-ui/icons/Web';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from "antd";
import EnhancedTable from '../../widgets/ResponsiveTable';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import LinkDialog from "../../etqa/LinkDialog";
import DGLetterReasonDialog from "../application/Controls/DGLetterReasonDialog";

const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'Back to DG Verification Dashboard', tooltip: '' }
            ]
        }
    ]
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#01579B',
        color: theme.palette.common.white,
        border: '1px solid white',
    },
    body: {
        fontSize: 11,
    },
}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    body: {
        fontSize: 14,
        fontWeight: 500
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);

const headCells = [
    { id: 'Occupation', numeric: false, disablePadding: true, label: 'Occupation', widthRatio: .154 },
    { id: 'AfricanMale', numeric: false, disablePadding: true, label: 'African Male', widthRatio: .047 },
    { id: 'AfricanFemale', numeric: false, disablePadding: true, label: 'African Female', widthRatio: .047 },
    { id: 'AfricanDisabled', numeric: false, disablePadding: true, label: 'African Disabled', widthRatio: .047 },
    { id: 'ColouredMale', numeric: false, disablePadding: true, label: 'Coloured Male', widthRatio: .047 },
    { id: 'ColouredFemale', numeric: false, disablePadding: true, label: 'Coloured Female', widthRatio: .047 },
    { id: 'ColouredDisabled', numeric: false, disablePadding: true, label: 'Coloured Disabled', widthRatio: .047 },
    { id: 'IndianMale', numeric: false, disablePadding: true, label: 'Indian Male', widthRatio: .047 },
    { id: 'IndianFemale', numeric: false, disablePadding: true, label: 'Indian Female', widthRatio: .047 },
    { id: 'IndianDisabled', numeric: false, disablePadding: true, label: 'Indian Disabled', widthRatio: .047 },
    { id: 'WhiteMale', numeric: false, disablePadding: true, label: 'White Male', widthRatio: .047 },
    { id: 'WhiteFemale', numeric: false, disablePadding: true, label: 'White Female', widthRatio: .047 },
    { id: 'WhiteDisabled', numeric: false, disablePadding: true, label: 'White Disabled', widthRatio: .047 },
    { id: 'Age1', numeric: false, disablePadding: true, label: 'Age < 35', widthRatio: .047 },
    { id: 'Age2', numeric: false, disablePadding: true, label: 'Age 35 - 55', widthRatio: .047 },
    { id: 'Age3', numeric: false, disablePadding: true, label: 'Age > 55', widthRatio: .047 },
    { id: 'TotalMale', numeric: false, disablePadding: true, label: 'Total Male', widthRatio: .047 },
    { id: 'TotalFemale', numeric: false, disablePadding: true, label: 'Total Female', widthRatio: .047 },
    { id: 'TotalDisabled', numeric: false, disablePadding: true, label: 'Total Disabled', widthRatio: .047 }
];

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

class DGVerificationMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            objectProps: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.object)) ? this.props.location.state.object : null,
            formObject: null,
            lookupArray: [],
            lookupVArray: [],
            lookupQVArray: [],
            FinancialYear: '',
            OrganisationName: '',
            Status: '',
            model: {
                Name: "",
            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.DGApplicationID)) ? this.props.location.state.DGApplicationID : null,
            OrganisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.OrganisationID)) ? this.props.location.state.OrganisationID : null,
            FinancialYearID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FinancialYearID)) ? this.props.location.state.FinancialYearID : null,
            FundingWindowID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FundingWindowID)) ? this.props.location.state.FundingWindowID : null,
            VerificationPermission: "",
            searchParameters: [{ Name: "DGApplicationID", Value: this.props.location.state.DGApplicationID }, { Name: "OrganisationID", Value: this.props.location.state.OrganisationID }, { Name: "FinancialYearID", Value: this.props.location.state.FinancialYearID }],
            OpenDialog: false,
            formViewURL: "",
            formName: "",
            linkDialogOpen: false,
        };
    }

    componentDidMount() {
        this.getHeaders(this.state.id)
        this.getVerification(this.state.id)
        this.getVerificationStatusList(this.state.id)
        this.getlookup(this.state.id)
        this.CheckVerificationPermissions(this.state.id);
    }

    getHeaders = (id) => {
        let url = "api/sims/dgverification/getfindheader?DGApplicationID=" + id;

        utils.invokeUrl(
            url,
            (response) => {
                this.setState({
                    FinancialYear: response.FinancialYear,
                    OrganisationName: response.OrganisationName,
                    SDLNumber: response.SDLNumber
                })
                console.log(response);
            },
            null,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
    };

    getVerification(id) {
        let url = `api/sims/dgverification/GetDGVerification?request={"searchParameters":[{"Name":"DGApplicationID","Value":${this.state.id}},{"Name":"OrganisationID","Value":${this.state.OrganisationID}},{"Name":"FinancialYearID","Value":${this.state.FinancialYearID.Id}}]}`;
        utils.invokeUrl(url, (response) => {
            this.setState({
                formObject: response
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    getVerificationStatusList(id) {
        let url = `api/sims/dgverification/GetDGVerificationStatusList?request={"searchParameters":[{"Name":"DGApplicationID","Value":${this.state.id}},{"Name":"OrganisationID","Value":${this.state.OrganisationID}},{"Name":"FinancialYearID","Value":${this.state.FinancialYearID.Id}}]}`;
        utils.invokeUrl(url, (response) => {
            this.setState({
                VerificationStatusListObject: response
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }
    getlookup(id) {
        let url = "api/sims/dgverification/GetLookupListDGEvidenceVerified";
        utils.invokeUrl(url, (response) => {
            this.setState({ lookupArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )

        let urlV = "api/sims/dgverification/GetLookupListDGVerificationStatus";
        utils.invokeUrl(urlV, (response) => {
            this.setState({ lookupVArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )

        let urlQV = "api/sims/dgverification/GetLookupListDGQualifictionVerificationStatus";
        utils.invokeUrl(urlQV, (response) => {
            this.setState({ lookupQVArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    CheckVerificationPermissions(id) {
        let url = "api/sims/DGVerification/GetDGVerificationPermissionCheck?DGApplicationID=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.setState({
                VerificationPermission: response.Result
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }




    handleChecklistArrayEV = (event, index) => {
        const values = [...this.state.formObject.DGVerificationDocumentsDto];
        const indexObject = { ...values[index] };
        indexObject.DGEvidenceVerifiedID = { Id: event.target.value };
        values[index] = indexObject;
        const finalObject = { ...this.state.formObject, DGVerificationDocumentsDto: values }
        this.setState({ formObject: finalObject });
    }

    handleChecklistArrayC = (event, index) => {
        const values = [...this.state.formObject.DGVerificationDocumentsDto];
        const indexObject = { ...values[index] };
        indexObject.Comment = event.target.value;
        values[index] = indexObject;
        const finalObject = { ...this.state.formObject, DGVerificationDocumentsDto: values }
        this.setState({ formObject: finalObject });
    }

    handleChecklistArrayQV = (event, index) => {
        const values = [...this.state.formObject.DGVerificationInterventionDto.DGVerificationInterventionData];
        const indexObject = { ...values[index] };
        indexObject.QualificationVerificationStatusID = event.target.value;
        values[index] = indexObject;
        const newDGVerificationInterventionDto = { ...this.state.formObject.DGVerificationInterventionDto };
        newDGVerificationInterventionDto.DGVerificationInterventionData = values;
        const finalObject = { ...this.state.formObject, DGVerificationInterventionDto: newDGVerificationInterventionDto }
        this.setState({ formObject: finalObject });
    }

    handleChecklistArrayQC = (event, index) => {
        const values = [...this.state.formObject.DGVerificationInterventionDto.DGVerificationInterventionData];
        const indexObject = { ...values[index] };
        indexObject.QualificationVerificationComments = event.target.value;
        values[index] = indexObject;
        const newDGVerificationInterventionDto = { ...this.state.formObject.DGVerificationInterventionDto };
        newDGVerificationInterventionDto.DGVerificationInterventionData = values;
        const finalObject = { ...this.state.formObject, DGVerificationInterventionDto: newDGVerificationInterventionDto }
        this.setState({ formObject: finalObject });
    }

    handleStatus = (event) => {
        const finalObject = { ...this.state.formObject, DGStatusID: { Id: event.target.value } }
        this.setState({ formObject: finalObject });
    }

    handleComment = (event) => {
        const finalObject = { ...this.state.formObject, Comments: event.target.value }
        this.setState({ formObject: finalObject });
    }

    CheckStatus = () => {
        if (this.state.formObject.DGStatusID.Id === "4" || this.state.formObject.DGStatusID.Id === 4) {
            this.setState({
                linkDialogOpen: true,
                linkDialogTitle: 'Query Reasons',
                LinkDialogName: 'QueryReasons',
                NewDGStatusID: 4,
                message: ""
            })
        }
        else if (this.state.formObject.DGStatusID.Id === "5" || this.state.formObject.DGStatusID.Id === 5) {
            this.setState({
                linkDialogOpen: true,
                linkDialogTitle: 'Rejection Reasons',
                LinkDialogName: 'RejectionReasons',
                NewDGStatusID: 5,
                message: ""
            })
        }
        else { this.SaveVerification(null) }

    }

    SaveVerification = (selection) => {

        let ReasonsList = "";
        if (selection !== null) {
            for (let i = 0; i < selection.length; i++) {
                if (i === 0) { ReasonsList += selection[i].Id; }
                else { ReasonsList += "," + selection[i].Id; }

            }
        }

        let SaveObject = { ...this.state.formObject, Reasons: ReasonsList };
        this.setState({ formObject: SaveObject });

        let url = "api/sims/dgverification/UpdateDGVerification";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(SaveObject)
            }
        )
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {

            this.setState({
                linkDialogOpen: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters))
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    successCallback(response) {

        if (response.MessageType === "WARNING") {
            let errResult = JSON.parse(response.Message);
            let message = "";
            errResult.map((item, index) => (
                message += item.Item1 + ' : ' + item.Item2 + '\n'
            ))
            this.setState({ message: message, messageStyle: response.MessageType });
        }
        else {
            this.setState({ message: response.Message, messageStyle: response.MessageType });
            this.getVerification(this.state.id);
            this.getVerificationStatusList(this.state.id)
        }
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    close() {
        this.props.history.push({
            pathname: '/DGVerificationSearch',
            state: {
                organisationID: this.state.OrganisationID,
                DGApplicationID: this.state.DGApplicationID,
                FinancialYearID: this.state.FinancialYearID,
                FundingWindowID: this.state.FundingWindowID,
                organisationName: this.state.OrganisationName,
                sdlnumber: this.state.SDLNumber
            },
        });
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.close();
        }
    }

    buttonSubmitClickHandler() {
        this.submit();
    }

    FileDownload(id, DocumentType) {
        console.log(id);
        utils.invokeUrl("api/sims/dgverification/DownloadDGDocument?DocumentID=" + id, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    OpenForm(id, formUrl, fName) {
        console.log(formUrl);
        let newsearchParameters = [...this.state.searchParameters];
        let temp_element = { ...newsearchParameters[1] };
        temp_element.Value = id
        newsearchParameters[1] = temp_element;

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            OpenDialog: true,
            formViewURL: formUrl,
            formName: fName
        });
    }

    CloseForm = () => {
        this.setState({
            OpenDialog: false,
            formViewURL: "",
            formName: ""
        });
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    render() {

        const { classes } = this.props;
        const doctablerows = !utils.isNull(this.state.formObject) ? this.state.formObject.DGVerificationDocumentsDto.map((item, index) => (

            <StyledTableRow key={item.ID}>
                <StyledTableCell component="th" scope="row">
                    {index + 1}
                </StyledTableCell>
                <StyledTableCell align="left">{item.DocumentDescription}</StyledTableCell>
                <StyledTableCell align="left">{item.EvidenceAvaialble}</StyledTableCell>
                <StyledTableCell align="left" component="th" scope="row" >
                    <TextField className="w-30" id={"DGEvidenceVerifiedID" + item.ID} value={item.DGEvidenceVerifiedID !== null ? item.DGEvidenceVerifiedID.Id : 0} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleChecklistArrayEV(values, index)} >
                        <MenuItem value={0} key={0} >  </MenuItem>
                        {this.state.lookupArray.map((lookupitem, index) => (
                            <MenuItem value={lookupitem.Id} key={lookupitem.Id} >{lookupitem.Description}</MenuItem>
                        ))}
                    </TextField>
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row" >
                    <TextField id={"Comment" + item.Number} multiline rows={2} value={item.Comment} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }} onChange={(values) => this.handleChecklistArrayC(values, index)}> </TextField>
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row" >
                    {item.DGDocumentUploadID !== 0 ?
                        <IconButton variant="text" color="primary" onClick={() => this.FileDownload(item.DGDocumentUploadID)} ><CloudDownloadIcon /></IconButton>
                        :
                        null
                    }
                </StyledTableCell>
            </StyledTableRow >
        ))
            :
            null;

        const inttableheader = !utils.isNull(this.state.formObject) ? this.state.formObject.DGVerificationInterventionDto.FriendlyColumnNames.map((item, index) => (
            item === "ID" ? <StyledTableCell align="left">#</StyledTableCell> : <StyledTableCell>{item}</StyledTableCell>
        )) : null;

        const inttablerows = !utils.isNull(this.state.formObject) ? this.state.formObject.DGVerificationInterventionDto.DGVerificationInterventionData.map((item, index) => (
            <StyledTableRow key={item.ID}>
                {Object.keys(item).map((innerItem, indexObject) => (
                    indexObject === 0 ? <StyledTableCell component="th" scope="row">
                        {indexObject + 1}
                    </StyledTableCell> :
                        innerItem === "QualificationVerificationStatusID" ?
                            <StyledTableCell align="left" component="th" scope="row" >
                                <TextField className="w-30" id={"QualificationVerificationStatusID" + item.ID} value={item.QualificationVerificationStatusID} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleChecklistArrayQV(values, index)} >
                                    <MenuItem value={0} key={0} >  </MenuItem>
                                    {this.state.lookupQVArray.map((lookupitem, index) => (
                                        <MenuItem value={lookupitem.Id} key={lookupitem.Id} >{lookupitem.Description}</MenuItem>
                                    ))}
                                </TextField>
                            </StyledTableCell> :
                            innerItem === "QualificationVerificationComments" ?
                                <StyledTableCell align="center" component="th" scope="row" >
                                    <TextField id={"QualificationVerificationComments" + item.Number} multiline rows={2} value={item.QualificationVerificationComments} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }} onChange={(values) => this.handleChecklistArrayQC(values, index)}> </TextField>
                                </StyledTableCell> :
                                <StyledTableCell align="left">{item[innerItem]}</StyledTableCell>
                ))}
            </StyledTableRow >
        ))
            :
            null;

        const statuslistrows = !utils.isNull(this.state.VerificationStatusListObject) && this.state.VerificationStatusListObject.TotalNumberOfRows !== 0 ? this.state.VerificationStatusListObject.Data.map((item, index) => (

            <StyledTableRow key={item.ID}>
                <StyledTableCell align="left">{item.Verifier}</StyledTableCell>
                <StyledTableCell align="left">{item.VerificationDate}</StyledTableCell>
                <StyledTableCell align="left">{item.DGStatus}</StyledTableCell>
            </StyledTableRow >
        ))
            :
            null;

        return (
            <ViewContainer topPadding="0" title="">
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <br />
                <table width="100%" class="FormsTableHeader">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfig} />
                            </td>
                            <td align="center">
                                <b>DG Verification</b><br />
                                <b>{this.state.FinancialYear}</b>
                            </td>
                            <td align="right">
                                <b>{this.state.OrganisationName} - {this.state.SDLNumber}</b>&nbsp;&nbsp;&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <Table className={classes.table} size="small" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">#</StyledTableCell>
                            <StyledTableCell>Supporting Documentation</StyledTableCell>
                            <StyledTableCell>Evidence Available / Submitted (Yes/No)</StyledTableCell>
                            <StyledTableCell>Evidence Verified</StyledTableCell>
                            <StyledTableCell>Comments</StyledTableCell>
                            <StyledTableCell>Links</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {doctablerows}
                    </TableBody>
                </Table>
                <br />
                <br />
                <Table className={classes.table} size="small" >
                    <TableHead>
                        <TableRow>
                            {inttableheader}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inttablerows}
                    </TableBody>
                </Table>
                <br />
                <br />
                <TableContainer component={Paper}>
                    <table className={classes.table} aria-label="simple table" size="small">
                        <tbody>
                            <TableRow>
                                <td align="left">
                                    <b>Status</b><br />
                                </td>
                                <td align="left">
                                    <TextField className="w-30" id="DGStatusID" value={!utils.isNull(this.state.formObject) && !utils.isNull(this.state.formObject.DGStatusID) ? this.state.formObject.DGStatusID.Id : 0} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }}
                                        onChange={(values) => this.handleStatus(values)}>
                                        <MenuItem value={0} key={0} >  </MenuItem>
                                        {!utils.isNull(this.state.lookupVArray) ? this.state.lookupVArray.map((lookupVitem, index) => (

                                            <MenuItem value={lookupVitem.Id} key={lookupVitem.Id} >{lookupVitem.Description}</MenuItem>

                                        )) : null}
                                    </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Comments</b><br />
                                </td>
                                <td align="left">
                                    <TextField id="Comments" multiline rows={4} value={!utils.isNull(this.state.formObject) && !utils.isNull(this.state.formObject.Comments) ? this.state.formObject.Comments : ""} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }} onChange={(values) => this.handleComment(values)}> </TextField>
                                </td>
                            </TableRow>
                        </tbody>
                    </table>
                </TableContainer>
                {!utils.isNull(this.state.VerificationStatusListObject) && this.state.VerificationStatusListObject.TotalNumberOfRows !== 0 ?
                    <Fragment>
                        <br />
                        <br />
                        <Table className={classes.table} size="small" >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Updated By</StyledTableCell>
                                    <StyledTableCell>Date Updated</StyledTableCell>
                                    <StyledTableCell>DG Status</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {statuslistrows}
                            </TableBody>
                        </Table>
                    </Fragment>
                    : null}
                <br />
                <Button variant="outlined" size="small" id="btnSave" color="#01579B" disabled={this.state.VerificationPermission === "ViewOnly" ? true : false} onClick={this.CheckStatus}>Save</Button>&nbsp;

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.state.linkDialogTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={this.state.linkDialogTitle}
                    linkName={this.state.linkDialogName} linkHandler={(selection) => this.SaveVerification(selection)}
                    selectionId={this.state.id}
                    buttonName="Save"
                >
                    <DGLetterReasonDialog
                        dataUrl={"api/sims/dgverification/GetDGLetterReasons"}
                        multiSelect={true} showTitle={false}
                        DGApplicationID={this.state.id}
                        NewDGStatusID={this.state.NewDGStatusID}
                        mode="lookup"
                    />
                </LinkDialog>

            </ViewContainer>

        );
    }
}

export default withRouter(withStyles(useStyles())(withMenuPermission(DGVerificationMain)));