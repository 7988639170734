import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "../../../View.jsx";
import AlertItem from "../../../widgets/AlertItem";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../../widgets/Toolbar';
import LinkDialog from "../../LinkDialog";
import Prompt from '../../../widgets/Prompt';
import UnitStandardSetupSearch from '../../program/UnitStandardSetupSearch';

export const UnitstandardHeadCells = [
    { id: 'SAQAUnitStandardID', numeric: false, disablePadding: true, label: 'Unitstandard Code' },
    { id: 'SAQAUnitStandardTitle', numeric: false, disablePadding: true, label: 'Unitstandard Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'UnitStandardRegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Start Date' },
    { id: 'UnitStandardRegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' }
];

const linkToolbarRef = React.createRef();
const utils = new ViewUtils();
const toolbarRef = React.createRef();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to Qualification Scope', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to Skills Programme Scope', theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class OnlineModeratorUnitStandard extends Component {
    constructor(props) {
        super(props)
        let data = {};

        this.state = {
            model: !utils.isNull(props.data) && !utils.isNull(props.data.unitstandards) ? props.data.unitstandards : { unitstandards: [] },
            showTable: true,
            unitstandardIds: unitstandardIds,
            linkDialogOpen: false,
            formDialogOpen: false,
            clearSelectedRow: false,
            selectedUnitstandard: null,
            propmtOpen: false,
            promptTitle: "Unlink Unit Standard From Moderator",
            promptContent: "Are you sure you want to unlink this unit standard from the moderator?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "ModeratorID", Value: this.props.location.state.ModeratorID }]
        }

        this.linkToolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link Unit standard/s to the moderator', icon: 'LinkIcon' },
                        { id: 'unlink', label: '', tooltip: 'Un-link selected unit standard from the moderator', disabled: utils.isNull(this.state.selectedUnitstandard), icon: 'LinkOffIcon' }
                    ]
                }
            ]
        };

        let unitstandardIds = "";

        if (!utils.isNull(props.data) && !utils.isNull(props.data.unitstandards)) {
            this.setState({ unitstandardIds: this.getIdList(props.data.unitstandards) });
        }
        else {
            if (this.props.regType == "reregistration") {
                this.AutoGrabModeratorUnitstandardScope();
            }
        }

    }

    AutoGrabModeratorUnitstandardScope() {
        let url = "api/sims/OnlineModeratorRegistration/GetPrefillModeratorUnitstandardScope?PersonID=" + this.props.data.personRoleDetails.ID;
        utils.invokeUrlExternal(url, (response) => {
            this.setState({
                model: {
                    ...this.state.model,
                    ["unitstandards"]: response[0].Data
                }
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    saveUnitstandards(selection) {
        for (let i = 0; i < selection.length; i++) {
            this.state.model["unitstandards"].push(selection[i]);
        }
        this.setState({ unitstandardIds: this.getIdList(selection) });
    }

    getIdList(programmes) {
        let exclusions = "";

        for (let i = 0; i < programmes.length; i++) {
            exclusions += programmes[i].Id;
            if (i < programmes.length - 1) {
                exclusions += ","
            }
        }

        return exclusions;
    }

    handleToolbarButtonClick(buttonId) {
        if ("back" === buttonId) {
            this.props.stepHandler(4, false, this.state.model);
        } else if ("next" === buttonId) {
            if (!utils.isNull(this.state.model)) {
                this.props.stepHandler(4, true, this.state.model);
            }
        }
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedUnitstandard: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    unlinkUnitstandard() {
        this.setState({ promptOpen: false }, () => {
            linkToolbarRef.current.setButtonDisabled("unlink", true);
        })

        let programmes = this.state.model.unitstandards;
        let newProgrammes = [];

        for (let i = 0; i < programmes.length; i++) {
            if (programmes[i].Id !== this.state.selectedProgrammeId) {
                newProgrammes.push(programmes[i]);
            }
        }
        let newIds = this.getIdList(newProgrammes);
        this.setState({ unitstandardIds: newIds, selectedProgrammeId: null, clearSelectedRow: true });
        this.setState({ model: { ...this.state.model, ["unitstandards"]: newProgrammes } });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedProgrammeId: selection[0].Id, clearSelectedRow: false });
        } else {
            this.setState({ selectedProgrammeId: null, clearSelectedRow: false });
        }
        linkToolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false, clearSelectedRow: true })
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    handleTopToolbarButtonClick = (buttonId) => {
        if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <p>
                    Online Moderator Registration: Unitstandard Scope
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        ref={linkToolbarRef}
                        buttonClickHandler={(buttonId) => this.handleTopToolbarButtonClick(buttonId)}
                        config={this.linkToolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={UnitstandardHeadCells}
                            rows={this.state.model.unitstandards}
                            autoFetchData={false}
                            height="50vh"
                            multiSelect={false}
                            clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <LinkDialog open={this.state.linkDialogOpen} linkHandler={(selection) => { this.saveUnitstandards(selection) }}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Unitstandard to Moderator`}
                >
                    <UnitStandardSetupSearch dataUrl="api/sims/OnlineModeratorRegistration/GetSearchLinkableUnitstandards"
                        multiSelect={true} showTitle={false} selectionCallbackMode="full"
                        IsExternal={this.props.IsExternal}
                        programmeId={this.props.data.personRoleDetails.ID}
                        programmeType="Q" exclusions={this.state.unitstandardIds}
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkUnitstandard()}
                    closePrompt={this.closePrompt}
                />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(OnlineModeratorUnitStandard)
);
