import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from "../../../ViewUtils";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const LearnerSeniorCertificateAssistanceForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function GetProgrammeStatusID(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }

    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    return (
        <EntityForm ref={ref} values={props.data} viewId="SeniorCertificateAssistanceSetup" dataURL="api/sims/learnerseniorcertificateassistance/GetLearnerLinkedSeniorCertificateAssistanceDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerSeniorCertificateAssistanceForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Assist Grade 12 Programme Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerseniorcertificateassistance/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="StartDate"
                                                key={tabValue}
                                                label="Start Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="EndDate"
                                                key={tabValue}
                                                label="End Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="SchoolName"
                                                parentId={0}
                                                key={tabValue}
                                                label="School Name"
                                                required
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerseniorcertificateassistance/GetLookupList?listId=Project"
                                                id="ProjectID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Project"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td colSpan="1">
                                            <CustomTextField
                                                id="TrainingProvider"
                                                parentId={0}
                                                key={tabValue}
                                                label="Training Provider"
                                                className="w-100 "
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="DateAgreementSigned"
                                                key={tabValue}
                                                label="Date Agreement Signed"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="StatusEffectiveDate"
                                                key={tabValue}
                                                label="Status Effective Date"
                                                className='{classes.textField} w-100 '
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <SelectItem
                                                        dataUrl={'api/sims/learnerseniorcertificateassistance/GetProgrammeStatusByRole?parameter=[{"Name":"ProgrammeStatusId","Value":' + GetProgrammeStatusID(form.values["ProgrammeStatusID"]) + '}]'}
                                                        id="ProgrammeStatusID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                        label="Programme Status"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="TerminationDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Termination Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerseniorcertificateassistance/GetLookupList?listId=TerminationReason"
                                                        id="TerminationReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Termination Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        required={TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="OtherTerminationReason"
                                                        key={tabValue}
                                                        label="Other Termination Reason"
                                                        className="w-100 "
                                                        required={terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerseniorcertificateassistance/GetLookupList?listId=EnrolmentStatusReason"
                                                        id="EnrolmentStatusReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Enrolment Status Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="CaptureByUser"
                                                        key={tabValue}
                                                        label="Capture By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>

                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
