import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import FormDialog from "../../etqa/FormDialog";
import { ProviderLogoApplicationForm } from "./ProviderLogoApplicationForm";
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'ProviderLogoApplicationType', numeric: false, disablePadding: true, label: 'Type of application' },
    { id: 'DateOfApplication', numeric: false, disablePadding: true, label: 'Date of application' },
    { id: 'ProviderLogoApplicationStatus', numeric: false, disablePadding: true, label: 'Status of application' },
    { id: 'DocumentName', numeric: false, disablePadding: true, label: 'Document Name', contentType: 'document' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class ProviderLogoApplication extends Component {

    constructor(props) {
        super(props)
        this.state = {
            addDialogOpen: false,
            editDialogOpen: false,
            selectedLogoApplication: null,
            promptOpen: false,
            promptTitle: "Remove Logo Application from Provider",
            promptContent: "Are you sure you want to remove this logo application from the Provider?",
            Message: "",
            MessageStyle: "",
            searchParameters: [{ Name: "ProviderID", Value: this.props.location.state.selection }],
            viewLogoApplication: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View', icon: 'ViewIcon', visible: this.props.menuPermissionItem.View },
                        { id: 'add', label: 'Add Logo Application', tooltip: 'Add logo application to Provider', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: 'Edit Logo Application', tooltip: 'Edit logo application on Provider', icon: 'EditIcon', disabled: utils.isNull(this.state.selectedLogoApplication), visible: this.props.menuPermissionItem.Edit },
                        { id: 'remove', label: 'Remove Logo Application', tooltip: 'Remove logo application from Provider', disabled: utils.isNull(this.state.selectedLogoApplication), icon: "Delete", visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };

    }

    GetCanEditPermission(id) {
        let url = "api/sims/ProviderLogoApplication/GetCanEditPermission?ProviderLogoApplicationID=" + id;
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "1") {
                toolbarRef.current.setButtonDisabled("edit", true);
            }
            else {
                toolbarRef.current.setButtonDisabled("edit", false);
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/provider',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("view" === buttonId) {
            this.setState({ editDialogOpen: true, Message: "", viewLogoApplication: true })
        }
        else if ("add" === buttonId) {
            this.setState({ addDialogOpen: true, Message: "" })
        }
        else if ("edit" === buttonId) {
            this.setState({ editDialogOpen: true, Message: "", viewLogoApplication: false })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                addDialogOpen: false,
                editDialogOpen: false,
                selectedLogoApplication: null,
                Message: "Provider Logo Application successfully saved",
                MessageStyle: "success"
            });

            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("remove", true);
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedLogoApplication: null,
                Message: ""
            })
            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("remove", true);
        }
        else {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedLogoApplication: null,
                Message: "A system error has occured, please try again later or contact your administrator",
                MessageStyle: "warning"
            })
            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("remove", true);
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLogoApplication: selection[0].Id, Message: "" });
            this.GetCanEditPermission(selection[0].Id);
        } else {
            this.setState({ selectedLogoApplication: null, Message: "" });
            toolbarRef.current.setButtonDisabled("edit", true);
        }

        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    successfulLogoApplicationRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedLogoApplication: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulLogoApplicationRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeLogoApplication(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("edit", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedLogoApplication}&ProviderID=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulLogoApplicationRemoveCallback, this.unsuccessfulLogoApplicationRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ promptOpen: false })
    }

    FileDownload = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            utils.invokeUrl("api/sims/providerlogoapplication/DownloadDocument?id=" + rowid, (response) => {

                var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };

    base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Provider > Logo Application " + (this.props.location.state.providerName !== null ? "> " + this.props.location.state.providerName + (this.props.location.state.providerSDLNumber !== null ? " - " + this.props.location.state.providerSDLNumber : "") : "> Add")}
                </p>
                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />
                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/providerlogoapplication/GetSearch`}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        onCellSelectionChange={this.FileDownload}
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <FormDialog
                    open={this.state.addDialogOpen}
                    dialogTitle={"Add Provider Logo Application"}
                    viewRef={this}
                    entityInfo={{ ProviderID: this.props.location.state.selection }}
                    formRef={addFormRef}
                    saveUrl={"api/sims/providerlogoapplication/AddProviderLogoApplication"}
                    usePost={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    <ProviderLogoApplicationForm ref={addFormRef} documentRequired={true} />
                </FormDialog>


                <FormDialog
                    open={this.state.editDialogOpen}
                    dialogTitle={"Edit Provider Logo Application"}
                    viewRef={this}
                    formRef={addFormRef}
                    entityId={this.state.selectedLogoApplication}
                    saveUrl={"api/sims/providerlogoapplication/UpdateProviderLogoApplication"}
                    usePut={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                    isView={this.state.viewLogoApplication}
                >
                    <ProviderLogoApplicationForm
                        ref={addFormRef}
                        id={this.state.selectedLogoApplication}
                        dataURL='api/sims/providerlogoapplication/GetFind'
                        documentRequired={false}
                    />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeLogoApplication("api/sims/providerlogoapplication/RemoveProviderLogoApplication") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(ProviderLogoApplication));
