import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Assessor.css";
import { AssessorForm } from './AssessorForm';
import { ViewContainer } from "../../View";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();


const assessorRef = React.createRef();

class Assessor extends Component {

    state = {
        AssessorDetails: {
            Person: {
                FirstName: "",
                Surname: "",
                IDNo: ""
            }
        },
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                        
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit},
                        {
                            contextMenu: [
                                { id: "registrationDetails", description: "Registration Details"},
                                { id: "registrationHistory", description: "Registration History"  }
                            ],
                            id: "registration", label: "Registration", width: "120px"
                        },
        
                        {
                            contextMenu: [
                                { id: "qualification", description: "SAQA Qualification" },
                                { id: "learnership", description: "Learnership" },
                                { id: "skillsProgramme", description: "Skills Programme" },
                                { id: "unitStandard", description: "Unit Standard" },
                                { id: "qctoqualification", description: "QCTO Qualification" },
                            ],
                            id: 'programs', label: 'Programmes', width: "120px"
                        },
        
                        { id: 'provider', label: 'Provider', tooltip: '' },
                        //{ id: 'idDocument', label: 'ID Document', tooltip: 'Identity Document' },
                        { id: 'crm', label: 'CRM', tooltip: 'Client Relationship Management' }
        
                    ]
                }
            ]
        }
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ AssessorDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.close();
        } else if ("idDocument" === buttonId) {
            this.props.history.push({
                pathname: '/assessorIDDocument',
                state: {
                    AssessorID: this.props.location.state.selection,
                    AssessorName: `${this.state.AssessorDetails.FirstName} ${this.state.AssessorDetails.Surname}`,
                    AssessorIDNo: `${this.state.AssessorDetails.IDNo}`
                }
            })
        }else if ("crm" === buttonId) {
            this.props.history.push({
                pathname: '/assessorCRM',
                state: {
                    selection: this.props.location.state.selection,
                    AssessorName: `${this.state.AssessorDetails.FirstName} ${this.state.AssessorDetails.Surname}`,
                    AssessorIDNo: `${this.state.AssessorDetails.IDNo}`
                }
            })
        } else if ("provider" === buttonId) {
            this.props.history.push({
                pathname: '/AssessorProviderSearch',
                state: {
                    AssessorID: this.props.location.state.selection,
                    AssessorName: `${this.state.AssessorDetails.FirstName} ${this.state.AssessorDetails.Surname}`,
                    AssessorIDNo: `${this.state.AssessorDetails.IDNo}`
                }
            })
        }
    }

    handleToolbarButtonMenuClick(menu) {
        if ("registrationDetails" === menu.id) {
            this.props.history.push({
                pathname: '/AssessorRegistrationDetails',         
                state: {
                    selection: this.props.location.state.selection,
                    AssessorName: `${this.state.AssessorDetails.FirstName} ${this.state.AssessorDetails.Surname}`,
                    AssessorIDNo: `${this.state.AssessorDetails.IDNo}`
                }
            })
        } else if ("registrationHistory" === menu.id) {
                this.props.history.push({
                    pathname: '/AssessorRegistrationHistory',
                    state: {
                        AssessorID: this.props.location.state.selection,
                        AssessorName: this.state.AssessorDetails.FirstName + " " + this.state.AssessorDetails.Surname,
                        AssessorIDNo: `${this.state.AssessorDetails.IDNo}`
                    }
                })

        } else if ("skillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/AssessorSkillsProgrammeSearch',
                state: {
                    AssessorID: this.props.location.state.selection,
                    AssessorName: this.state.AssessorDetails.FirstName + " " + this.state.AssessorDetails.Surname,
                    AssessorIDNo: `${this.state.AssessorDetails.IDNo}`
                }
            })
        } else if ("learnership" === menu.id) {
            this.props.history.push({
                pathname: '/Assessorlearnerships',
                state: {
                    AssessorID: this.props.location.state.selection,
                    AssessorName: this.state.AssessorDetails.FirstName + " " + this.state.AssessorDetails.Surname,
                    AssessorIDNo: `${this.state.AssessorDetails.IDNo}`
                }
            })
        } else if ("qualification" === menu.id) {
            this.props.history.push({
                pathname: '/AssessorQualificationSearch',
                state: {
                    AssessorID: this.props.location.state.selection,
                    AssessorName: this.state.AssessorDetails.FirstName + " " + this.state.AssessorDetails.Surname,
                    AssessorIDNo: `${this.state.AssessorDetails.IDNo}`
                }
            })
        } else if ("unitStandard" === menu.id) {
            this.props.history.push({
                pathname: '/AssessorUnitStandard',
                state: {
                    AssessorID: this.props.location.state.selection,
                    AssessorName: this.state.AssessorDetails.FirstName + " " + this.state.AssessorDetails.Surname,
                    AssessorIDNo: `${this.state.AssessorDetails.IDNo}`
                }
            })
        } else if ("qctoqualification" === menu.id) {
            this.props.history.push({
                pathname: '/AssessorModuleQualificationSearch',
                state: {
                    AssessorID: this.props.location.state.selection,
                    AssessorName: this.state.AssessorDetails.FirstName + " " + this.state.AssessorDetails.Surname,
                    AssessorIDNo: `${this.state.AssessorDetails.IDNo}`
                }
            })
        }
    }

    close() {
        this.props.history.push('/assessorSearch');
    }

    successCallback = () => {

        this.setState({ message: "Assessor updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = assessorRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/assessordetails/UpdateAssessor", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    render() {
       
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Assessor " + (this.state.AssessorDetails.FirstName !== null ? "> " + this.state.AssessorDetails.FirstName + " " + this.state.AssessorDetails.Surname + (this.state.AssessorDetails.IDNo !== null ? " - " + this.state.AssessorDetails.IDNo : "") : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Assessor" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "64vh", maxHeight: "64vh" }}>
                                    <AssessorForm ref={assessorRef} id={this.props.location.state.selection} valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission = {this.props.menuPermissionItem.Edit} />
                                </div>
                            </td> 
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(Assessor));
