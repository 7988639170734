import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import WSPATRVerificationSearchForm from './WSPATRVerificationSearchForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();
const toolbarRef = React.createRef();
class WSPATRVerificationSearch extends Component {

    constructor(props) {
        super(props);


        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View Verification', icon: 'ViewIcon', disabled: true },
                    ]
                }
            ]
        }

        this.searchParameters = 
            [
                { Name: "SDLNumber", Value: "" },
                { Name: "FinancialYearID", Value: "" },

            ]

        this.headCells = 
            [
                { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
                { id: 'CompanyName', numeric: false, disablePadding: true, label: 'Company Name' },
                { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
                { id: 'SubmissionDate', numeric: false, disablePadding: true, label: 'Submission Date' },
                { id: 'VerificationStatus', numeric: false, disablePadding: true, label: 'Verification Status' },

            ]

        let model = 
            {
                SDLNumber: "",
            FinancialYearID: { Id: 26, Description: "01 April 2024 - 31 March 2025" }
            }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedWSPATR: null,
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedWSPATR: selection[0].Id });
        } else {
            this.setState({ selectedWSPATR: null });
        }
        //this.setState({ selectedWSPATR: selection })
        toolbarRef.current.setButtonDisabled("view", false);

    }

    handleToolbarButtonClick = (buttonId) => {
        if ("view" === buttonId) {
            this.props.history.push({
                pathname: "/WSPATRVerificationMain",
                state: {
                    WSPATRID: this.state.selectedWSPATR
                }
            })
        }
    }

    render() {
        return (

            <ViewContainer title={""}>
                <SearchView headCells={this.headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/WSPATRVerification/getsearch"}
                    entityViewPath='/WSPATRVerificationMain'
                    model={this.state.model}
                    title='WSP/ATR Verification'
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    mode={this.props.mode}
                    multiSelect={this.props.multiSelect}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="LegalName"
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    toolbarRef={toolbarRef}
                >
                    <WSPATRVerificationSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(WSPATRVerificationSearch)
);


