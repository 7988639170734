import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import "../../../View.css";
import { AddPersonRoleIDUploadForm } from './AddPersonRoleIDUploadForm';
import ViewUtils from '../../../ViewUtils';
import EnhancedToolbar from '../../../widgets/Toolbar';
import DocumentUpload from '../../../widgets/DocumentUpload';
import Button from '@material-ui/core/Button';
import { ViewContainer } from "../../../View.jsx";
import { isEmptyObject } from "jquery";
import AlertItem from "../../../widgets/AlertItem";

const utils = new ViewUtils();
const nextToolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'next', label: 'NEXT', tooltip: 'Add', theme: true }
            ]
        }
    ]
};

const createNewToolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'createNew', label: 'CREATE NEW', tooltip: 'Proceed and create a new Moderator linked to a person', width: "150px", theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class AddPersonRoleSearch extends Component {

    state = {
        formData: {},
        file: null,
        selection: [],
        data: {},
        message: "",
        searchResult: {},
        searched: false,
        currentIdNumber: "",
        hasError: false,
        isSouthAfrican: false,
        showTable: true,
        IsExternal: utils.isNull(this.props.IsExternal) ? false : this.props.IsExternal
    };

    handleToolbarButtonClick(buttonId) {
        if (!utils.isNull(this.state.formData)) {

            let data = utils.isNull(this.props.data) ? {} : this.props.data;
            if (!utils.isNull(this.state.data.PersonDetails)) {
                data.identityDetails = {};
                data.identityDetails.formData = this.state.formData;
                data.identityDetails.searchResult = this.state.searchResult;
                data.identityDetails.searched = true;
                data.personRoleDetails = {};
                data.personRoleDetails.Person = this.state.data.PersonDetails;
            }
            else {
                data.identityDetails = {};
                data.identityDetails.formData = this.state.formData;
                data.identityDetails.searchResult = this.state.searchResult;
                data.identityDetails.searched = true;
            }

            this.props.stepHandler(0, true, data);
        }
    }

    fileUploadHandler(data) {
        this.setState({ formData: {} })
        this.setState({ file: data });
    }

    search() {
        this.setState({ message: "", hasError: false });
        let formValue = formRef.current.getValue();
        if (formValue !== null && typeof formValue !== 'undefined') {
            formValue.RegistrationType = this.props.registrationType;
            this.setState({ message: "", hasError: false });
            var currentParameters = [{ Name: "FirstName", Value: "" },
            { Name: "MiddleName", Value: "" },
            { Name: "Surname", Value: "" },
            { Name: "IDNo", Value: this.state.formData.IDNo }];

            if (this.props.IsExternal) {
                utils.invokeUrlExternal("api/sims/" + this.props.role + "/addPersonSearch?identityData=" + JSON.stringify(formRef.current.getValue()),
                    (data) => {
                        //let result = JSON.parse(data);
                        let result = data;
                        this.setState({ searchResult: result, searched: true })
                        if (this.props.registrationType == "newregistration") {
                            if (result.RoleRecordFound === true) {
                                this.setState({ message: "A valid moderator with the specified criteria already exist.", hasError: true });
                            }
                        }
                        else {
                            if (result.RoleRecordFound === true && result.IdValid == true) {
                                this.setState({ message: "A Moderator with the specified criteria was found.", hasError: false });
                            }
                            else {
                                this.setState({ message: "A Moderator with the specified criteria cannot be found. Please confirm that all entered details are correct and that this moderator has expired or currently in a de-registered status.", hasError: true });
                            }
                        }

                        if (result.DataValid === false) {
                            this.setState({ message: result.InvalidDataMessage, hasError: true });
                        }

                        //if (result.PersonFound) {
                        this.setState({ data: result })
                        //}
                    }, null);
            }
            else {
                utils.invokeUrl("api/sims/" + this.props.role + "/addPersonSearch?identityData=" + JSON.stringify(formRef.current.getValue()),
                    (data) => {
                        //let result = JSON.parse(data);
                        let result = data;
                        this.setState({ searchResult: result, searched: true })
                        if (result.RoleRecordFound === true) {
                            this.setState({ message: "An Moderator with the specified criteria already exist", hasError: true });
                        }

                        if (result.DataValid === false) {
                            this.setState({ message: result.InvalidDataMessage, hasError: true });
                        }

                        //if (result.PersonFound) {
                        this.setState({ data: result })
                        //}
                    }, null);
            }

            this.setState({
                searchParameters: currentParameters
            });
        }
    }

    processFileHandler() {
        this.setState({ message: "", hasError: false });
        if (this.props.IsExternal) {
            utils.invokeUrlExternal('dms/ocr/id/card', (data) => {
                if (data) {
                    let result = {};
                    result = { ...data, HasSouthAfricanID: { Id: 1, Description: "Yes" } }
                    this.setState({ formData: result });
                    this.setState({ currentIdNumber: data.IDNo });
                } else {
                    this.setState({ message: "The file cannot be extracted", hasError: true });
                }
            }, () => {
                this.setState({ message: "The file cannot be extracted", hasError: true });
            }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(this.state.file)
            });
        }
        else {
            utils.invokeUrl('dms/ocr/id/card', (data) => {
                if (data) {
                    let result = {};
                    result = { ...data, HasSouthAfricanID: { Id: 1, Description: "Yes" } }
                    this.setState({ formData: result });
                    this.setState({ currentIdNumber: data.IDNo });
                } else {
                    this.setState({ message: "The file cannot be extracted", hasError: true });
                }
            }, () => {
                this.setState({ message: "The file cannot be extracted", hasError: true });
            }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(this.state.file)
            });
        }
    }

    HandleDropChange(formValue) {

        if (!isEmptyObject(this.state.formData)) {
            if (this.state.formData.HasSouthAfricanID.Description !== formValue.HasSouthAfricanID.Description) {
                if (formValue.HasSouthAfricanID.Description === "Yes") {
                    this.setState({ isSouthAfrican: true, showTable: false, formData: formValue }, () => {
                        this.setState({ showTable: true })
                    });
                } else if (formValue.HasSouthAfricanID.Description !== "Yes") {
                    this.setState({ isSouthAfrican: false, showTable: false, formData: formValue }, () => {
                        this.setState({ showTable: true })
                    });
                }
            }
        }
        else if (isEmptyObject(this.state.formData)) {
            if (formValue.HasSouthAfricanID.Description === "Yes") {
                this.setState({ isSouthAfrican: true, showTable: false, formData: formValue }, () => {
                    this.setState({ showTable: true })
                });
            } else if (formValue.HasSouthAfricanID.Description !== "Yes") {
                this.setState({ isSouthAfrican: false, showTable: false, formData: formValue }, () => {
                    this.setState({ showTable: true })
                });
            }
        }
    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            if (!utils.isNull(values.HasSouthAfricanID)) {
                this.HandleDropChange(values);
            }

            if (!utils.isNull(values.IDNo) && values.IDNo.length == 13 && values.HasSouthAfricanID.Description === "Yes") {
                if (utils.isNull(values.CitizenResidentialStatusID) && utils.isNull(values.DateofBirth) && utils.isNull(values.GenderID)) {
                    try {
                        let idnumberDetails = { ...values };
                        const residentailStatus = idnumberDetails.IDNo.charAt(10);
                        if (residentailStatus == "0") {
                            idnumberDetails.CitizenResidentialStatusID = { Id: "1", Description: "South Africa" };
                        }
                        else if (residentailStatus == "1") {
                            idnumberDetails.CitizenResidentialStatusID = { Id: "4", Description: "Permanent Resident" }
                        }
                        var date = new Date('19' + idnumberDetails.IDNo.slice(0, 2), idnumberDetails.IDNo.slice(2, 4) - 1, idnumberDetails.IDNo.slice(4, 6));
                        idnumberDetails.DateofBirth = date;
                        const gender = parseInt(idnumberDetails.IDNo.slice(6, 10));
                        if (gender >= 0 && gender <= 4999) {
                            idnumberDetails.GenderID = { Id: "2", Description: "Female" }
                        } else if (gender >= 5000 && gender <= 9999) {
                            idnumberDetails.GenderID = { Id: "1", Description: "Male" }
                        }
                        this.setState((prevState, props) => {
                            return {
                                formData: { ...prevState.formData, ...idnumberDetails }
                            }
                        });
                    } catch (err) {

                    }
                }
            }
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <AlertItem message="Kindly note that you need all the information and documents to be available as per the guidelines to complete this application.  No data will be saved without all information completed. 
                    Required documents are as follow: Copy of ID, Moderator Certificate endorsed by ETDP SETA, Copy of Highest Qualification, CV" alertStyle="WARNING" />
                <p className="legend">
                    ID Document Upload
                </p>
                <div className="w-100" style={{ overflowY: "auto", maxHeight: "55vh" }}>
                    <table className="w-100" border="0">
                        <tbody>
                            <tr>
                                <td valign="top" height="70%">
                                    <DocumentUpload showViewer={true}
                                        onUploadHandler={(data) => this.fileUploadHandler(data)}
                                        onProcessHandler={() => this.processFileHandler()}
                                        disabled={!this.state.isSouthAfrican}
                                    />
                                </td>
                                <td width="50%" valign="top">
                                    <p style={{ height: "34px", paddingTop: "16px" }}>Personal Details</p>
                                    {
                                        !utils.isStringEmpty(this.state.message) ?
                                            <p className={this.state.hasError ? "errorMessage" : "message"}>
                                                {this.state.message}
                                            </p>
                                            :
                                            ""
                                    }
                                    {this.state.showTable === true && (
                                        <AddPersonRoleIDUploadForm role={this.props.role} IsExternal={this.props.IsExternal} values={this.state.formData} ref={formRef} valueChangeHandler={this.handleFormValueChange} isSouthAfricanFlag={this.state.isSouthAfrican} SecondaryRegistrationType={this.props.registrationType} />
                                    )}
                                    <br />
                                    <Button variant="contained" component="span"
                                        onClick={this.search.bind(this)}>
                                        Validate
                                    </Button>
                                    <div style={{ height: "42%", marginRight: "32px" }}>
                                        {
                                            this.state.searchResult.PersonFound === true ?
                                                <div style={{ height: "100%" }}>
                                                    {this.state.searchResult.RoleRecordFound === true && this.props.registrationType == "reregistration" ?
                                                        <table width="90%">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="90%">
                                                                    </td>
                                                                    <td width="10%" align="right">
                                                                        <div style={{ width: "64px" }}>
                                                                            <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={typeof this.state.selection !== 'undefined' && this.state.selection !== null && this.state.selection.length > 0} config={nextToolbarConfig} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        : ""
                                                    }
                                                </div>
                                                :
                                                ""
                                        }
                                        {
                                            this.state.searchResult.RoleRecordFound === false &&
                                                this.state.searchResult.DataValid === true && this.state.searched === true && this.props.registrationType == "newregistration" ?
                                                <div>
                                                    {this.state.searchResult.IdValid === false ?
                                                    <div className="addLearnerSearchResults">
                                                        <div>{this.state.searchResult.IdVerificationMessage}</div>
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                    {this.state.searchResult.IdValid === true ?
                                                        <table width="100%">
                                                            <div className="addLearnerSearchResults">
                                                                <div>{this.state.searchResult.IdValid === false ? this.state.searchResult.IdVerificationMessage : "No existing record found for this Moderator, kindly continue with your application."}</div>
                                                            </div>
                                                            <tbody>
                                                                <tr>
                                                                    <td width="90%">
                                                                    </td>
                                                                    <td width="10%" align="left">
                                                                        <div style={{ width: "150px" }}>
                                                                            <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={typeof this.state.selection !== 'undefined' && this.state.selection !== null && this.state.selection.length > 0} config={createNewToolbarConfig} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                                :
                                                ""
                                        }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AddPersonRoleSearch)
);
