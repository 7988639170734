import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./OnlineRegistrationTypeSelect.css";
import withMenuPermission from "../../widgets/withMenuPermission";
import { Button } from "react-bootstrap";
import LoadingIndicatorLogin from '../../LoadingIndicatorLogin';
import { withAlert } from 'react-alert'


const assessorModeratorSecondaryRegTypes = [
    { Id: "newregistration", Name: "New Registration" },
    { Id: "reregistration", Name: "Re-registration" }
]

const providerSecondaryRegTypes = [
    { Id: "newaccreditation", Name: "New Accreditation" },
    { Id: "reaccreditation", Name: "Re-accreditation" },
    { Id: "programmeapproval", Name: "Extension of scope" }
]


class OnlineRegistrationSecondryTypeSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRegistrationSecondaryType: "-1",
            selectedRegistrationType: null,
            message: "",
            loading: true
        };
    }

    showAlert(msg, msgType) {
        this.props.alert.show(msg, {
            timeout: 15000, 
            type: msgType,
            onOpen: () => {
            }, 
            onClose: () => {
            } 
        })
    }

    back() {
        this.setState({ selectedRegistrationSecondaryType: null });
        this.props.history.push({
            pathname: "/OnlineRegistrationTypeSelect"
        })
    }

    select() {
        if (this.props.location.state.selectedRegistrationType == "assessor") {
                this.props.history.push({
                    pathname: "/AddOnlineAssessorFlow",
                    state: {
                        selectedRegistrationType: this.props.location.state.selectedRegistrationType,
                        selectedRegistrationSecondaryType: this.state.selectedRegistrationSecondaryType
                    }
                })
        }
        else if (this.props.location.state.selectedRegistrationType == "moderator") {
                this.props.history.push({
                    pathname: "/AddOnlineModeratorFlow",
                    state: {
                        selectedRegistrationType: this.props.location.state.selectedRegistrationType,
                        selectedRegistrationSecondaryType: this.state.selectedRegistrationSecondaryType
                    }
                })
        }
        else if (this.props.location.state.selectedRegistrationType == "provider") {
            if (this.state.selectedRegistrationSecondaryType == "newaccreditation") {
                this.showAlert('We have ceased accreditation applications for legacy qualifications. For further information please contact the team at qualitymanagement@bankseta.org.za', 'error');
                return;
            }
            else {
                this.props.history.push({
                    pathname: "/AddOnlineProviderFlow",
                    state: {
                        selectedRegistrationType: this.props.location.state.selectedRegistrationType,
                        selectedRegistrationSecondaryType: this.state.selectedRegistrationSecondaryType
                    }
                })
            }   
        }
      
    }

    render() {
        return (
            <div className="OnlineRegistration-container">
                <div className="OnlineRegistrationSelect">
                    <p className="errorMessage">{this.state.message}</p>
                    <p>Please select one of the following:</p>
                    {
                        (this.props.location.state.selectedRegistrationType == "moderator" || this.props.location.state.selectedRegistrationType == "assessor") ?
                    <form className="style-2" id="scroll">
                        {assessorModeratorSecondaryRegTypes.map(RegSecondaryType => (
                            <div className="form-check" key={RegSecondaryType.Id}>
                                <label>
                                    <input type="radio"
                                        value={RegSecondaryType.Id}
                                        checked={this.state.selectedRegistrationSecondaryType === RegSecondaryType.Id.toString()}
                                        onChange={(ev) => this.setState({ selectedRegistrationSecondaryType: ev.target.value })}
                                    />
                                    <span className="label-text"> {RegSecondaryType.Name} </span>
                                </label>
                            </div>
                        ))}
                    </form>
                            :
                            ""
                    }
                    {
                        (this.props.location.state.selectedRegistrationType == "provider") ?
                            <form className="style-2" id="scroll">
                                {providerSecondaryRegTypes.map(RegSecondaryType => (
                                    <div className="form-check" key={RegSecondaryType.Id}>
                                        <label>
                                            <input type="radio"
                                                value={RegSecondaryType.Id}
                                                checked={this.state.selectedRegistrationSecondaryType === RegSecondaryType.Id.toString()}
                                                onChange={(ev) => this.setState({ selectedRegistrationSecondaryType: ev.target.value })}
                                            />
                                            <span className="label-text"> {RegSecondaryType.Name} </span>
                                        </label>
                                    </div>
                                ))}
                            </form>
                            :
                            ""
                    }
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Button
                                        block
                                        onClick={this.back.bind(this)}
                                        type="button"
                                    >
                                        BACK
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        block
                                        onClick={this.select.bind(this)}
                                        className="OnlineRegistration-btn"
                                        disabled={this.state.selectedRegistrationType === "-1" || this.props.isLoading}
                                        type="button"
                                    >
                                        NEXT
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                </div>
                <LoadingIndicatorLogin show={this.props.isLoading} />
            </div>
        );
    }
}


export default withRouter(withMenuPermission(withAlert()(OnlineRegistrationSecondryTypeSelect)));
