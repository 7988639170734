/// <reference path="../provider/provideradduserform.jsx" />

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import FormDialog from "../FormDialog";
import { EmployerAddContactForm } from "../employer/EmployerAddContactForm";
import { ViewContainer } from "./../../View.jsx";

const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'Designation', numeric: false, disablePadding: true, label: 'Designation' },
    { id: 'TelephoneNumber', numeric: false, disablePadding: true, label: 'Telephone Number' },
    { id: 'CellPhoneNumber', numeric: false, disablePadding: true, label: 'CellPhone Number' },
    { id: 'FaxNumber', numeric: false, disablePadding: true, label: 'Fax Number' },
    { id: 'EMail', numeric: false, disablePadding: true, label: 'EMail' }

];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class employerContactSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {

            formDialogOpen: false,
            selectedContact: null,
            propmtOpen: false,
            promptTitle: "Remove contact from organisation",
            promptContent: "Are you sure you want to remove this contact from the organisation?",
            removeMessage: "",
            removeMessageStyle: "",
            searchParameters: [{ Name: "EmployerID", Value: this.props.location.state.selection }]
  
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add contact to organisation', icon: 'AddIcon' },
                        { id: 'remove', label: '', tooltip: 'Remove contact from organisation', icon: 'DeleteIcon', disabled: utils.isNull(this.state.selectedContact) }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/employer',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("add" === buttonId) {
            this.setState({ formDialogOpen: true })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    postAddHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedContact: selection[0].Id });
        } else {
            this.setState({ selectedContact: null });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    successfulContactRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedContact: null, removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulContactRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeContact(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedContact}&employerId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulContactRemoveCallback, this.unsuccessfulContactRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Organisation > Contacts" + (this.props.location.state !== null ? " > " + this.props.location.state.employerName + (this.props.location.state.employerSDLNo !== null ? " - " + this.props.location.state.employerSDLNo : "") : "> Add")}
                </p>
                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/employercontact/getEmployerContacts`}
                        height="50vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Add organisation Contact"}
                    viewRef={this}
                    formRef={addFormRef}
                    saveUrl={"api/sims/employercontact/InsertEmployerContact"}
                    usePost={true}
                    closeHandler={(isCancelled, linked) => this.postAddHandler(isCancelled, linked)}
                >
                    <EmployerAddContactForm ref={addFormRef} />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeContact("api/sims/employercontact/RemoveEmployerContact") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(employerContactSearch)
);
