import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Organisation.css";
import SearchView from "../../widgets/SearchView";
import OrganisationSearchForm from './OrganisationSearchForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import { LinkOrganisationForm } from './LinkOrganisationForm';
import FormDialog from "../../etqa/FormDialog";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'LegalName', numeric: false, disablePadding: true, label: 'LegalName' },
    { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
    { id: 'LinkStartDate', numeric: false, disablePadding: true, label: 'Link Start Date' },
    { id: 'LinkEndDate', numeric: false, disablePadding: true, label: 'Link End Date' },
    { id: 'NumberOfEmployees', numeric: true, disablePadding: true, label: 'Number Of Employees' },
    { id: 'Document', numeric: false, disablePadding: true, label: 'Document', contentType: 'document' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();
const editFormRef = React.createRef();
const endLinkFormRef = React.createRef();

class ChildOrganisationSearch extends Component {



    constructor(props) {
        super(props)

        let searchParameters =
            [

                { Name: "LegalName", Value: "" },
                { Name: "TradeName", Value: "" },
                { Name: "SDLNumber", Value: "" },
                { Name: "OrganisationID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null }

            ]

        this.state = {
            model: {
                LegalName: "",
                SDLNumber: "",
                TradeName: "",
                OrganisationID: this.props.location.state.organisationID,
                FinancialYearStartID: { Id: 26, Description: "01 April 2024 – 31 March 2025" }
            },
            addDialogOpen: false,
            editDialogOpen: false,
            endLinkDialogOpen: false,
            selectedOrganisation: null,
            LinkOrganisationDialog: false,
            Message: "",
            MessageStyle: "",
            keyUpSwitch: false,
            searchParameters: searchParameters,
            showTable: true,
            organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
            organisationName: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationName)) ? this.props.location.state.organisationName : null,
            sdlnumber: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.sdlnumber)) ? this.props.location.state.sdlnumber : null,

        };


        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link Organisation', icon: 'LinkIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: 'delink', label: '', tooltip: 'End/Remove Link', icon: 'LinkOffIcon', disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedOrganisation: selection[0].Id })
        } else {
            this.setState({ selectedOrganisation: null })
        }
    }

    handleToolbarButtonClick = (buttonId) => {

        if (buttonId === "edit") {
            this.setState({ editDialogOpen: true, Message: "", LinkOrganisationDialog: false })
        } else if (buttonId === "close") {
            this.props.history.push({
                pathname: '/skillsOrganisation',
                state: { selection: this.state.organisationID }
            });
        }
        else if (buttonId === "link") {
            this.setState({ addDialogOpen: true, Message: "", LinkOrganisationDialog: true })

        }
        else if (buttonId === "delink") {
            this.setState({ endLinkDialogOpen: true, Message: "", LinkOrganisationDialog: false })

        }
    }

    FileDownload = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            utils.invokeUrl("api/sims/skillslinkedorganisations/downloaddocument?id=" + rowid, (response) => {

                var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                endLinkDialogOpen: false,
                selectedOrganisation: null,
                LinkOrganisationDialog: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                showTable: false
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                endLinkDialogOpen: false,
                selectedOrganisation: null,
                Message: "",
                MessageStyle: "",
                LinkOrganisationDialog: false
            })
        }
        else {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                endLinkDialogOpen: false,
                selectedOrganisation: null,
                LinkOrganisationDialog: false
            })
        }
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ LinkOrganisation: values });
        }
    }


    render() {
        return (
            <ViewContainer title={utils.isNull(this.props.showTitle) || this.props.showTitle === true ? '' : null}>
                <p className="breadcrumbs">
                    {"Organisation > Link Organisations " + (this.state.organisationName !== null ? "> " + this.state.organisationName + (this.state.sdlnumber !== null ? " - " + this.state.sdlnumber : "") : "> Add")}
                </p>

                <br />

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                {this.state.showTable === true && (
                    <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/skillslinkedorganisations/getsearch"}
                        model={this.state.model}
                        toolbarConfig={this.toolbarConfig}
                        searchParameters={this.state.searchParameters}
                        mode={this.props.mode}
                        multiSelect={this.props.multiSelect}
                        onLookupValueChange={this.props.onLookupValueChange}
                        descriptionProp="LegalName"
                        history={this.props.history}
                        buttonClickHandler={this.handleToolbarButtonClick}
                        keyUpSwitch={this.state.keyUpSwitch}
                        setSelection={(selection) => { this.handleSelection(selection) }}
                        handleCell={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                    >

                        <OrganisationSearchForm data={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }}
                            valueChangeHandler={(values) => {
                                if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                    this.setState({ model: values });
                                }
                            }} />

                    </SearchView>
                )}



                <FormDialog
                    open={this.state.addDialogOpen}
                    dialogTitle={"Link Organisation"}
                    viewRef={this}
                    entityInfo={{ OrganisationID: this.state.organisationID }}
                    formRef={addFormRef}
                    saveUrl={"api/sims/skillslinkedorganisations/LinkChildOrganisation"}
                    usePost={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    <LinkOrganisationForm ref={addFormRef} valueChangeHandler={(values) => this.handleFormValueChange(values)} open={this.state.LinkOrganisationDialog} ParentOrganisationId={this.state.organisationID} data={this.state.model} />



                </FormDialog>

                <FormDialog
                    open={this.state.editDialogOpen}
                    dialogTitle={"Edit Link Organisation"}
                    viewRef={this}
                    entityInfo={{ OrganisationID: this.state.organisationID }}
                    formRef={editFormRef}
                    entityId={this.state.selectedOrganisation}
                    saveUrl={"api/sims/skillslinkedorganisations/UpdateChildOrganisation"}
                    usePut={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    <LinkOrganisationForm
                        ref={editFormRef}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                        edit={true} ParentOrganisationId={this.state.organisationID}
                        dataURL='api/sims/skillslinkedorganisations/getfind'
                        id={this.state.selectedOrganisation}
                    />



                </FormDialog>

                <FormDialog
                    open={this.state.endLinkDialogOpen}
                    dialogTitle={"De Link Organisation"}
                    viewRef={this}
                    entityInfo={{ ID: this.state.selectedOrganisation }}
                    formRef={endLinkFormRef}
                    entityId={this.state.selectedOrganisation}
                    saveUrl={"api/sims/skillslinkedorganisations/DeLinkChildOrganisation"}
                    usePut={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    <LinkOrganisationForm
                        ref={endLinkFormRef}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                        endlink={true} ParentOrganisationId={this.state.organisationID}
                    />



                </FormDialog>

            </ViewContainer>


        );
    }
}

export default withRouter(withMenuPermission(ChildOrganisationSearch));