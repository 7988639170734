import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import Alert from '@material-ui/lab/Alert';
import { CareerGuidanceWorkshopForm } from './CareerGuidanceWorkshopForm';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@material-ui/core';

const CareerGuidanceRef = React.createRef();
const utils = new ViewUtils();

class AddCareerGuidanceWorkshop extends Component {

    state = {
        id: null,
        showTable: true,
        CareerGuidanceWorkshopID: this.props.location.state.careerGuidanceWorkshopID,
        CreateNewFlag: this.props.location.state.hideFields,
        toolbarConfig : {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon', visible: !this.props.location.state.viewOnly }
                    ]
                }
            ]
        },
        showCareerGuidesDialog: false,
        selectedCareerGuideFile: null,
        showCareerGuidanceReportDialog: false,
        selectedCareerGuidanceReportFile: null,
        showAttendanceRegisterDialog: false,
        selectedAttendanceRegisterFile: null,
        snackbarMessage: '',
        snackbarSeverity: 'error',
        snackbarOpen: false,
        distfileName: null,
        reportformfileName: null, 
        registerfileName: null
    };

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            this.setState({ distfileName: values.DistributedEvidenceOriginalFileName, reportformfileName: values.ReportFormOriginalFileName, registerfileName: values.AttendanceRegisterOriginalFileName });
        }
    }

    toggleCareerGuidesDialog = () => {
        this.setState(prevState => ({
            showCareerGuidesDialog: !prevState.showCareerGuidesDialog
        }));
    };

    handleCareerGuideFileChange = (event) => {
        this.setState({ selectedCareerGuideFile: event.target.files[0] });
    };

    handleCareerGuidanceDocumentUpload = (type) => {
        let selectedFile;
        switch (type) {
            case "EVIDENCE":
                selectedFile = this.state.selectedCareerGuideFile;
                break;
            case "REPORTFORM":
                selectedFile = this.state.selectedCareerGuidanceReportFile;
                break;
            case "ATTENDANCE":
                selectedFile = this.state.selectedAttendanceRegisterFile;
                break;
            default:
                return;
        }

        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                let base64String = reader.result;

                let data = {
                    EntityID: this.state.CareerGuidanceWorkshopID,
                    FileCategory: type,
                    Document: {
                        Name: selectedFile.name,
                        size: selectedFile.size,
                        type: selectedFile.type,
                        base64: base64String
                    }
                };

                utils.invokeUrl(`api/sims/CareerGuidance/AddCareerGuidanceDocument`, (response) => {
                    if (response.MessageType === "success") {
                        this.setState({
                            showTable: false
                        }, () => {
                            this.setState({
                                showTable: true
                            })
                        });
                        this.handleSnackbarOpen("File upload successful.", "success");
                    } else {
                        this.handleSnackbarOpen("File upload failed.", "error");
                    }
                }, (error) => {
                    this.handleSnackbarOpen("An error occurred while uploading. Please try again.", "error");
                }, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(data)
                });
            };

            reader.readAsDataURL(selectedFile);
        } else {
            this.handleAlertOpen();
        }
    };

    handleAlertOpen = () => {
        this.handleSnackbarOpen("Please select a file before uploading.", "error");
    }

    toggleCareerGuidanceReportDialog = () => {
        this.setState(prevState => ({
            showCareerGuidanceReportDialog: !prevState.showCareerGuidanceReportDialog
        }));
    };

    handleCareerGuidanceReportFileChange = (event) => {
        this.setState({ selectedCareerGuidanceReportFile: event.target.files[0] });
    };

    toggleAttendanceRegisterDialog = () => {
        this.setState(prevState => ({
            showAttendanceRegisterDialog: !prevState.showAttendanceRegisterDialog
        }));
    };

    handleAttendanceRegisterFileChange = (event) => {
        this.setState({ selectedAttendanceRegisterFile: event.target.files[0] });
    };

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        }
    }

    save() {
        let formValue = CareerGuidanceRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/CareerGuidance/AddCareerGuidanceWorkshop", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    CareerGuidanceWorkshopID: response.EntityId,
                    CreateNewFlag: false
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    closeView() {

        this.props.history.push({
            pathname: '/CareerGuidanceWorkshopSearch',
            state: {
                EventName: this.props.location.state.EventName
            }
        });
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackbarOpen: false });
    }

    handleSnackbarOpen = (message, severity) => {
        this.setState({ snackbarOpen: true, snackbarMessage: message, snackbarSeverity: severity });
    }

    downloadDocument = (documentType) => {
        utils.invokeUrl("api/sims/CareerGuidance/DownloadCareerGuidanceDocument?ID=" + this.state.CareerGuidanceWorkshopID + "&Type=" + documentType, (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        });
    };

    base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    render() {
        const showUploadButtons = this.state.CareerGuidanceWorkshopID !== 0;
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {
                        (this.state.CreateNewFlag) ?
                            `Career Guidance Workshop > Add`
                            :
                            `Update Career Guidance Workshop > ${this.props.location.state.EventName}`
                    }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                        <CareerGuidanceWorkshopForm ref={CareerGuidanceRef}
                                            viewRef={this}
                                            id={this.state.CareerGuidanceWorkshopID}
                                            viewOnly={this.props.location.state.viewOnly}
                                            hideFields={this.state.CreateNewFlag}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                    )}
                                </div>
                            </td>
                        </tr>
                        {showUploadButtons && (
                            <>
                        <Button variant="contained" color="primary" onClick={this.toggleCareerGuidesDialog}>
                            Career Guides Distributed Evidence Upload
                        </Button>
                        {this.state.distfileName && (
                            <span style={{ marginLeft: '10px' }}>
                                <button
                                    style={{ background: 'none', border: 'none', padding: 0, color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={() => this.downloadDocument('EVIDENCE')}
                                >
                                    {this.state.distfileName}
                                </button>
                            </span>
                                )}
                         


                        <Dialog open={this.state.showCareerGuidesDialog} onClose={this.toggleCareerGuidesDialog}>
                            <DialogTitle>Career Guides Distributed Evidence Upload</DialogTitle>
                            <DialogContent>
                                <input
                                    type="file"
                                    accept="image/*,application/pdf"
                                    onChange={this.handleCareerGuideFileChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.toggleCareerGuidesDialog} color="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={() => this.handleCareerGuidanceDocumentUpload("EVIDENCE")} color="primary">
                                    Upload
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <br></br>
                        <br></br>

                        <Button variant="contained" color="primary" onClick={this.toggleCareerGuidanceReportDialog}>
                            Career Guidance Report Form Upload
                        </Button>
                        {this.state.reportformfileName && (
                            <span style={{ marginLeft: '10px' }}>
                                <button
                                    style={{ background: 'none', border: 'none', padding: 0, color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={() => this.downloadDocument('REPORTFORM')}
                                >
                                    {this.state.reportformfileName}
                                </button>
                            </span>
                        )}

                        <Dialog open={this.state.showCareerGuidanceReportDialog} onClose={this.toggleCareerGuidanceReportDialog}>
                            <DialogTitle>Career Guidance Report Form Upload</DialogTitle>
                            <DialogContent>
                                <input
                                    type="file"
                                    accept="image/*,application/pdf"
                                    onChange={this.handleCareerGuidanceReportFileChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.toggleCareerGuidanceReportDialog} color="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={() => this.handleCareerGuidanceDocumentUpload("REPORTFORM")} color="primary">
                                    Upload
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <br></br>
                        <br></br>

                        <Button variant="contained" color="primary" onClick={this.toggleAttendanceRegisterDialog}>
                            Attendance Register Upload
                        </Button>
                        {this.state.registerfileName && (
                            <span style={{ marginLeft: '10px' }}>
                                <button
                                    style={{ background: 'none', border: 'none', padding: 0, color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={() => this.downloadDocument('ATTENDANCE')}
                                >
                                    {this.state.registerfileName}
                                </button>
                            </span>
                        )}

                        <Dialog open={this.state.showAttendanceRegisterDialog} onClose={this.toggleAttendanceRegisterDialog}>
                            <DialogTitle>Attendance Register Upload</DialogTitle>
                            <DialogContent>
                                <input
                                    type="file"
                                    accept="image/*,application/pdf"
                                    onChange={this.handleAttendanceRegisterFileChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.toggleAttendanceRegisterDialog} color="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={() => this.handleCareerGuidanceDocumentUpload("ATTENDANCE")} color="primary">
                                    Upload
                                </Button>
                            </DialogActions>
                                </Dialog>
                            </>
                        )}
                    </tbody>
                </table>
                <Snackbar
                    open={this.state.snackbarOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Alert onClose={this.handleSnackbarClose} severity={this.state.snackbarSeverity}>
                        {this.state.snackbarMessage}
                    </Alert>
                </Snackbar>
            </ViewContainer>
        );
    }
}

export default withRouter(AddCareerGuidanceWorkshop);
