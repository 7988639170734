import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import { ViewContainer } from "./../../View.jsx";

const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'IdNumber', numeric: false, disablePadding: true, label: 'ID Number' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
            ]
        }
    ]
};

class EmployerLearnerSearch extends Component {
    state = {
        searchParameters: [{ Name: "EmployerID", Value: this.props.location.state.employerId }],
    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/employer',
                state: { selection: this.props.location.state.employerId }
            });
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Organisation > Learners" + (this.props.location.state !== null ? " > " + this.props.location.state.employerName + (this.props.location.state.employerSDLNo !== null ? " - " + this.props.location.state.employerSDLNo : "") : "> Add")}
                </p>
                <div className="searchResults">
                    <EnhancedToolbar highlight={false}
                        title="Employer"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={toolbarConfig} />
                    <EnhancedTable headCells={headCells} onSelectionChange={(selection) => this.setState({ selection: selection })}
                        dataUrl="api/sims/employerlearner/getEmployerLearners" searchParameters={this.state.searchParameters} />
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(EmployerLearnerSearch)
);
