import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import Button from '@material-ui/core/Button';
import withMenuPermission from "../../widgets/withMenuPermission";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import Item from "antd/lib/list/Item";
import AlertItem from "../../widgets/AlertItem";
import './Style.css'

const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'Back to WSP/ATR Dashboard', tooltip: '' }
            ]
        }
    ]
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#D9B262',
        color: theme.palette.common.white,
        border: '1px solid white',
    },
    body: {
        fontSize: 11,
    },
}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    body: {
        fontSize: 14,
        fontWeight: 500
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);

const useStyles = theme => ({
    table: {
        minWidth: 700,
    },
});

class WspAtrForms extends Component {
    constructor(props) {
        super(props)
        this.state = {
            objectProps: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.object)) ? this.props.location.state.object : null,
            formArray: [],
            FinancialYear: '',
            OrganisationName: '',
            Status: '',
            model: {
                Name: "",
            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRID)) ? this.props.location.state.WSPATRID : null,
            organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,

        };
    }

    componentDidMount() {

        this.getForms(this.state.id)
        this.getFormHead(this.state.id)
        this.GetWSPATRFormSubmissionCheck(this.state.id)
    }
    getForms(id) {
        let url = "api/sims/wspatr/GetWSPATRForms?WSPATRID=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.setState({ formArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }
    getFormHead(id) {
        let url = "api/sims/wspatr/GetFindHeader?WSPATRID=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.setState({
                FinancialYear: response.FinancialYear,
                OrganisationName: response.OrganisationName,
                WSPStatus: response.WSPStatus,
                SDLNumber: response.SDLNumber
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }
    GetWSPATRFormSubmissionCheck(id) {
        let url = "api/sims/wspatr/GetWSPATRFormSubmissionCheck?WSPATRID=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.setState({
                CanSubmit: response.Result
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }
    submit(id) {
        let url = "api/sims/wspatr/WSPATRSubmit?WSPATRID=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    close() {
        this.props.history.push({
            pathname: '/WspAtrDashboard',
            state: {
                organisationID: this.state.organisationID,
                organisationName: this.state.OrganisationName,
                sdlnumber: this.state.SDLNumber
            }
        });
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            //this.save();
        } else {
            this.close();
        }
    }

    openForm(id, e) {

        let url = '/';
        switch (this.state.objectProps.DGType) {
            case 'Learning Programmes':
                url = '/dglearningProgrammeformSearch'
                break;
            default:
                return true;
                break;

        }

        this.props.history.push({
            pathname: url,
            state: { formId: id, orgId: this.props.preSelectedProp, WSPATRobject: this.state.objectProps, }
        });
    }

    buttonSubmitClickHandler() {
        this.submit();
    }


    render() {

        const { classes } = this.props;
        const tablerows = this.state.formArray.map((item, index) => (
            item.URL !== "" ?
                <StyledTableRow key={item.number}>
                    <StyledTableCell component="th" scope="row">
                        {item.Number}
                    </StyledTableCell>
                    <StyledTableCell align="left">{item.FormName}</StyledTableCell>
                    <StyledTableCell component="th" scope="row" >{item.FormDescription}</StyledTableCell>
                    <StyledTableCell >{item.DueDate}</StyledTableCell>
                    <StyledTableCell >
                        <Link to={{ pathname: item.URL, state: { organisationID: this.state.organisationID, WSPATRID: this.state.id, WSPATRFormsID: item.Id, WSPATRFormName: item.FormName + " " + item.FormDescription } }}>
                            <Button variant="outlined" size="small" color="#01579B" className={classes.margin}>Select</Button>
                        </Link>

                    </StyledTableCell>
                </StyledTableRow >

                :

                <StyledTableRow color="primary">
                    <StyledTableTextCell align="right" ></StyledTableTextCell>
                    <StyledTableTextCell align="right"></StyledTableTextCell>

                    <StyledTableTextCell style={{ width: 400 }} >{item.FormDescription}</StyledTableTextCell>

                    <StyledTableTextCell align="right" ></StyledTableTextCell>

                    <StyledTableTextCell align="right" ></StyledTableTextCell>

                </StyledTableRow>
        ))
        const submitrow = this.state.CanSubmit == "1" ?
            <Button variant="outlined" size="small" color="#01579B" onClick={() => this.buttonSubmitClickHandler()} className={classes.margin}>SUBMIT WSP/ATR</Button>
            :
            <b>WSP/ATR Status : {this.state.WSPStatus}</b>;

        return (
            <ViewContainer topPadding="0" title="">
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%" class="FormsTableHeader">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfig} />
                            </td>
                            <td align="center">
                                <b>WSP & ATR Forms</b><br />
                                <b>{this.state.FinancialYear}</b>
                            </td>
                            <td align="right">
                                <b>{this.state.OrganisationName} - {this.state.SDLNumber}</b>&nbsp;&nbsp;&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td colSpan="3" align="right" className="formContainer">
                                {submitrow}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <Table className={classes.table} size="small" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">#</StyledTableCell>
                            <StyledTableCell>Form Name</StyledTableCell>
                            <StyledTableCell>Form Description</StyledTableCell>
                            <StyledTableCell >Due Date</StyledTableCell>
                            <StyledTableCell >Select</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tablerows}
                    </TableBody>
                </Table>


            </ViewContainer>

        );
    }
}
export default withRouter(withStyles(useStyles())(withMenuPermission(WspAtrForms)));
